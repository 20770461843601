import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { CustomCheckbox, CustomInputField } from "../../common/FormInputs";
import CustomSelect from "../../common/CustomSelect/CustomSelect";

const AssignTranscriber = ({ modal, setModal }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState();
  const [trigger, setTrigger] = useState(false);
  const [next, setNext] = useState(false);
  const [input, setInput] = useState("");
  const [checked, setChecked] = useState(false);
  const [subject, setSubject] = useState("");

  useEffect(() => {
    async function getTranscribersList(id) {
      try {
        const response = await CaseService.getTranscribersList(id);
        if (response) {
          const result = countObjectsWithSameValue(response, "editor");
          setState(
            response.map((obj) =>
              result.editor && result.editor > 0
                ? obj.transcriberAccess === "editor"
                  ? {
                      ...obj,
                      menuItems: [
                        {
                          label: "Editor",
                          value: "editor",
                          disabled: false,
                        },
                        {
                          label: "Viewer",
                          value: "viewer",
                        },
                      ],
                    }
                  : {
                      ...obj,
                      menuItems: [
                        {
                          label: "Editor",
                          value: "editor",
                          disabled: true,
                        },
                        {
                          label: "Viewer",
                          value: "viewer",
                        },
                      ],
                    }
                : {
                    ...obj,
                    menuItems: [
                      {
                        label: "Editor",
                        value: "editor",
                        disabled: false,
                      },
                      {
                        label: "Viewer",
                        value: "viewer",
                      },
                    ],
                  }
            )
          );
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    if (modal?.state) {
      getTranscribersList(modal?.id);
    }
  }, [modal?.state]); // eslint-disable-line react-hooks/exhaustive-deps

  async function onFormSubmit() {
    try {
      setLoader({ state: true, message: `Submitting Transcript...` });
      let transcribersAccess = {};
      await state
        .filter((item) => item.assigned)
        .map((arr) => {
          return (transcribersAccess[arr.id] = arr.transcriberAccess
            ? arr.transcriberAccess
            : "viewer");
        });
      if (modal?.meetingAudioId) {
        const payload = {
          meetingAudioId: modal?.meetingAudioId,
          transcriberIds: state
            .filter((obj) => obj.assigned)
            .map((newObj) => newObj.id),
          transcribersAccess,
          participantsList: input,
          isAttachAudio: checked,
          audioFileUrl: modal?.audioFileUrl,
          audioFileName: modal?.audioFileName,
          transcriptionSubject: subject,
          transcriberName: state.filter(
            (item) => item.transcriberAccess === "editor"
          )[0]?.name,
          transcriberEmail: state.filter(
            (item) => item.transcriberAccess === "editor"
          )[0]?.email,
        };
        const response = await CaseService.assignTranscribers(payload);
        if (response) {
          enqueueSnackbar(response?.message, { variant: "success" });
        }
      } else {
        const payload = {
          transcriptId: modal?.meetingAudioId,
          transcriberIds: state
            .filter((obj) => obj.assigned)
            .map((newObj) => newObj.id),
          transcribersAccess,
        };
        const response = await CaseService.assignTranscribers(payload);
        if (response) {
          enqueueSnackbar(response?.message, { variant: "success" });
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
      setNext({ state: false });
      setInput("");
      setSubject("");
      setChecked(false);
    }
  }

  function countObjectsWithSameValue(arr, key) {
    let dict = {};
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].transcriberAccess === "editor") {
        dict[arr[i].transcriberAccess] =
          (dict[arr[i].transcriberAccess] || 0) + 1;
        dict[arr[i].email] = arr[i].email;
      }
    }
    return dict;
  }

  useEffect(() => {
    if (trigger) {
      const result = state && countObjectsWithSameValue(state, "editor");
      setState(
        state &&
          state.map((obj) =>
            result.editor && result.editor > 0
              ? obj.transcriberAccess === "editor"
                ? { ...obj }
                : {
                    ...obj,
                    menuItems: [
                      {
                        label: "Editor",
                        value: "editor",
                        disabled: true,
                      },
                      {
                        label: "Viewer",
                        value: "viewer",
                      },
                    ],
                  }
              : {
                  ...obj,
                  menuItems: [
                    {
                      label: "Editor",
                      value: "editor",
                      disabled: false,
                    },
                    {
                      label: "Viewer",
                      value: "viewer",
                    },
                  ],
                }
          )
      );
      setTrigger(false);
    }
  }, [trigger]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setModal({ state: false });
    setNext({ state: false });
    setInput("");
    setSubject("");
    setChecked(false);
  };

  return (
    <>
      <Modal open={modal?.state} onClose={handleClose}>
        <DrawerContainer>
          <HeadingContainer>
            <Heading>{"Assign Transcriber"}</Heading>
            <CloseModal
              onClick={handleClose}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          {next?.state ? (
            <AgentFormContainer>
              <FormLabel>Participants list</FormLabel>
              <CustomInputField
                variant="outlined"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "98%",
                }}
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              <FormLabel>Mail Subject</FormLabel>
              <CustomInputField
                variant="outlined"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "98%",
                }}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <CheckboxContainer>
                <CustomCheckbox
                  checked={checked}
                  onChange={(e) => setChecked(e?.target?.checked)}
                />
                <CheckBoxLabel>{"Attach audio"}</CheckBoxLabel>
              </CheckboxContainer>
              <ButtonContainer>
                <PrimaryOutlinedCTAButton
                  style={{ width: "45%", padding: "8px 8px" }}
                  onClick={(e) => setNext({ state: false })}
                >
                  {"Back"}
                </PrimaryOutlinedCTAButton>
                <PrimaryCTAButton
                  style={{ width: "45%", padding: "8px 8px" }}
                  disabled={!input || !subject}
                  onClick={(e) => {
                    e.preventDefault();
                    onFormSubmit();
                  }}
                >
                  {"Submit"}
                </PrimaryCTAButton>
              </ButtonContainer>
            </AgentFormContainer>
          ) : (
            <AgentFormContainer>
              <div className="docs-listing">
                {state?.map((item, index) => (
                  <CheckboxContainer className="cm_row">
                    <CustomCheckbox
                      key={index}
                      checked={item?.assigned}
                      onChange={(ev) => {
                        setState(
                          state.map((obj) =>
                            obj.id === item.id
                              ? {
                                  ...obj,
                                  assigned: ev.target.checked,
                                  transcriberAccess: item.transcribersAccess
                                    ? item.transcribersAccess
                                    : "viewer",
                                }
                              : obj
                          )
                        );
                      }}
                    />
                    <div className="cm_name">
                      <div>{item?.name}</div>
                      <div>({item?.email})</div>
                      {item?.assigned ? (
                        <SelectContainer className="select-container">
                          <CustomSelect
                            value={item.transcriberAccess}
                            menuItemValues={item.menuItems}
                            onChange={(event) => {
                              setState(
                                state.map((obj) =>
                                  obj.id === item.id
                                    ? {
                                        ...obj,
                                        transcriberAccess: event.target.value,
                                      }
                                    : obj
                                )
                              );
                              setTrigger(true);
                            }}
                          />
                        </SelectContainer>
                      ) : null}
                    </div>
                  </CheckboxContainer>
                ))}
              </div>
              <ButtonContainer>
                <PrimaryOutlinedCTAButton
                  style={{ width: "45%", padding: "8px 8px" }}
                  onClick={handleClose}
                >
                  {"Cancel"}
                </PrimaryOutlinedCTAButton>
                <PrimaryCTAButton
                  style={{ width: "45%", padding: "8px 8px" }}
                  onClick={() => setNext({ state: true })}
                >
                  {"Next"}
                </PrimaryCTAButton>
              </ButtonContainer>
            </AgentFormContainer>
          )}
        </DrawerContainer>
      </Modal>
    </>
  );
};

export default AssignTranscriber;

const DrawerContainer = styled.div`
  max-width: 486px;
  min-height: 318px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  & .cm_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    align-items: center;
    & .cm_name {
      font-size: 15px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
  & .docs-listing {
    overflow: auto;
    height: 286px;
    border: 4px solid transparent;
    border-radius: 8px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 45px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SelectContainer = styled.div`
  margin-top: 10px;
`;

const FormLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.7;
  text-transform: capitalize;
  color: ${COLORS.INPUT_LABEL};
  margin-right: 8px;
`;

const CheckBoxLabel = styled.label`
  font-size: 16px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;
