import React, { Fragment, useEffect, useState } from "react";
import ActionBar from "../../internal/TimeSlideTranscript/ActionBar";
import _ from "lodash";
import { navigate, useLocation } from "@reach/router";
import styled from "styled-components";
import themes from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import theme from "../../../assets/theme";
import TranscriptEditor from "../../transcripts/TranscriptEditor/index";
import AlertDialog from "../../common/Alert";

const RecordingsTimeSlide = (id) => {
  const history = useLocation();
  const previousPath = history?.pathname.replace("/transcription", "");
  const breadcrumbs = ["Meeting Details", "Transcript"];
  const location = useLocation();
  const [data, setData] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      count: 0,
      mediaId: "",
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const transcriptId = location?.state?.transcriptId;
  const [transcriptData, setTranscriptData] = useState();
  const meetingId = transcriptData?.meetingId;
  const [discard, setDiscard] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      mediaId: "",
    },
  ]);
  const [render, setRender] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [open, setOpen] = useState(false);
  const [transcriptionStatus, setTranscriptionStatus] = useState();
  const [alertMessage, setAlertMessage] = useState();

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "meeting_details":
        navigate(`${previousPath}`);
        break;
      default:
        break;
    }
  };

  async function getLiveTranscriptionDataGeneric() {
    try {
      setLoader({ state: true, message: "Fetching Transcription..." });
      const response = await CaseService.getLiveTranscriptionDataGenric();
      if (response) {
        setData(
          response?.transcriptionData?.words?.map((el) => {
            return {
              id: el?.id,
              end: el?.end,
              confidence: el?.confidence,
              text: el?.text,
              start: el?.start,
              speaker: el?.speaker,
              mediaId: el?.mediaId,
            };
          })
        );
        setDiscard(
          response?.transcriptionData?.words?.map((el) => {
            return {
              id: el?.id,
              end: el?.end,
              confidence: el?.confidence,
              text: el?.text,
              start: el?.start,
              speaker: el?.speaker,
              mediaId: el?.mediaId,
            };
          })
        );
      }
      if (response) {
        setTranscriptData(response);
        setRender(true);
      }
      getTranscriptionStatus(response?.meetingId);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  async function getLiveTranscription(transcriptId) {
    try {
      setLoader({ state: true, message: "Fetching Transcription..." });
      const response = await CaseService.getLiveTranscription(transcriptId);
      if (response) {
        setData(
          response?.transcriptData?.words?.map((el) => {
            return {
              id: el?.id,
              end: el?.end,
              confidence: el?.confidence,
              text: el?.text,
              start: el?.start,
              speaker: el?.speaker,
              mediaId: el?.mediaId,
            };
          })
        );
        setDiscard(
          response?.transcriptData?.words?.map((el) => {
            return {
              id: el?.id,
              end: el?.end,
              confidence: el?.confidence,
              text: el?.text,
              start: el?.start,
              speaker: el?.speaker,
              mediaId: el?.mediaId,
            };
          })
        );
      }
      if (response) {
        setTranscriptData(response);
        setRender(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const updateTranscriptionDataBoth = async (data) => {
    try {
      setLoader({ state: true, message: "Adding Transcript..." });
      const payload = {
        editedTranscriptionData: data,
      };
      if (transcriptData?.transcriptData) {
        const res = await CaseService.updateTranscriptionData(
          payload,
          transcriptId
        );
        if (res?.message) {
          enqueueSnackbar(res?.message, {
            variant: "success",
          });
        }
        getLiveTranscription(transcriptId);
      } else {
        const res = await CaseService.updateLiveTranscriptionData(
          payload,
          meetingId
        );
        if (res?.message) {
          enqueueSnackbar(res?.message, {
            variant: "success",
          });
        }
        getLiveTranscriptionDataGeneric();
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  useEffect(() => {
    if (transcriptId) {
      getLiveTranscription(transcriptId);
    } else {
      getLiveTranscriptionDataGeneric();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const discardChanges = () => {
    setData(
      discard.map((el) => {
        return {
          id: el?.id,
          end: el?.end,
          confidence: el?.confidence,
          text: el?.text,
          start: el?.start,
          speaker: el?.speaker,
          mediaId: el?.mediaId,
        };
      })
    );
    setRender(true);
  };

  const getTranscriptionStatus = async (meetingId) => {
    try {
      const response = await CaseService.getTranscriptionStatus(meetingId);
      if (response) {
        setTranscriptionStatus(response?.transcriptionStatus);
        setAlertMessage(response?.alert);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (location?.state?.value) {
        getTranscriptionStatus(meetingId);
      }
    }, 120000);
    return () => clearInterval(interval);
  }, [meetingId]); // eslint-disable-line react-hooks/exhaustive-deps

  const moveTranscriptToDoc = async (meetingId) => {
    try {
      setLoader({ state: true, message: "Transcript moved" });
      const response = await CaseService.moveTranscriptToDoc(meetingId);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setTimeout(() => {
        getLiveTranscriptionDataGeneric();
      }, 1800);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  const saveAndmoveTranscript = async (meetingId) => {
    try {
      setLoader({ state: true, message: "Transcript moved" });
      const payload = {
        editedTranscriptionData: data,
      };
      const response = await CaseService.updateLiveTranscriptionData(
        payload,
        meetingId
      );
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      moveTranscriptToDoc(meetingId);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  const discardAndMove = (meetingId) => {
    setData(
      discard.map((el) => {
        return {
          id: el?.id,
          end: el?.end,
          confidence: el?.confidence,
          text: el?.text,
          start: el?.start,
          speaker: el?.speaker,
          mediaId: el?.mediaId,
        };
      })
    );
    if (data) {
      moveTranscriptToDoc(meetingId);
    }
  };

  const moveTranscriptionAlert = (meetingId, alertMessage) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Discard and Move",
      secondaryBtnText: "Cancel",
      moveTranscriptBtnText: "Save and Move",
      clickSecondarybtn: () => setOpen(false),
      clickPrimaryBtn: () => discardAndMove(meetingId),
      movePrimaryBtn: () => saveAndmoveTranscript(meetingId),
      onDialogClose: () => setOpen(false),
      desc: (
        <>
          Are you sure you want to move the transcript to case documents? <br />
          <br />
          <b style={{ fontFamily: `${theme.fonts.primaryFontExtraBold}` }}>
            Note :
          </b>{" "}
          <b
            style={{
              fontFamily: theme.fonts.primaryFontExtraBold,
              color: COLORS.LOGOUT_RED,
            }}
          >
            {`Please make sure you save all your changes before moving the transcript.`}
          </b>
          <br />
          <br />
          <b
            style={{
              fontFamily: theme.fonts.primaryFontExtraBold,
              color: COLORS.LOGOUT_RED,
            }}
          >
            {alertMessage}
          </b>
        </>
      ),
      moveTranscript: true,
      heading: `Move Transcription`,
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  const actions = [
    {
      text: "Post Transcript",
      props: {
        onClick: () => moveTranscriptionAlert(meetingId, alertMessage),
      },
      outlined: true,
      hidden: transcriptionStatus && data ? false : true,
    },
    {
      text: "Refresh",
      props: {
        onClick: () =>
          transcriptId
            ? getLiveTranscription(transcriptId)
            : getLiveTranscriptionDataGeneric(),
      },
      hidden: false,
    },
    {
      text: "Discard",
      props: {
        onClick: () => discardChanges(),
      },
      hidden: data ? false : true,
    },
    {
      text: "Save (alt + s)",
      props: {
        onClick: () => updateTranscriptionDataBoth(data),
      },
      hidden: transcriptData?.audioFileUrl && data ? false : true,
    },
  ];

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick, actions }} />
      <Container>
        {data ? (
          <TranscriptEditor
            {...{ meetingId, data, transcriptId, render, setRender }}
            mediaUrl={transcriptData?.audioFileUrl}
            setData={setData}
            title={"Generic Meeting Transcription"}
            updateState={updateTranscriptionDataBoth}
          />
        ) : (
          <StyledTableEmptyBlock>
            <h2>
              {
                "Welcome to JustAct live transcription services. Your session has either not started or your live transcript is currently being generated."
              }
            </h2>
          </StyledTableEmptyBlock>
        )}
      </Container>
      <AlertDialog
        isOpen={open}
        {...{ ...dialogData }}
        descriptionTextStyle={{
          textAlign: "left",
          fontFamily: theme.fonts.primaryFontSemiBold,
        }}
      />
    </Fragment>
  );
};

export default RecordingsTimeSlide;

export const Container = styled.div`
  padding: 4px 43px;
  display: flex;
  flex-direction: column;
`;

export const HeadingContainer = styled.span`
  margin-bottom: 31px;
  font-family: ${themes.fonts.primaryFontSemiBold};
  font-size: ${(props) => (props.size ? props.size : "13px")};
  color: ${COLORS.BTN_GREEN};
  // text-transform: uppercase;
  cursor: pointer;
  & .topSection {
    background: ${COLORS.BOX_DARKGREEN};
  }
`;

const StyledTableEmptyBlock = styled.div`
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-40%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: ${COLORS.COLOR_DARK};
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 20px;
    margin: 0;
    text-align: center;
    font-family: ${theme.fonts.primaryFontBold};
  }
  p {
    margin: 10px auto 30px;
    font-family: ${theme.fonts.primaryFontRegular};
    color: ${COLORS.COLOR_DARK};
  }
  a {
    color: ${COLORS.BTN_GREEN};
    font-family: ${theme.fonts.primaryFontRegular};
  }
`;
