const COLORS = {
  PRIMARY_WHITE: "#ffffff",
  PRIMARY_BLACK: "#000000",
  COLOR_DARK: "#293461",
  HEADER_BORDER: "#e1e3ee",
  BTN_GREEN: "#00838c",
  BORDER_GREY: "#e1e3ee",
  TABLE_GREY_CELL: "#f4f6ff",
  HYPERLINK: "#007aff",
  INPUT_BACKGROUND: "#f9f9f9",
  INPUT_BORDER: "#e1e3ee",
  INPUT_LABEL: "#949ab0",
  LOGOUT_RED: "#d91b1b",
  REMOVE_RED: "#ff3c3c",
  LIGHT_BLUE: "#f8faff",
  WARNING: "#d97706",
  NOTIFICATION_GREEN: "rgba(89, 179, 48, 0.1)",
  NOTIFICATION_ORANGE: "rgba(246,180,54, 0.1)",
  NOTIFICATION_BLUE: "rgba(41,52,97, 0.1)",
  NOTIFICATION_RED: "rgba(225,0,0, 0.1)",
  BOX_BLUE: "#0000FF",
  BOX_DARKGREEN: "#006400",
  BOX_DEEPPINK: "#FF1493",
  BOX_DARKMAGENTA: "#8B008B",
  BOX_INDIGO: "#4B0082",
  BOX_OLIVE: "#808000",
  INPUT_GRAY: "#aaaaaa",
  TRANSCRIPT_GRAY: "#8294a5",
  HIGHLIGHT_COLOR: "#69e3c2"
};

export default COLORS;
