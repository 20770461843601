import {
  CircularProgress,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import theme from "../../../assets/theme";
import CaseService from "../../../services/CaseService";
import { IconHolder, Count, Row } from "./styles";
import Items from "./items";
import _ from "lodash";
import queryString from "query-string";
import { navigate, useLocation } from "@reach/router";
import {
  NOTIFICATION_ACTIVITY,
  NOTIFICATION_INTERVAL,

} from "../../../helpers/constants";
import labels from "../../../helpers/labels.json";
import useNotification from "../../../hooks/useNotification";
import NotificationsIcon from "@material-ui/icons/Notifications";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: 400,
    height: 300,
    padding: "10px 0px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    padding: "10px 20px",
  },
}))(MenuItem);

const Notification = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); // This state is used for indicating the current page
  const [loading, setLoading] = useState(true); // This State for showing the loader for pending action
  const [activities, setActivities] = useState([]); //This state for Pending Action
  const [endPage, setEndPage] = useState();
  const [triggerCount, setTriggerCount] = useState(true);
  const location = useLocation();
  const { setTriggerNotify } = useNotification(); // hooks to refresh the page

  const handleClick = (event) => {
    setActivities([]);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setCurrentPage(0);
    setEndPage(0);
    setActivities([]);
    setAnchorEl(null);
  };

  useEffect(() => {
    const getPendingAction = () => {
      let params = {
        page: currentPage + 1,
        perPage: 25,
      };
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      CaseService.getPendingActions(stringParams).then(
        (res) => {
          if (res?.activities) {
            setActivities([...activities, ...res.activities.data]);
            setEndPage(res.activities.lastPage);
          }
          setLoading(false);
        },
        (err) => {
          console.log(err);
          setLoading(false);
        }
      );
    };
    if (anchorEl) {
      setLoading(true);
      getPendingAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, anchorEl]);

  /**
   * This use Effect for triggering the interval
   */
  useEffect(() => {
    const countInterval = setInterval(() => {
      setTriggerCount(true);
    }, NOTIFICATION_INTERVAL);
    return () => {
      clearInterval(countInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getCount() {
      try {
        const res = await CaseService.getNotificationCount();
        if (res) {
          setCount(res?.notificationCount);
        }
      } catch (error) {
        console.log("failed");
      }
    }

    if (triggerCount) {
      getCount();
      setTriggerCount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCount]);

  useEffect(() => {
    async function clearNotification() {
      try {
        const res = await CaseService.clearNotification();
        if (res?.success) {
          setCount(0);
        }
      } catch (error) { }
    }
    if (anchorEl && count) {
      clearNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  function loadMoreItems(event) {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (endPage && currentPage < endPage - 1) {
        //user is at the end of the list so load more items
        setCurrentPage(currentPage + 1);
      }
    }
  }

  const triggerNotify = (pathName) => {
    if (
      location.pathname === pathName ||
      location.pathname.includes(pathName)
    ) {
      setTimeout(() => {
        setTriggerNotify(true);
      });
    }
  };

  const navigateToAction = ({ kind, entity }) => {
    setAnchorEl(null);
    if (
      kind === "caseManagerSummaryUpload" ||
      kind === "caseManagerAgreementUpload"
    ) {
      navigate("/dashboard/uploads");
      triggerNotify("/dashboard/uploads");
    } else if (
      (kind === "claimantReviewCounterClaim" ||
        kind === "respondentReviewCounterClaim") &&
      entity.resolutionKind === "mediation"
    ) {
      navigate(`/dashboard/${entity?.resolutionKind}/${entity?.id}`);
      triggerNotify(`/dashboard/${entity?.resolutionKind}`);
    } else {
      navigate("/dashboard");
      triggerNotify(`/dashboard`);
    }
  };

  // const renderMessage = ({ kind, entity, message }) => {
  //   if (
  //     (kind === "claimantReviewCounterClaim" ||
  //       kind === "respondentReviewCounterClaim") &&
  //     entity.resolutionKind === "mediation"
  //   ) {
  //     return message;
  //   } else {
  //     return pending_action_msg[kind]?.value
  //       ? `${pending_action_msg[kind]?.value} (Case Id ${entity?.id})`
  //       : message;
  //   }
  // };

  return (
    <div>
      <Row onClick={handleClick}>
        <IconHolder>
          <NotificationsIcon className="icon" />
        </IconHolder>
        {count ? <Count>{count}</Count> : null}
      </Row>
      <StyledMenu
        onScroll={loadMoreItems}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          {activities?.length ? (
            <div>
              {activities.map((el, index) => (
                <StyledMenuItem
                  key={index}
                  onClick={() => navigateToAction(el)}
                >
                  <Items
                    type={NOTIFICATION_ACTIVITY[el?.kind]?.type}
                    // message={renderMessage(el)}
                    message={`${el.message} ${labels.view_here
                      }`}
                    time={el?.created_at}
                  />
                </StyledMenuItem>
              ))}
            </div>
          ) : (
            <div></div>
          )}
          <div>
            {loading ? (
              <StyledMenuItem>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <CircularProgress size={20} />
                </div>
              </StyledMenuItem>
            ) : null}
          </div>
        </div>
      </StyledMenu>
    </div>
  );
};

export default Notification;
