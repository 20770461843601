import React from "react";
import { Modal } from "@material-ui/core";
import { Formik } from "formik";
import FormField from "../../../common/FormField/FormField";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import { ReopenCaseValidationSchema } from "../../../../helpers/validationSchema";
import CaseService from "../../../../services/CaseService";

const ReOpenModal = ({ reOpen, setReOpen, setTriggerNotify }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "please wait..." });
      const payload = {
        counterClaimExtendTimeline: values?.counterClaimExtendTimeline,
        meta: reOpen?.rowData?.meta,
      };
      const response = await CaseService.reOpenCaseCM(reOpen?.id, payload);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setTriggerNotify(true);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setReOpen({ state: false });
    }
  }

  return (
    <Modal open={reOpen?.state} onClose={() => setReOpen({ state: false })}>
      <DrawerContainer>
        <Container>
          <HeadingContainer>
            <Heading>Reopen Case</Heading>
            <CloseModal
              onClick={() => setReOpen({ state: false })}
              src={require("../../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            <Formik
              initialValues={{
                counterClaimExtendTimeline: "",
              }}
              enableReinitialize
              validateOnBlur
              validationSchema={ReopenCaseValidationSchema}
              validateOnChange
              onSubmit={onFormSubmit}
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                setFieldError,
                handleBlur,
                isValid,
              }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <StyledFormElementsWrapper className="disabledArrows">
                    <FormField
                      white
                      input={{
                        type: "number",
                        name: "counterClaimExtendTimeline",
                        label: "Counter Claim Extend Timeline (Days)",
                        required: true,
                      }}
                      {...{
                        values,
                        handleChange,
                        errors,
                        handleSubmit,
                        touched,
                        handleBlur,
                        setFieldValue,
                      }}
                    />
                  </StyledFormElementsWrapper>
                  <ButtonContainer>
                    <PrimaryOutlinedCTAButton
                      style={{ width: "45%" }}
                      onClick={() => setReOpen({ state: false })}
                    >
                      Close
                    </PrimaryOutlinedCTAButton>
                    <PrimaryCTAButton
                      style={{ width: "45%" }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </PrimaryCTAButton>
                  </ButtonContainer>
                </StyledForm>
              )}
            </Formik>
          </FormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default ReOpenModal;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 456px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 40px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const StyledForm = styled.form``;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 18px;
  justify-content: space-around;
`;

const StyledFormElementsWrapper = styled.div`
  justify-content: flex-start;
`;

const FormContainer = styled.p`
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  min-height: 200px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
    padding-top: 10px;
  }
`;
