import API from "../api/manager";
import config from "../api/config";

const createTranscriber = (data) => {
  return API({
    method: "POST",
    url: config.urls.transcribers.transcriber,
    data,
  });
};
const getTranscribers = (query) => {
  return API({
    method: "GET",
    url: `${config.urls.transcribers.transcriber}/${query}`,
  });
};

const getTranscriberDetails = (id) => {
  return API({
    method: "GET",
    url: config.urls.transcribers.transcriberDetails + `/${id}`,
  });
};

const deleteTranscriber = (id, status) => {
  return API({
    method: "DELETE",
    url: config.urls.transcribers.transcriberDetails + `/${id}/${status}`,
  });
};

const editTranscriber = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.transcribers.transcriberDetails + `/${id}`,
    data,
  });
};

const getCasesTranscribers = (query) => {
  return API({
    method: "GET",
    url: config.urls.transcribers.transcriberCases + query,
  });
};

const getCMTranscriberCases = (id, query) => {
  return API({
    method: "GET",
    url: config.urls.transcribers.CMTranscriberCases + `/${id}` + query,
  });
};

const getTranscribersListForLive = (id) => {
  return API({
    method: "GET",
    url: config.urls.transcribers.getTranscribersListForLive + `/${id}`,
  });
};

const caseStatsTranscribers = (id) => {
  return API({
    method: "GET",
    url: config.urls.transcribers.transcriberCasesStats + `/${id}`,
  });
};

const getSpeakerNames = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.transcribers.getSpeakerNames}/${id}`,
  });
};

const postProcessingTranscripts = (id, data) => {
  return API({
    method: "POST",
    url: config.urls.transcribers.postProcessingTranscripts + `/${id}`,
    data,
  });
};

const getTranscribersListForRecordings = (id) => {
  return API({
    method: "GET",
    url: config.urls.transcribers.getTranscribersListForRecordings + `/${id}`,
  });
};

export default {
  createTranscriber,
  getTranscribers,
  getTranscriberDetails,
  deleteTranscriber,
  editTranscriber,
  getCasesTranscribers,
  getCMTranscriberCases,
  getTranscribersListForLive,
  caseStatsTranscribers,
  getSpeakerNames,
  postProcessingTranscripts,
  getTranscribersListForRecordings,
};
