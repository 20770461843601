import React from "react";
import { StyledFormElementsWrapper, ContainerGrid, FormLabel } from "../styles";
import { CustomFileUpload } from "../../../common/FormInputs";
import FormField from "../../../common/FormField/FormField";
import CustomSelect from "./CustomSelect";

const UpdateNotice = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  s3Upload,
  getFileName,
  menuItems,
  getNoticeTemplates,
  menuNotice,
  menuTemplateCode,
}) => {
  const inputs = [
    {
      type: "select",
      name: "noticeCategory",
      label: "Notice Category",
      required: true,
      items: menuNotice,
    },
    {
      type: "select",
      name: "templateCode",
      label: "Template Code",
      required: true,
      items: menuTemplateCode[values?.noticeCategory]?.length
        ? menuTemplateCode[values?.noticeCategory]?.map(
            ({ id, templateCode }) => ({
              id: id,
              label: templateCode,
              value: templateCode,
            })
          )
        : [],
    },
  ];

  const upload_templates = [
    {
      name: "noticeTemplate",
      label: "Upload Notice Template (docx)",
      type: "upload",
      required: true,
      accept: ".docx",
      types: [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
    },
    {
      name: "excelTemplate",
      label: "Upload Excel Template (xlsx)",
      type: "upload",
      required: true,
      accept: ".xlsx",
      types: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
      ],
    },
  ];

  return (
    <>
      <StyledFormElementsWrapper style={{ marginBottom: 8, marginTop: 15 }}>
        <ContainerGrid className="disabledArrows">
          <CustomSelect
            name="partyId"
            trigger={true}
            {...{
              errors,
              touched,
              setFieldValue,
              menuItems,
              getNoticeTemplates,
            }}
          />
          {inputs.map((input, index) => {
            return (
              <FormField
                key={index}
                menuItems={input.items}
                {...{
                  touched,
                  errors,
                  input,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }}
              />
            );
          })}
          {upload_templates.map((upload, index) => (
            <div style={{ marginTop: 10 }}>
              <FormLabel>
                {upload.label} <span className="required-star"> *</span>
              </FormLabel>
              <CustomFileUpload
                error={touched[upload.name] && errors[upload.name]}
                setFile={(file) =>
                  s3Upload(file, upload.name, setFieldValue, upload.types)
                }
                style={{
                  width: "100%",
                }}
                accept={upload.accept}
                file={{ name: getFileName(values[upload.name]) }}
              />
            </div>
          ))}
        </ContainerGrid>
      </StyledFormElementsWrapper>
    </>
  );
};

export default UpdateNotice;
