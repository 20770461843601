import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Modal, FormControlLabel, Radio, Typography } from "@material-ui/core";
import { Formik } from "formik";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import TranscriberService from "../../../services/TranscriberService";
import FormField from "../../common/FormField/FormField";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";

const Index = ({ modal, setModal }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState([{ type: "text", name: "", label: "" }]);
  const transcriptId = modal?.id;
  const [next, setNext] = useState(false);
  const [isTemplate, setIsTemplate] = useState("");

  useEffect(() => {
    async function getSpeakerNames(id) {
      try {
        const response = await TranscriberService.getSpeakerNames(id);
        if (response?.speakerNames) {
          setInputs(
            response?.speakerNames.map((el) => {
              return {
                type: "text",
                name: el,
                label: el,
              };
            })
          );
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    if (modal?.state) {
      getSpeakerNames(modal?.id);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.state]);

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Post processing transcript..." });
      const payload = {
        replaceSpeaker: values,
        isUkTemplate: next?.isUkTemplate ? next?.isUkTemplate : false,
      };
      const response = await TranscriberService.postProcessingTranscripts(
        transcriptId,
        payload
      );
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
      window.open(response?.fileUrl);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
      setNext({ state: false });
      setIsTemplate("");
    }
  }

  const radio_inputs = [
    {
      id: 1,
      label: "India",
      name: "india",
      value: false,
      color: "#00aeef",
    },
    {
      id: 2,
      label: "Uk",
      name: "uk",
      value: true,
      color: "#00aeef",
      labelColor: "#454545",
    },
  ];

  /* inital form keys

  function initialValues() {
    let hashMap = {};
    for (let i = 0; i < inputs.length; i++) {
      hashMap[inputs[i].label] = "";
    }
    return hashMap;
  }

  */

  return (
    <>
      <Modal
        open={modal?.state}
        onClose={() => {
          setModal(false);
          setIsTemplate("");
          setNext({ state: false });
        }}
      >
        <DrawerContainer>
          <HeadingContainer>
            <Heading>
              {next?.state ? "Post Processing" : "Choose document Template"}
            </Heading>
            <CloseModal
              src={require("../../../assets/images/closeModal.svg")}
              onClick={() => {
                setModal(false);
                setIsTemplate("");
                setNext({ state: false });
              }}
            />
          </HeadingContainer>
          {next?.state ? (
            <FormContainer>
              <Formik
                initialValues={{}}
                enableReinitialize
                validateOnBlur
                validateOnChange
                onSubmit={onFormSubmit}
              >
                {({
                  values,
                  handleChange,
                  errors,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  setFieldError,
                  handleBlur,
                }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <MainContainer>
                      {inputs.map((input, index) => (
                        <HalfWidth>
                          <FormField
                            key={index}
                            {...{
                              input,
                              values,
                              handleChange,
                              errors,
                              handleSubmit,
                              touched,
                              setFieldValue,
                              setFieldError,
                              handleBlur,
                            }}
                          />
                        </HalfWidth>
                      ))}
                    </MainContainer>
                    <ButtonContainer>
                      <PrimaryOutlinedCTAButton
                        style={{
                          width: "38%",
                          marginLeft: "25px",
                          padding: "10px 10px",
                        }}
                        onClick={() => setNext({ state: false })}
                      >
                        {"Back"}
                      </PrimaryOutlinedCTAButton>
                      <PrimaryCTAButton
                        style={{
                          width: "38%",
                          marginRight: "25px",
                          padding: "10px 10px",
                        }}
                        disabled={inputs[0]?.name?.length > 0 ? false : true}
                        onClick={handleSubmit}
                      >
                        {"Submit"}
                      </PrimaryCTAButton>
                    </ButtonContainer>
                  </StyledForm>
                )}
              </Formik>
            </FormContainer>
          ) : (
            <FormContainer>
              <RadioGrid>
                {radio_inputs.map((item) => (
                  <>
                    <FormControlLabel
                      value={item.value}
                      control={<Radio style={{ color: "#00838c" }} />}
                      label={
                        <Typography
                          variant="body1"
                          style={{
                            color: "#293461",
                            fontSize: "18px",
                            fontFamily: theme.fonts.primaryFontSemiBold,
                          }}
                        >
                          {item.label}
                        </Typography>
                      }
                      checked={isTemplate === item.value}
                      onClick={(e) => {
                        if (item.name === "uk") {
                          setIsTemplate(true);
                        } else {
                          setIsTemplate(false);
                        }
                      }}
                    />
                  </>
                ))}
              </RadioGrid>
              <ButtonContainer>
                <PrimaryOutlinedCTAButton
                  style={{
                    width: "38%",
                    marginLeft: "25px",
                    padding: "10px 10px",
                  }}
                  onClick={() => {
                    setModal(false);
                    setIsTemplate("");
                  }}
                >
                  {"Cancel"}
                </PrimaryOutlinedCTAButton>
                <PrimaryCTAButton
                  onClick={() => {
                    if (isTemplate === "") {
                      enqueueSnackbar("Please choose document template", {
                        variant: "warning",
                      });
                    } else {
                      setNext({ state: true, isUkTemplate: isTemplate });
                    }
                  }}
                  style={{
                    width: "38%",
                    marginRight: "25px",
                    padding: "10px 10px",
                  }}
                >
                  {"Next"}
                </PrimaryCTAButton>
              </ButtonContainer>
            </FormContainer>
          )}
        </DrawerContainer>
      </Modal>
    </>
  );
};

export default Index;

const DrawerContainer = styled.div`
  max-width: 516px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.div`
  width: 100%;
  padding: 24px 15px 30px 15px;
  line-height: 1.71;
`;

const MainContainer = styled.div`
  max-height: 350px;
  overflow: auto;
`;

const HalfWidth = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: space-around;
`;

const StyledForm = styled.form`
  width: 100%;
`;

export const RadioGrid = styled.div`
  margin-left: 45px;
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    grid-templates: 1fr 1fr;
  }
`;
