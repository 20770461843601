import API from "../api/manager";
import config from "../api/config";

const getProfiles = (query) => {
  return API({
    method: "GET",
    url: config.urls.user.index + query,
  });
};

const getProfile = (id) => {
  return API({
    method: "GET",
    url: config.urls.user.index + `/${id}`,
  });
};

const getAgreement = () => {
  return API({
    method: "GET",
    url: config.urls.systemConfigAgreement + `/settlementAgreement`,
  });
};

const setProfile = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.user.update + `/${id}`,
    data,
  });
};

const activateDeactiveProfile = (id, status) => {
  return API({
    method: "DELETE",
    url: config.urls.user.index + `/${id}/${status}`,
    data: {},
  });
};

const setConfig = (data) => {
  return API({
    method: "POST",
    url: config.urls.systemConfig,
    data,
  });
};

const getConfig = () => {
  return API(
    {
      method: "GET",
      url: config.urls.systemConfig,
    },
    false
  );
};

const getPassiveNeutral = (query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.passiveusers}${query}`,
  });
};

const getUsersCount = () => {
  return API({
    method: "GET",
    url: `${config.urls.case.getUsersCount}`,
  });
};

export default {
  getConfig,
  setConfig,
  getProfiles,
  getAgreement,
  getProfile,
  setProfile,
  activateDeactiveProfile,
  getPassiveNeutral,
  getUsersCount,
};
