import COLORS from "../../assets/Colors";
import theme from "../../assets/theme";
import { Button, withStyles } from "@material-ui/core";
// import { Center } from "../../styles/component/style";
import styled from "styled-components";
const PrimaryBtnStyle = {
  root: {
    backgroundColor: COLORS.BTN_GREEN,
    color: COLORS.PRIMARY_WHITE,
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
    width: "100%",
    textTransform: "none",
    borderRadius: 4,
    padding: "16px 22px",
    border: `1px solid ${COLORS.BTN_GREEN}`,
    transition: "300ms",
    "&:hover": {
      backgroundColor: COLORS.BTN_GREEN,
      borderColor: COLORS.BTN_GREEN,
      transform: "translate(0, -4px)",
    },
    "&:active": {
      backgroundColor: COLORS.BTN_GREEN,
      borderColor: COLORS.BTN_GREEN,
    },
    "&:focus": {
      borderColor: COLORS.BTN_GREEN,
    },
  },
  label: {
    lineHeight: 1,
  },
  disabled: {
    borderColor: "rgba(0, 0, 0, 0.26)",
    opacity: 0.6,
    color: `${COLORS.PRIMARY_WHITE} !important`,
  },
};

const PrimaryBtnStyleRefresh = {
  root: {
    backgroundColor: COLORS.BTN_GREEN,
    color: COLORS.PRIMARY_WHITE,
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
    width: "100%",
    textTransform: "none",
    borderRadius: 4,
    // padding: "16px 22px",
    border: `1px solid ${COLORS.BTN_GREEN}`,
    transition: "300ms",
    "&:hover": {
      backgroundColor: COLORS.BTN_GREEN,
      borderColor: COLORS.BTN_GREEN,
      transform: "translate(0, -4px)",
    },
    "&:active": {
      backgroundColor: COLORS.BTN_GREEN,
      borderColor: COLORS.BTN_GREEN,
    },
    "&:focus": {
      borderColor: COLORS.BTN_GREEN,
    },
  },
  label: {
    lineHeight: 1,
  },
  disabled: {
    borderColor: "rgba(0, 0, 0, 0.26)",
    opacity: 0.6,
    color: `${COLORS.PRIMARY_WHITE} !important`,
  },
};

const PrimaryBtnStyleRecording = {
  root: {
    backgroundColor: COLORS.BTN_GREEN,
    color: COLORS.PRIMARY_WHITE,
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
    width: "100%",
    textTransform: "none",
    borderRadius: 4,
    padding: "10px 12px",
    border: `1px solid ${COLORS.BTN_GREEN}`,
    transition: "300ms",
    "&:hover": {
      backgroundColor: COLORS.BTN_GREEN,
      borderColor: COLORS.BTN_GREEN,
      transform: "translate(0, -4px)",
    },
    "&:active": {
      backgroundColor: COLORS.BTN_GREEN,
      borderColor: COLORS.BTN_GREEN,
    },
    "&:focus": {
      borderColor: COLORS.BTN_GREEN,
    },
  },
  label: {
    lineHeight: 1,
  },
  disabled: {
    borderColor: "rgba(0, 0, 0, 0.26)",
    opacity: 0.6,
    color: `${COLORS.PRIMARY_WHITE} !important`,
  },
};

const OutlinedPrimaryBtnStyle = {
  root: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    color: COLORS.BTN_GREEN,
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
    width: "100%",
    textTransform: "none",
    borderRadius: 4,
    padding: "16px 22px",
    border: `1px solid ${COLORS.BTN_GREEN}`,
    transition: "300ms",
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_WHITE,
      borderColor: COLORS.BTN_GREEN,
      transform: "translate(0, -4px)",
    },
    "&:active": {
      backgroundColor: COLORS.PRIMARY_WHITE,
      borderColor: COLORS.BTN_GREEN,
    },
    "&:focus": {
      borderColor: COLORS.BTN_GREEN,
    },
  },
  label: {
    lineHeight: 1,
  },
  disabled: {
    borderColor: "rgba(0, 0, 0, 0.26)",
    opacity: 0.6,
    color: `${COLORS.PRIMARY_WHITE} !important`,
  },
};

const OutlinedSecondaryBtnStyle = {
  root: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    color: COLORS.LOGOUT_RED,
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
    width: "100%",
    textTransform: "none",
    borderRadius: 4,
    padding: "16px 22px",
    border: `1px solid ${COLORS.LOGOUT_RED}`,
    transition: "300ms",
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_WHITE,
      borderColor: COLORS.LOGOUT_RED,
      transform: "translate(0, -4px)",
    },
    "&:active": {
      backgroundColor: COLORS.PRIMARY_WHITE,
      borderColor: COLORS.LOGOUT_RED,
    },
    "&:focus": {
      borderColor: COLORS.LOGOUT_RED,
    },
  },
  label: {
    lineHeight: 1,
  },
  disabled: {
    borderColor: "rgba(0, 0, 0, 0.26)",
    opacity: 0.6,
    color: `${COLORS.PRIMARY_WHITE} !important`,
  },
};

const SecondaryBtnStyle = {
  root: {
    background: "none",
    margin: "auto",
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: COLORS.BTN_GREEN,
    cursor: "pointer",
    border: "none",
    outline: "none",
    textTransform: "none",
    padding: "6px 14px",
    transition: "300ms",
    "&:hover": {
      background: "none",
      color: COLORS.BTN_GREEN,
      transform: "translate(0, -4px)",
    },
    "&:active": {},
    "&:focus": {},
  },
  label: {
    lineHeight: 1,
  },
  disabled: {
    borderColor: "rgba(0, 0, 0, 0.26)",
  },
};

export const ActionButton = styled.button`
  border-radius: 4px;
  border: none;
  background-color: ${COLORS.BTN_GREEN};
  width: 171px;
  height: 42px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  text-align: center;
  color: ${COLORS.PRIMARY_WHITE};
  outline: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  margin-left: 23px;
`;

export const SecondaryActionButton = styled.button`
  border-radius: 4px;
  border: solid 1px ${COLORS.BTN_GREEN};
  background-color: ${COLORS.PRIMARY_WHITE};
  width: 171px;
  height: 42px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  text-align: center;
  color: ${COLORS.BTN_GREEN};
  outline: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  margin-left: 23px;
`;

export const PrimaryCTAButton = withStyles(PrimaryBtnStyle)(Button);
export const PrimaryCTAButtonRefresh = withStyles(PrimaryBtnStyleRefresh)(
  Button
);
export const PrimaryCTAButtonRecording = withStyles(PrimaryBtnStyleRecording)(
  Button
);
export const SecondaryCTAButton = withStyles(SecondaryBtnStyle)(Button);
export const PrimaryOutlinedCTAButton = withStyles(OutlinedPrimaryBtnStyle)(
  Button
);
export const SecondaryOutlinedCTAButton = withStyles(OutlinedSecondaryBtnStyle)(
  Button
);
