import React from "react";
import {
  AWAITING_RESPONDANT,
  RESPONDANT_ONBOARDED,
  NEGOTIATION_ONGOING,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  CLAIMENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  AWAITING_RESPONDANT_QUIT,
} from "../../../helpers/constants";
import CommentBox from "./common/CommentBox";
import CaseAgreement from "./common/CaseAgreement";
import AgreementDocCard from "./common/AgreementDocCard";
import _ from "lodash";

function MediationState({
  currentState,
  isRespondent,
  ownerId,
  caseDetails,
  showCta,
  comments,
  setComments,
  partyList,
  isLocalTranscriptionServer,
}) {
  /**
   * @description This function is to decide whether the respondent view is shown are not
   * @param rounds
   */

  const RenderCommentBox = ({
    status,
    expand,
    errorText,
    comments,
    caseId,
    setComments,
    disabled,
    isLocalTranscriptionServer,
  }) => {
    return (
      <CommentBox
        title={`${_.capitalize(caseDetails?.resolutionKind)} Process`}
        expand={expand}
        status={status}
        errorText={errorText}
        ownerId={ownerId}
        caseId={caseId}
        comments={comments}
        disabled={disabled}
        setComments={setComments}
        kind={
          caseDetails?.resolutionKind === "mediation"
            ? "Mediator"
            : "Arbitrator"
        }
        hasMediator={
          caseDetails?.requestedMediators?.length &&
          caseDetails?.requestedMediators?.some((el) => el?.id)
        }
        hideAddComment={
          currentState?.key === "awaitingRespondentAcceptance" ||
          currentState?.key === "noticeToArbitrate" ||
          currentState?.key === "arbitrationReferenced" ||
          currentState?.key === "arbitrationNominated" ||
          currentState?.key === "arbitrationConfirmed" ||
          currentState?.key === "waitingForArbitratorConfirmation" ||
          currentState?.key === "firstNoticeSent" ||
          currentState?.key === "statementOfClaimFiled" ||
          currentState?.key === "awaitingForSec17Details" ||
          currentState?.key === "sec17PetitionFiled" ||
          currentState?.key === "sec17Ordered" ||
          currentState?.key === "awaitingSec17Execution" ||
          currentState?.key === "sec17ExtensionMemoFiled" ||
          currentState?.key === "sec17ExtensionMemoOrdered" ||
          currentState?.key === "fsOrdered" ||
          currentState?.key === "attachmentOrdered" ||
          currentState?.key === "garnisheeProhibitaryOrder" ||
          currentState?.key === "garnisheeFinalOrder" ||
          currentState?.key === "sec17Closed" ||
          currentState?.key === "statementOfDefenceFiled" ||
          currentState?.key === "rejoinderFromClaimant" ||
          currentState?.key === "surrejoinderFromRespondent" ||
          currentState?.key === "2ndNoticeMOM" ||
          currentState?.key === "evidenceFiledByClaimant" ||
          currentState?.key === "evidenceFiledByRespondent" ||
          currentState?.key === "crossExamination" ||
          currentState?.key === "argumentsByClaimant" ||
          currentState?.key === "argumentsByRespondent" ||
          currentState?.key === "negotiationOngoing"
        }
        partyList={partyList}
        isLocalTranscriptionServer={isLocalTranscriptionServer}
      />
    );
  };

  const AgreementSection = () => {
    return (
      <>
        <div style={{ marginTop: 28 }}>
          <CaseAgreement
            title={`Final Award Summary for Case ID: ${caseDetails?.id}`}
            message={caseDetails?.negotiationSummary}
            summaryStatus={caseDetails?.summaryStatus === "completed"}
            isPaid={currentState?.cta?.length === 0}
          />
        </div>
        <div style={{ marginTop: 28 }}>
          <AgreementDocCard
            id={caseDetails?.id}
            status={caseDetails.status}
            isRespondent={isRespondent}
            hasRating={caseDetails?.rating}
            hasAgreement={caseDetails?.hasAgreementDocument}
            type={`Download`}
          />
        </div>
      </>
    );
  };

  switch (currentState?.key) {
    case AWAITING_RESPONDANT:
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Declined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
            // disabled={true}
          />
        </div>
      );
    case AWAITING_RESPONDANT_QUIT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Closed by Case Manager"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case RESPONDANT_ONBOARDED:
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Declined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case NEGOTIATION_ONGOING: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case NEGOTIATION_QUIT_BY_CLAIMENT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Quit by Claimant"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case NEGOTIATION_QUIT_BY_RESPONDENT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Quit by Respondent"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case NEGOTIATION_QUIT_BY_CASEMANAGER: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Closed by Case Manager"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case NEGOTIATION_REACHED:
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case CLAIMENT_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
          <AgreementSection />
        </>
      );
    case RESPONDENT_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
          <AgreementSection />
        </>
      );
    case BOTH_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
          <AgreementSection />
        </>
      );

    // new stages
    case "awaitingRespondentAcceptance":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "noticeToArbitrate":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "arbitrationReferenced":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "arbitrationNominated":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "arbitrationConfirmed":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "waitingForArbitratorConfirmation":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "firstNoticeSent":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "statementOfClaimFiled":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "awaitingForSec17Details":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "sec17PetitionFiled":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "sec17Ordered":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "awaitingSec17Execution":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "sec17ExtensionMemoFiled":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "sec17ExtensionMemoOrdered":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "fsOrdered":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "attachmentOrdered":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "garnisheeProhibitaryOrder":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "garnisheeFinalOrder":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "sec17Closed":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "statementOfDefenceFiled":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "rejoinderFromClaimant":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "surrejoinderFromRespondent":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "2ndNoticeMOM":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "evidenceFiledByClaimant":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "evidenceFiledByRespondent":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "crossExamination":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "argumentsByClaimant":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "argumentsByRespondent":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "negotiationOngoing":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={true}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "reservedForAward":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
          <AgreementSection />
        </>
      );
    case "awardPassed":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
          <AgreementSection />
        </>
      );
    case "resolutionFailed":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
        </>
      );
    case "settledAndWithdrawn":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
          <AgreementSection />
        </>
      );
    case "updatedAndWithdrawn":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
          <AgreementSection />
        </>
      );
    case "finalAward":
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </div>
          <AgreementSection />
        </>
      );
    default:
      return null;
  }
}

export default React.memo(MediationState);
