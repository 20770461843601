import React from "react";
import { StyledFormElementsWrapper, ContainerGrid, FormLabel } from "../styles";
import { CustomFileUpload } from "../../../common/FormInputs";
import FormField from "../../../common/FormField/FormField";
import CustomSelect from "./CustomSelect";

const UploadNotice = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  inputs,
  upload_templates,
  s3Upload,
  getFileName,
  menuItems,
}) => {
  return (
    <>
      <StyledFormElementsWrapper style={{ marginBottom: 8, marginTop: 15 }}>
        <ContainerGrid className="disabledArrows">
          <CustomSelect
            name="partyId"
            trigger={false}
            {...{
              errors,
              touched,
              setFieldValue,
              menuItems,
            }}
          />
          {inputs.map((input, index) => {
            return (
              <FormField
                key={index}
                {...{
                  touched,
                  errors,
                  input,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }}
              />
            );
          })}
          {upload_templates.map((upload, index) => (
            <div style={{ marginTop: 10 }}>
              <FormLabel>
                {upload.label} <span className="required-star"> *</span>
              </FormLabel>
              <CustomFileUpload
                error={touched[upload.name] && errors[upload.name]}
                setFile={(file) =>
                  s3Upload(file, upload.name, setFieldValue, upload.types)
                }
                style={{
                  width: "100%",
                }}
                accept={upload.accept}
                file={{ name: getFileName(values[upload.name]) }}
              />
            </div>
          ))}
        </ContainerGrid>
      </StyledFormElementsWrapper>
    </>
  );
};

export default UploadNotice;
