import React from "react";
import AddIcon from "@material-ui/icons/Add";
import DrawerModal from "../../DrawerModal/DrawerModel";
import { useState } from "react";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

export default function ViewOnlyUser({
  caseManager,
  title = "Case Manager",
  addMediator,
  titleTag,
}) {
  const [modal, setModal] = useState(false);
  return (
    <CaseManagerCard>
      {caseManager?.name ? null : (
        <div className="add_circle" onClick={() => addMediator()}>
          <Tooltip title="Add view only user" placement="right">
            <AddIcon className="add_icon" />
          </Tooltip>
        </div>
      )}
      <DrawerModal
        {...{ modal, setModal }}
        id={caseManager?.id}
        type={title}
        details={caseManager}
      />
    </CaseManagerCard>
  );
}

export const CaseManagerCard = styled.div`
  width: 185px;
  display: flex;
  border-radius: 7px;
  // border: solid 0.5px #00838c;
  // background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px 6px;
  & .add_case_casemanager {
    color: ${COLORS.INPUT_LABEL};
    font-family: ${theme.fonts.primaryFontSemiBold};
  }

  & .add_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    cursor: pointer;
    background-color: rgba(0, 131, 140, 0.13);
  }
  & .add_icon {
    color: ${COLORS.BTN_GREEN};
    font-size: 18px;
  }

  & .pending_mediator {
    font-size: 10px;
    margin-top: 2px;
    font-family: ${theme.fonts.primaryFontSemiBold};
    color: ${COLORS.WARNING};
  }
`;
