import React, { useRef } from "react";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import CaseService from "../../../../../src/services/CaseService";
import { useState } from "react";
import useLoader from "../../../../hooks/useLoader";

import {
  CustomCheckbox,
  CheckBoxLabel,
  CheckboxContainer,
} from "../../../common/FormInputs";

export default function MeetingAccess({
  id,
  setRefreshStatus,
  meetingDetail,
  caseDetails,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [checked, setChecked] = useState();
  const MTRef = useRef();

  const toggleDarkMode = () => {
    setChecked(!checked);
  };

  const enableOrDisable = async ({ status }) => {
    try {
      const meetingStatus = status === "closed" ? "open" : "closed";
      setLoader({ state: true, message: "Updating Status..." });
      const response = await CaseService.DisableEnableMeeting(
        id,
        meetingStatus
      );
      if (response) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          setRefreshStatus(true);
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <CheckboxContainer>
      {caseDetails?.status !== "draft" ? (
        <CheckboxContainer>
          <CustomCheckbox
            id="secret"
            inputRef={MTRef}
            onChange={() => toggleDarkMode(enableOrDisable(meetingDetail))}
            checked={meetingDetail?.status === "open"}
          ></CustomCheckbox>
          <CheckBoxLabel>Enable VC link</CheckBoxLabel>
        </CheckboxContainer>
      ) : (
        ""
      )}
    </CheckboxContainer>
  );
}
