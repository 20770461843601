import React, { useEffect } from "react";
import { StyledFormElementsWrapper, ContainerGrid, FormLabel } from "../styles";
import FormField from "../../../common/FormField/FormField";
import { CustomFileUpload } from "../../../common/FormInputs";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage, getFileName } from "../../../../helpers/functions";
import AWSService from "../../../../services/AWSService";
import NoticeService from "../../../../services/NoticeService";

const validFormat = /(?:image)/;

const HeaderFooter = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  setFind,
  letterMenuItems,
  setLetterMenuItems,
  triggerNotify,
  setTriggerNotify,
}) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function s3Upload(file, field, setFieldValue) {
    if (file) {
      try {
        const errorMessage = "Invalid File Type";
        if (!validFormat.test(file?.type)) throw errorMessage;
        setLoader({ state: true, message: `Uploading file ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Upload was successful", { variant: "success" });
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  useEffect(() => {
    async function getLetterHeadOption() {
      try {
        setLoader({ state: true, message: "fetching details..." });
        const response = await NoticeService.getLetterHeadOption();
        if (response) {
          setLetterMenuItems(
            response.map(({ letterHeadName, id }) => ({
              id: id,
              label: letterHeadName,
              value: letterHeadName,
            })),
          );
        }
      } catch (err) {
        enqueueSnackbar(err?.message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        setTriggerNotify(false);
      }
    }
    if (triggerNotify) {
      getLetterHeadOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNotify]);

  const inputs = [
    {
      type: "autoComplete",
      name: "letterHeadName",
      label: "Letter Head Name",
      required: true,
    },
  ];

  const letter_head_images = [
    {
      name: "headerImage",
      label: "Header Image",
      type: "upload",
      accept: "image/*",
    },
    {
      name: "footerImage",
      label: "Footer Image",
      type: "upload",
      accept: "image/*",
    },
  ];

  return (
    <StyledFormElementsWrapper style={{ marginBottom: 8, marginTop: 15 }}>
      <ContainerGrid>
        {inputs.map((input, index) => {
          return (
            <FormField
              menuItems={letterMenuItems}
              key={index}
              {...{
                touched,
                errors,
                input,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                setFind,
              }}
            />
          );
        })}

        {letter_head_images.map((image, index) => (
          <div style={{ marginTop: 10 }}>
            <FormLabel>{image.label}</FormLabel>
            <CustomFileUpload
              error={touched[image.name] && errors[image.name]}
              setFile={(file) => s3Upload(file, image.name, setFieldValue)}
              style={{
                width: "100%",
              }}
              accept={image.accept}
              file={{ name: getFileName(values[image.name]) }}
            />
          </div>
        ))}
      </ContainerGrid>
    </StyledFormElementsWrapper>
  );
};

export default HeaderFooter;
