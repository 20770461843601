import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const Container = styled.div`
  padding: 34px 43px;
  display: flex;
  flex-direction: column;
`;
export const TableWrapper = styled.div``;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
`;
export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 100px;
`;
