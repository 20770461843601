import React, { useState, useRef, useEffect, Fragment } from "react";
import ActionBar from "../../common/ActionBar";
import {
  Container,
  ProfilePic,
  Row,
  LabelValueContainer,
  Label,
  Value,
  BoldLabel,
} from "./styles";
import _ from "lodash";
import moment from "moment";
import { states } from "../../../helpers/constants";
import CustomTable from "../../common/CustomTable/CustomTable";
import { filterByStatus, getParties } from "./constants";
import queryString from "query-string";
import ProfileModal from "./components/ProfileModal";
import { navigate } from "@reach/router";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import SystemService from "../../../services/SystemService";
import labels from "../../../helpers/labels.json";
import useAlert from "../../../hooks/useAlert";
import theme from "../../../assets/theme";
import {
  getLabelByPartyStatus,
  parseTimeStamp,
} from "../../../helpers/functions";
import { HyperLink, Bold } from "./styles";

const keys1 = {
  name: labels.profile_name,
  email: labels.profile_email,
  mobile: labels.profile_mobile,
};

const keys3 = {
  houseNumber: labels.house_no,
  locality: labels.locality,
  landmark: labels.landmark,
};
const keys4 = {
  city: labels.city,
  state: labels.state,
  pin: labels.pin,
};

const keys2 = [
  {
    key: _.lowerCase(labels.dob),
    render: (v) => {
      return moment(v).format("DD/MM/YYYY");
    },
  },
];

function findState(val) {
  const found = states.find((state) => state.value === val);
  if (found) {
    return found.label;
  }
}
const initState = [
  { label: labels.profile_filters.all, key: "allCount" },
  { label: labels.profile_filters.under_verification, key: "underReviewCount" },
];

const Index = ({ userId }) => {
  const [modal, setModal] = useState(false);
  const { setLoader } = useLoader();
  const [state, setState] = useState({});
  const [filters, setFilters] = useState(initState);
  const MTRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState(filters[0].label);
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState();
  const [refresh, setRefresh] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const { hideAlert, showAlert } = useAlert();

  useEffect(() => {
    async function getUserDetails() {
      try {
        setBreadcrumbs([]);
        setLoader({ state: true, message: "Fetching User Profile..." });
        const response = await SystemService.getProfile(userId);
        setUser(response);
        setBreadcrumbs([_.startCase(labels.profiles), response.name]);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (userId && refresh) {
      getUserDetails();
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, refresh]);

  useEffect(() => {
    if (selectedFilter && MTRef?.current?.onQueryChange) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    }
  }, [selectedFilter]);

  const activateDeactiveUser = async (status) => {
    try {
      setLoader({ state: true, message: "Update user profile..." });
      const result = await SystemService.activateDeactiveProfile(
        userId,
        status
      );
      if (result?.message) {
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setRefresh(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const actions = [
    {
      text: labels.edit_profile,
      key: "edit",
      props: { onClick: () => setModal(true) },
    },
    {
      text: labels.activate,
      key: "enable",
      props: { onClick: () => showDeactivateAlert("enable") },
    },
    {
      text: labels.deactive,
      key: "disable",
      props: {
        onClick: () => showDeactivateAlert("disable"),
        style: { background: "#d91b1b", border: "1px solid #d91b1b" },
      },
    },
  ];

  const showDeactivateAlert = (key) => {
    showAlert({
      heading: "Please confirm!",
      // desc: `Are you sure you want to ${
      //   key === "enable" ? "Activate" : "Deactivate"
      // } ${user?.name} (${userId})?`,
      desc: (
        <>
          Are you sure you want to{" "}
          {key === "enable" ? "Activate" : "Deactivate"}{" "}
          <b style={{ fontFamily: `${theme.fonts.primaryFontExtraBold}` }}>
            {user?.name} ({userId})
          </b>
        </>
      ),
      primaryBtnText: "Yes",
      secondaryBtnText: "No",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => {
        activateDeactiveUser(key);
        hideAlert();
      },
      isOpen: true,
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
      onDialogClose: () => hideAlert(),
    });
  };

  function setStats(stats) {
    const mapped = initState.map((filter) => {
      let returnData = {
        label: filter.label,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  function handleNavigateToParty(type, id) {
    switch (type) {
      case "draft":
        navigate(`/dashboard/verify-parties/draft/${id}`);
        break;
      default:
        navigate(`/dashboard/verify-parties/${id}`);
        break;
    }
  }

  const columns = [
    {
      field: "id",
      title: labels.party_id,
      tooltip: "Placeholder",
      render: (rowData) => (
        <HyperLink
          onClick={() => handleNavigateToParty(rowData?.status, rowData?.id)}
        >
          {rowData.id}
        </HyperLink>
      ),
      headerStyle: {
        paddingLeft: 35,
      },
    },
    {
      field: "name",
      title: labels.party_name,
      render: (rowData) => <Bold onClick={() => {}}>{rowData.name}</Bold>,
    },
    {
      field: "kind",
      title: labels.party_type,
      tooltip: "Placeholder",
      render: (rowData) => <>{_.capitalize(rowData.kind)}</>,
    },
    {
      field: "role",
      title: labels.party_role,
      tooltip: "Placeholder",
      sorting: false,
      render: (rowData) => (
        <>
          {rowData.primaryAgentEmail
            ? rowData.primaryAgentCanCreateAgent
              ? "Primary Agent"
              : "Agent"
            : "Party Agent"}
        </>
      ),
    },
    {
      field: "documentKind",
      tooltip: "Placeholder",
      title: labels.id_type,
      render: (rowData) => (
        <>{rowData.documentKind === "pan" ? "PAN" : "Aadhar"}</>
      ),
    },
    {
      field: "status",
      tooltip: "Placeholder",
      title: labels.status,
      render: (rowData) => getLabelByPartyStatus(rowData.status),
    },
    {
      field: "created_at",
      tooltip: "Placeholder",
      title: labels.created_on,
      render: (rowData) => (
        <>{moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}</>
      ),
    },
  ];

  const data = (query) =>
    new Promise((resolve, reject) => {
      let params = {
        page: query.page + 1,
        status: filterByStatus(selectedFilter),
        perPage: 10,
        query: query.search,
        owner: user?.id,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sort_direction = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getParties(stringParams, userId)
        .then((result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
            setStats(result.stats);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case labels.profiles:
        navigate("/dashboard/profiles");
        break;
      default:
        break;
    }
  };

  const getActionButton = (user) => {
    if (user?.disabled) {
      return actions.filter((el) => el.key !== "disable");
    } else {
      return actions.filter((el) => el.key !== "enable");
    }
  };

  if (!user) return null;
  return (
    <Fragment>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
        actions={getActionButton(user)}
      />
      <Container>
        <ProfilePic
          alt="profile_img"
          src={
            user?.avatarUrl
              ? user.avatarUrl
              : require("../../../assets/images/userPlaceholder.svg")
          }
        />
        <Row>
          {_.keys(keys1).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys1[key]}</Label>
              <Value>{user[key]}</Value>
            </LabelValueContainer>
          ))}
        </Row>
        <Row>
          {keys2.map(({ key, render }, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{key}</Label>
              <Value>{render(user[key])}</Value>
            </LabelValueContainer>
          ))}
        </Row>
        <BoldLabel style={{ marginBottom: 0, marginTop: 30 }}>
          {labels.communication_address}
        </BoldLabel>
        <Row style={{ marginTop: 10 }}>
          {_.keys(keys3).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys3[key]}</Label>
              <Value>
                {key === "state" ? findState(user[key]) : user[key]}
              </Value>
            </LabelValueContainer>
          ))}
        </Row>
        <Row>
          {_.keys(keys4).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys4[key]}</Label>
              <Value>
                {key === "state" ? findState(user[key]) : user[key]}
              </Value>
            </LabelValueContainer>
          ))}
        </Row>
        <BoldLabel>
          {labels.parties_of} {_.upperCase(user?.name)}
        </BoldLabel>
        <CustomTable
          pageSize={state?.data?.length ? state?.data?.length : 10}
          hidePagination={state.lastPage === 1}
          pluralTitle={_.startCase(labels.parties)}
          singularTitle=""
          {...{
            columns,
            data,
            filters,
            selectedFilter,
            setSelectedFilter,
            MTRef,
          }}
          hideSearch
          state={state}
        />
      </Container>
      <ProfileModal {...{ modal, setModal, user, setUser }} />
    </Fragment>
  );
};

export default Index;
