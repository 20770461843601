import React from "react";
import styled, { css } from "styled-components";
import { PrimaryCTAButton } from "../../../common/Buttons";
import _ from "lodash";

function CaseCTA({
  cta = [],
  onbuttonClick,
  disableButton = false,
  resolutionKind = "",
}) {
  return (
    <ActionButtonContainer>
      <div>
        {cta?.length > 0 &&
          cta?.map((button, index) => (
            <ActionButton
              type={"button"}
              key={index}
              danger={button.danger}
              disabled={disableButton}
              onClick={() => onbuttonClick(button.type)}
            >
              {button.label?.replace(
                /Mediation/gi,
                _.capitalize(resolutionKind)
              )}
            </ActionButton>
          ))}
      </div>
    </ActionButtonContainer>
  );
}

const ActionButton = styled(PrimaryCTAButton)`
  width: 280px;
  ${({ danger }) =>
    danger &&
    css`
      background-color: #ff3c3c;
      &:focus,
      &:hover {
        background-color: #ff4d4d;
        border: 1px solid #ff3c3c;
      }
      border: 1px solid #ff3c3c;
    `}
`;

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  & button:not(:first-child) {
    margin-left: 20px;
  }
`;
export default CaseCTA;
