import React from "react";
import styled from "styled-components";
import TimeBox from "./TimeBox";
import {
  FastForwardSharp,
  FastRewindSharp,
  PlayArrow,
  Forward10,
  VolumeOff,
  VolumeUp,
  PauseOutlined,
  PictureInPicture,
} from "@material-ui/icons";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import CustomSelect from "../../common/CustomSelect/CustomSelect";
import { PLAYBACK_RATES } from "../../../helpers/constants";
import { Tooltip } from "@material-ui/core";

const PlayerControls = ({
  playMedia,
  isPlaying,
  isMute,
  playbackRate,
  skipBackward,
  skipForward,
  rollback,
  currentTime,
  duration,
  onSetCurrentTime,
  handleMuteVolume,
  setPlayBackRate,
  playbackRateOptions,
  promptSetCurrentTime,
  pictureInPicture,
  mediaType,
}) => {
  // const pictureInPicture = ('pictureInPictureEnabled' in document) ? (<button
  //   value="Picture-in-picture"
  //   title="Picture-in-picture"
  //   className={`${style.playerButton} ${style.pip}`}
  //   onClick={this.props.pictureInPicture}>
  //   <FontAwesomeIcon icon={faTv} />
  // </button>) : null;

  return (
    <>
      <Container>
        <DisplayContainer>
          <TimeBox
            promptSetCurrentTime={promptSetCurrentTime}
            currentTime={currentTime}
            duration={duration}
          />
          <ButtonPlayer
            value="seek backward by a set interval: alt r"
            title="seek backward by a set interval: alt r"
            onClick={() => rollback()}
          >
            <Forward10 />
          </ButtonPlayer>
          <ButtonPlayer
            value="seek backward: alt j"
            title="seek backward: alt j"
            onClick={() => skipBackward()}
            // onMouseDown={setIntervalHelperBackward}
            // onMouseUp={clearIntervalHelperBackward}
          >
            <FastRewindSharp />
          </ButtonPlayer>
          <ButtonPlayer
            value="Play/Pause: alt k"
            title="Play/Pause: alt k"
            onClick={() => playMedia()}
          >
            {isPlaying ? <PauseOutlined /> : <PlayArrow />}
          </ButtonPlayer>
          <ButtonPlayer
            value="seek forward: alt l"
            title="seek forward: alt l"
            // onMouseDown={setIntervalHelperForward}
            // onMouseUp={clearIntervalHelperForward}
            onClick={() => skipForward()}
          >
            <FastForwardSharp />
          </ButtonPlayer>
          <ButtonPlayer
            value="Toggle Sound"
            title="Toggle Sound"
            onClick={() => handleMuteVolume()}
          >
            {isMute ? <VolumeOff /> : <VolumeUp />}
          </ButtonPlayer>
          {"pictureInPictureEnabled" in document && mediaType === "video" ? (
            <ButtonPlayer
              value="Picture-in-picture"
              title="Picture-in-picture"
              onClick={() => pictureInPicture()}
            >
              <PictureInPicture />
            </ButtonPlayer>
          ) : null}
          <div className="player-controls">
            <Tooltip title="Playback rate change" placement="right">
              <CustomSelect
                white={true}
                id={"playbackRate"}
                menuItemValues={PLAYBACK_RATES}
                name={"playbackRate"}
                value={playbackRate}
                onChange={setPlayBackRate}
              />
            </Tooltip>
          </div>
        </DisplayContainer>
      </Container>
    </>
  );
};

export default PlayerControls;

const Container = styled.div`
  display: inline-block;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
`;
const DisplayContainer = styled.div`
  display: flex;
  border-radius: 5px;
  width: 100%;
  @media ${theme.breakpoints.sm_down} {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 3px;
  }
`;
const ButtonPlayer = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 4px;
  padding: 0.5em;
  border: 0;
  color: white;
  background: ${COLORS.BTN_GREEN};
  font-size: 1em;
  cursor: pointer;
  margin-right: 0.3rem;
  margin-top: 0.3rem;

  &:hover {
    background: ${COLORS.BTN_GREEN};
  }
`;
export const TimeBoxs = styled.div`
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  line-height: 38px;
  padding: 0 2em;
  margin-right: 0.3rem;
  margin-top: 0.2rem;
  background-color: ${COLORS.INPUT_GRAY};
  @media ${theme?.breakpoints?.sm_down} {
    width: 100%;
  }
`;

// const PlayerControlsd = styled.div`
//   margin-bottom: 30px;
//   // display: grid;
//   // grid-template-rows: 10% 3rem 3rem;
//   // grid-row-gap: 5px;
//   // margin: 0 0 0.5rem 0;
// `
// const BtnsGroup = styled.div`
//   display: flex;
//   width: 50%;
//   // grid-template-columns: repeat(4, 1fr);
//   // grid-column-gap: 0.2em;
// `
// const PlayerButton = styled.button`
//   width: 100%;
//   height: 48px;
//   margin: 0;
// `
