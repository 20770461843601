import React from "react";
import CreateViewOnlyUser from "./CreateViewOnlyUser";

const EditNeutral = ({ id }) => {
  return (
    <div>
      <CreateViewOnlyUser id={id} />
    </div>
  );
};

export default EditNeutral;
