import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { Modal } from "@material-ui/core";
import AlertDialog from "../../common/Alert";
import { CheckboxContainer, CustomCheckbox } from "../../common/FormInputs";
import SearchBar from "../../common/SearchBar";
import useSettings from "../../../hooks/useSettings";

export default function CaseLifeCycleStatus({
  lifeCycle,
  setLifeCycle,
  status,
  setStatus,
}) {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [inputText, setInputText] = useState("");
  const { caseLifeCycleStatus } = useSettings();

  useEffect(() => {
    if (lifeCycle) {
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifeCycle]);

  async function addStatus(selectedStatus) {
    try {
      setLoader({ state: true, message: "Adding Status..." });

      const response = selectedStatus;
      if (response) {
        setStatus(response);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
      setOpen(false);
    } finally {
      setLoader({ state: false });
      setLifeCycle(false);
      setOpen(false);
    }
  }

  return (
    <Modal
      open={lifeCycle}
      onClose={() => setLifeCycle(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{"Case Status"}</Heading>
          <CloseModal
            onClick={() => setLifeCycle(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <FormContainer>
          <div className="input-search">
            <SearchBar
              name={"caseManger"}
              placeHolderText={"Search Sub Stages"}
              backgroundColor="#f9f9f9"
              {...{ inputText, setInputText }}
              width="100%"
            />
          </div>
          <div className="status-list">
            {caseLifeCycleStatus?.length &&
              caseLifeCycleStatus
                .filter((stage) =>
                  stage.label.toLowerCase().includes(inputText.toLowerCase()),
                )
                .map((action) => (
                  <CheckboxContainer className="status_row">
                    <CustomCheckbox
                      // key={item?.id}
                      checked={action.value === status.value}
                      onChange={() => {
                        setOpen(true);
                        setStatus({
                          label: action.label,
                          value: action.value,
                        });
                      }}
                    />
                    <div className="status_name">{action?.label}</div>
                  </CheckboxContainer>
                ))}
          </div>
          <div>
            <AlertDialog
              isOpen={open}
              clickSecondarybtn={() => setOpen(false)}
              onDialogClose={() => setOpen(false)}
              primaryBtnText={"Ok"}
              secondaryBtnText={"Cancel"}
              desc={
                <StyledTextSemi>
                  Are you sure you want to update status to{" "}
                  <StyledText>{status.label}</StyledText>
                </StyledTextSemi>
              }
              heading={"Update Case Status"}
              clickPrimaryBtn={() => addStatus(status)}
              descriptionTextStyle={{
                textAlign: "left",
                fontFamily: theme.fonts.primaryFontSemiBold,
              }}
            />
          </div>
        </FormContainer>
      </DrawerContainer>
    </Modal>
  );
}

const DrawerContainer = styled.div`
  max-width: 516px;
  min-height: 318px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  & .status_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    align-items: center;
    & .status_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
  & .status-list {
    overflow: auto;
    height: 265px;
    border: 4px solid transparent;
    border-radius: 8px;
    margin-left: 10px;
  }
  & .input-search {
    margin: 10px 10px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const StyledTextSemi = styled.a`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const StyledText = styled.a`
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
`;
