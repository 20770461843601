import React from "react";
import {
  AWAITING_RESPONDANT,
  RESPONDANT_ONBOARDED,
  NEGOTIATION_ONGOING,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  CLAIMENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  AWAITING_RESPONDANT_QUIT
} from "../../../helpers/constants";
import _ from "lodash";
import ClaimBox from "./common/ClaimBox";
import CaseAgreement from "./common/CaseAgreement";
import AgreementDocCard from "./common/AgreementDocCard";

export default function NegotiationState({
  currentState,
  negotiationRounds,
  isRespondent,
  ownerId,
  respondentId,
  caseDetails
}) {
  /**
   * @description This function is to decide whether the respondent view is shown are not
   * @param rounds
   */
  const checkShowRespondentView = rounds => {
    return rounds?.respondentClaims?.some(
      el =>
        (el?.offer?.status === "offered" ||
          el?.offer?.statusAlais === "makeOffer") &&
        el?.offer?.offerorId === respondentId
    );
  };

  const RenderClaimBox = ({
    round,
    status,
    expand,
    errorText,
    showRespondantView
  }) => {
    return (
      <ClaimBox
        title={`Round ${negotiationRounds[round].negotiationRound}`}
        key={round}
        expand={expand}
        isRespondent={isRespondent}
        claimItems={negotiationRounds[round]}
        enableCheckBox={currentState?.enableSelection}
        showRespondantView={
          showRespondantView ||
          checkShowRespondentView(negotiationRounds[round])
        }
        status={status}
        errorText={errorText}
        ownerId={ownerId}
        negotiationRound={negotiationRounds[round].negotiationRound}
        respondentId={respondentId}
      />
    );
  };

  const AgreementSection = () => {
    return (
      <>
        <div style={{ marginTop: 28 }}>
          <CaseAgreement
            title={`Final Award Summary for Case ID: ${caseDetails?.id}`}
            message={caseDetails?.negotiationSummary}
            summaryStatus={caseDetails?.summaryStatus === "completed"}
          />
        </div>
        <div style={{ marginTop: 28 }}>
          <AgreementDocCard
            id={caseDetails?.id}
            status={caseDetails.status}
            isRespondent={isRespondent}
            type={"Download"}
            hasAgreement={caseDetails?.hasAgreementDocument}
          />
        </div>
      </>
    );
  };

  switch (currentState?.key) {
    case AWAITING_RESPONDANT:
      return (
        <div style={{ marginTop: 28 }}>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                expand={true}
                round={round}
                status={_.keys(negotiationRounds).length - 1 !== idx && "error"}
              />
            ))}
        </div>
      );
    case AWAITING_RESPONDANT_QUIT:
        return (
          <div style={{ marginTop: 28 }}>
            {_.keys(negotiationRounds)
              ?.sort()
              ?.map((round, idx) => (
                <RenderClaimBox
                  key={round}
                  expand={_.keys(negotiationRounds).length - 1 === idx}
                  round={round}
                  status={"error"}
                  errorText={
                    _.keys(negotiationRounds).length - 1 === idx
                      ? "Case Closed by Case Manager"
                      : "Progressed but did not converge yet"
                  }
                />
              ))}
          </div>
        );
    case RESPONDANT_ONBOARDED:
      return (
        <div style={{ marginTop: 28 }}>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                expand={true}
                round={round}
                errorText={"Respondent Declined the Case"}
                status={caseDetails.respondentStatus === "declined" && "error"}
              />
            ))}
        </div>
      );
    case NEGOTIATION_ONGOING: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                status={_.keys(negotiationRounds).length - 1 !== idx && "error"}
              />
            ))}
        </div>
      );
    case NEGOTIATION_QUIT_BY_CLAIMENT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                status={"error"}
                errorText={
                  _.keys(negotiationRounds).length - 1 === idx
                    ? "Negotiation Quit by Claimant"
                    : "Progressed but did not converge yet"
                }
              />
            ))}
        </div>
      );
    case NEGOTIATION_QUIT_BY_RESPONDENT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                status={"error"}
                errorText={
                  _.keys(negotiationRounds).length - 1 === idx
                    ? "Negotiation Quit by Respondent"
                    : "Progressed but did not converge yet"
                }
              />
            ))}
        </div>
      );
    case NEGOTIATION_QUIT_BY_CASEMANAGER:
      return (
        <div style={{ marginTop: 28 }}>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                status={"error"}
                errorText={
                  _.keys(negotiationRounds).length - 1 === idx
                    ? "Case Closed by Case Manager"
                    : "Progressed but did not converge yet"
                }
              />
            ))}
        </div>
      );
    case NEGOTIATION_REACHED:
      return (
        <div style={{ marginTop: 28 }}>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                status={
                  _.keys(negotiationRounds).length - 1 === idx
                    ? "success"
                    : "error"
                }
                showRespondantView={true}
              />
            ))}
        </div>
      );
    case CLAIMENT_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            {_.keys(negotiationRounds)
              ?.sort()
              ?.map((round, idx) => (
                <RenderClaimBox
                  key={round}
                  caseId={caseDetails?.id}
                  expand={false}
                  round={round}
                  index={idx}
                  status={
                    _.keys(negotiationRounds).length - 1 === idx
                      ? "success"
                      : "error"
                  }
                  showRespondantView={true}
                />
              ))}
          </div>
          <AgreementSection />
        </>
      );
    case RESPONDENT_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            {_.keys(negotiationRounds)
              ?.sort()
              ?.map((round, idx) => (
                <RenderClaimBox
                  key={round}
                  caseId={caseDetails?.id}
                  expand={false}
                  round={round}
                  index={idx}
                  status={
                    _.keys(negotiationRounds).length - 1 === idx
                      ? "success"
                      : "error"
                  }
                  showRespondantView={true}
                />
              ))}
          </div>
          <AgreementSection />
        </>
      );
    case BOTH_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            {_.keys(negotiationRounds)
              ?.sort()
              ?.map((round, idx) => (
                <RenderClaimBox
                  key={round}
                  caseId={caseDetails?.id}
                  expand={false}
                  round={round}
                  index={idx}
                  status={
                    _.keys(negotiationRounds).length - 1 === idx
                      ? "success"
                      : "error"
                  }
                  showRespondantView={true}
                />
              ))}
          </div>
          <AgreementSection />
        </>
      );
    default:
      return null;
  }
}
