import React from "react";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Modal } from "@material-ui/core";
import styled from "styled-components";

const ShortCutKeys = ({ showShortcuts, setShowShortCuts }) => {
  const actions = [
    { key: "Alt+k", title: "Play/Pause" },
    { key: "Alt+l", title: "Move 2s Forward" },
    { key: "Alt+j", title: "Move 2s Backward" },
    { key: "Alt+r", title: "Move 10s Backward" },
    { key: "Alt+s", title: "Save transcript" },
    { key: "Alt+t", title: "Open the speaker edit window " },
    { key: "Tilde(`)", title: "Copy the current timestamp" },
  ];

  return (
    <Modal open={showShortcuts} onClose={() => setShowShortCuts(false)}>
      <DrawerContainer>
        <HeadingContainer>
          <Heading>{"Keyboard ShortCut Keys"}</Heading>
          <CloseModal
            onClick={() => setShowShortCuts(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <NoteContainer>
          <div className="ml40 mr40">
            <div
              className="mt20 mb20 h300"
              style={{
                overflow: "auto",
              }}
            >
              {actions.map((action) => (
                <div className="keys_row">
                  <div className="keys_label">{action.key}</div>
                  <div className="keys_name">{action.title}</div>
                </div>
              ))}
            </div>
          </div>
        </NoteContainer>
      </DrawerContainer>
    </Modal>
  );
};

export default ShortCutKeys;

const DrawerContainer = styled.div`
  max-width: 516px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  & .keys_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    & .keys_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .keys_label {
        width: 55px;
        font-size: 16px;
        font-weight: 600;
        margin-right: 13px;
        border-radius: 8px;
        background-color: ${COLORS.BORDER_GREY};
        font-family: ${theme.fonts.primaryFontSemiBold};
      }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 33px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const NoteContainer = styled.p`
  padding-top: 14px;
  padding-left: 10px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  overflow: auto;
`;
