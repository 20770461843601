import React, { useState, Fragment } from "react";
import * as PDFJS from "pdfjs-dist";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import SearchBar from "../../common/CustomTable/common/SearchBar";
import { Button, Modal } from "@material-ui/core";
import "pdfjs-dist/build/pdf.worker.entry";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";

PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;

const PdfViewer = ({ url, bundleCode }) => {
  // const pdfContainerRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [inputText, setInputText] = useState("");
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const pdfFiles = [url];
  const [page, setPage] = useState();

  const handleSearch = async () => {
    let results = [];
    let pageNumbers = [];
    for (let i = 0; i < pdfFiles.length; i++) {
      try {
        setLoader({ state: true, message: "Fetching page..." });
        const pdf = await PDFJS.getDocument(pdfFiles[i]).promise;
        const numPages = pdf.numPages;
        for (let pageIndex = 1; pageIndex <= pdf.numPages; pageIndex++) {
          const page = await pdf.getPage(pageIndex);
          const textContent = await page.getTextContent();
          const text = textContent.items.map((item) => item.str);

          if (text.includes(inputText)) {
            results.push({ fileIndex: i, pageIndex });
          }

          const matchingIndices = text.reduce((indices, value, index) => {
            if (value.toLowerCase().includes(inputText.toLowerCase())) {
              indices.push(index);
            }
            return indices;
          }, []);

          if (matchingIndices.length > 0) {
            results.push({
              pageIndex,
              indices: matchingIndices,
            });
          }
        }
        const pageNumbersToShow = results.map((item) => item.pageIndex);
        if (pageNumbersToShow.length > 0) {
          for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
            if (!pageNumbersToShow.includes(pageNumber)) {
              continue; // Skip pages not in the desired list
            }
            // Get the page and render it
            const page = await pdf.getPage(pageNumber);

            pageNumbers.push(page._pageIndex + 1);
            // const viewport = page.getViewport({ scale: 1.5 });
            // const canvas = document.createElement("canvas");
            // const canvasContext = canvas.getContext("2d");
            // canvas.width = viewport.width;
            // canvas.height = viewport.height;
            // await page.render({ canvasContext, viewport }).promise;

            setModal(true);
            // pdfContainerRef.current.appendChild(page._pageIndex + 1);

            // setTimeout(() => {
            //   pdfContainerRef.current.appendChild(canvas);
            // }, 10);
          }
          setPage(pageNumbers);
        } else {
          enqueueSnackbar("Search keyword is not available", {
            variant: "success",
          });
        }
      } catch (err) {
        const message = getErrorMessage(err);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        results = [];
      }
    }
  };

  return (
    <Fragment>
      <Container>
        <SearchBar
          placeHolderText="Search keyword"
          backgroundColor="#ffffff"
          {...{ inputText, setInputText }}
        />
        {inputText?.length ? (
          <ButtonContainer onClick={() => handleSearch()}>
            {"Search"}
          </ButtonContainer>
        ) : null}
      </Container>
      <Modal open={modal} onClose={() => setModal(false)}>
        <DrawerContainer>
          <HeadingContainer>
            <Heading>{"Pdf"}</Heading>
            <CloseModal
              src={require("../../../assets/images/closeModal.svg")}
              onClick={() => setModal(false)}
            />
          </HeadingContainer>
          <FormContainer>
            {/* <div ref={pdfContainerRef} /> */}
            {page && page.length
              ? page.map((page) => (
                  <div className="pdf-listing">
                    <div className="pdf_name">
                      <HyperLink
                        onClick={() => window.open(`${url}#page=${page}`)}
                      >{`${bundleCode}#page=${page}`}</HyperLink>
                    </div>
                  </div>
                ))
              : null}
          </FormContainer>
        </DrawerContainer>
      </Modal>
    </Fragment>
  );
};

export default PdfViewer;

export const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  line-height: 1.9;
  margin-top: 12px;
  align-items: center;
  color: ${COLORS.INPUT_LABEL};
  display: inline-block;
`;

const ButtonContainer = styled(Button)(({ disabled }) => ({
  marginLeft: "0.9em",
  width: "100%",
  height: "2.6em",
  minWidth: 36,
  outline: "2px solid #00838c",
  fontFamily: theme.fonts.primaryFontSemiBold,
  color: "#00838c",
}));

const Container = styled.div`
  margin: 0 15px;
  //   background-color: white;
  outline: none;
  border-radius: 8px;
  display: flex;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  & .pdf_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    align-items: center;
    & .pdf_name {
      font-size: 14px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
  & .pdf-listing {
    overflow: auto;
    border: 4px solid transparent;
    border-radius: 8px;
    margin-left: 10px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 340px;
  }
`;

const FormContainer = styled.div`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 180px;
  overflow: auto;
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #00838c;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
`;
