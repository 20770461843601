import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const Container = styled.div`
  padding: 24px 43px;
  display: flex;
  flex-direction: column;
`;

export const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding-bottom: 12px;
  padding-left: 13px;
  padding-right: 11px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 46px;
    padding-right: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledForm = styled.form`
  @media ${theme?.breakpoints?.sm_up} {
    width: 100%;
  }
  & .heading {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    & .delete {
      height: 20px;
      width: 20px;
      margin-bottom:15px;
      cursor: pointer;
      @media ${theme?.breakpoints?.sm_up} {
        margin: 34px 0 21px 0;
      }
    }
`;

export const Subheading = styled.div`
  float: right;
  margin-bottom: 5px;
  & a {
    margin-left: 10px;
    font-family: ${theme.fonts.primaryFontSemiBold};
    color: ${COLORS.BTN_GREEN};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  margin-left: 10px;
`;

export const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
  max-width: 70%;
  & .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  & .template {
    margin-top: 0px;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
  }
  & .mediator-type {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column !important;
      & .MuiFormControlLabel-root {
        padding: 10px 0px;
      }
    }
  }
  @media ${theme?.breakpoints?.sm_down} {
    & .template {
      margin-top: 15px;
    }
  }
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  cursor: pointer;
  display: table;
  font-size: 14px;
  width: 100%;
  @media (min-width: 780px) {
    font-size: 12px;
    width: auto;
  }
`;

export const Button = styled.div`
  width: 50%;
`;

export const CheckboxGrid = styled.div`
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 0px;
  }
`;

export const ContainerGrid = styled.div`
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }
`;
