import React, { useRef } from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import {
  Label,
  ButtonContainer,
  LabelValueContainer,
  Value,
  ProfilePic,
  ProfilePicContainer
} from "../styles";
import FormField from "../../../common/FormField/FormField";
import { PrimaryCTAButton } from "../../../common/Buttons";
import useLoader from "../../../../hooks/useLoader";
import AWSService from "../../../../services/AWSService";
import { Formik } from "formik";
import { EditProfileSchema } from "../../../../helpers/validationSchema";
import { states } from "../../../../helpers/constants";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import SystemService from "../../../../services/SystemService";
import labels from "../../../../helpers/labels.json";
import _ from "lodash";

export default function ProfileModal({ modal, setModal, user, setUser }) {
  const fileRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file.type.includes("image/")) {
      try {
        setLoader({ state: true, message: `Uploading file: ${file.name} ...` });
        const payload = {
          key: file.name
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Successfully Uploaded", {
            variant: "success"
          });
        }
      } catch (error) {
        setFieldError(field, "Could not upload this file");
      } finally {
        setLoader({ state: false });
      }
    } else {
      return enqueueSnackbar("Invalid File Type", {
        variant: "error"
      });
    }
  }

  async function onFormSubmit(values) {
    const postData = JSON.parse(JSON.stringify(values));
    try {
      setLoader({ state: true, message: "Updating profile..." });
      const response = await SystemService.setProfile(user.id, postData);
      if (response.user) {
        setUser(response.user);
        enqueueSnackbar(response?.message, {
          variant: "success"
        });
        setModal(false);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Drawer
      anchor={"right"}
      open={modal}
      onClose={() => setModal(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{_.upperCase(labels.edit_profile)}</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <Formik
            initialValues={{
              name: user?.name || "",
              houseNumber: user?.houseNumber || "",
              locality: user?.locality || "",
              city: user?.city || "",
              state: user?.state || "TN",
              pin: user?.pin || "",
              landmark: user?.landmark || "",
              avatarUrl: user?.avatarUrl || ""
            }}
            enableReinitialize
            validateOnBlur
            validationSchema={EditProfileSchema}
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldError,
              handleBlur
            }) => (
              <>
                <ProfilePicContainer>
                  <ImageWrapper onClick={() => fileRef.current.click()}>
                    <ProfilePic
                      src={
                        values?.avatarUrl
                          ? values.avatarUrl
                          : require("../../../../assets/images/userPlaceholder.svg")
                      }
                    ></ProfilePic>
                    <PickerIcon
                      src={require("../../../../assets/images/imagePicker.svg")}
                    />
                  </ImageWrapper>
                </ProfilePicContainer>
                <BasicDetails>
                  <LabelValueContainer style={{ marginBottom: 10 }}>
                    <Label>
                      {labels.profile_name}
                      <span className="required-star"> *</span>
                    </Label>
                    <FormField
                      input={{
                        name: "name",
                        type: "text",
                        label: null
                      }}
                      {...{
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur
                      }}
                    />
                    {/* <Value>{user?.name}</Value> */}
                  </LabelValueContainer>
                  <LabelValueContainer
                    style={{ width: "auto", marginBottom: 10 }}
                  >
                    <Label style={{ marginBottom: 5 }}>
                      {labels.profile_email}
                    </Label>
                    <Value>{user?.email}</Value>
                  </LabelValueContainer>
                  <LabelValueContainer
                    style={{ width: "auto", marginBottom: 10 }}
                  >
                    <Label style={{ marginBottom: 5 }}>
                      {labels.profile_mobile}
                    </Label>
                    <Value>{user?.mobile}</Value>
                  </LabelValueContainer>
                </BasicDetails>

                <FormField
                  input={{
                    name: "houseNumber",
                    label: labels.house_no_label.toUpperCase(),
                    type: "text",
                    placeholder: "Eg. Flat 2C, PKP Apartment",
                    required: true
                  }}
                  {...{
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur
                  }}
                />
                <Flex>
                  <HalfWidth>
                    <FormField
                      input={{
                        name: "locality",
                        label: _.upperCase(labels.locality),
                        type: "text",
                        placeholder: "Eg. Saket",
                        required: true
                      }}
                      {...{
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur
                      }}
                    />
                  </HalfWidth>
                  <HalfWidth>
                    <FormField
                      input={{
                        name: "city",
                        label: _.upperCase(labels.city),
                        type: "text",
                        placeholder: "Eg. Delhi",
                        required: true
                      }}
                      {...{
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur
                      }}
                    />
                  </HalfWidth>
                </Flex>
                <Flex>
                  <HalfWidth>
                    <FormField
                      input={{
                        name: "state",
                        label: _.upperCase(labels.state),
                        type: "select",
                        required: true
                      }}
                      menuItems={[...states]}
                      {...{
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur
                      }}
                    />
                  </HalfWidth>
                  <HalfWidth>
                    <FormField
                      input={{
                        name: "pin",
                        label: _.upperCase(labels.pin),
                        type: "number",
                        placeholder: "Eg. 110016",
                        required: true
                      }}
                      {...{
                        touched,
                        errors,
                        values,
                        handleBlur
                      }}
                      handleChange={e => {
                        if (Number(e?.target?.value) > 999999) {
                          return;
                        } else {
                          handleChange(e);
                        }
                      }}
                    />
                  </HalfWidth>
                </Flex>
                <HalfWidth>
                  <FormField
                    input={{
                      name: "landmark",
                      label: _.upperCase(labels.landmark),
                      type: "text",
                      placeholder: "Eg. Garden of 5 senses",
                      required: true
                    }}
                    {...{
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleBlur
                    }}
                  />
                </HalfWidth>
                <ButtonContainer>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    style={{ width: 413, margin: "0 auto" }}
                  >
                    {_.upperCase(labels.update_profile)}
                  </PrimaryCTAButton>
                </ButtonContainer>
                <input
                  ref={fileRef}
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={e =>
                    s3Upload(
                      e.target.files[0],
                      "avatarUrl",
                      setFieldValue,
                      setFieldError
                    )
                  }
                />
              </>
            )}
          </Formik>
        </AgentFormContainer>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 618px;
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  padding-top: 14px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HalfWidth = styled.div`
  width: 48%;
`;

const PickerIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 34px;
  height: 34px;
  object-fit: contain;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 91px;
  height: 91px;
  border-radius: 50%;
  margin-bottom: 2px;
  cursor: pointer;
  user-select: none;
`;

const BasicDetails = styled.div`
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
`;
