import React from "react";
import {
  StyledFormElementsWrapper,
  ContainerGrid,
  FormLabel,
  Subheading,
} from "../styles";
import FormField from "../../../common/FormField/FormField";
import { CustomFileUpload } from "../../../common/FormInputs";
import CustomSelect from "./CustomSelect";

const GenerateNotice = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  s3Upload,
  getFileName,
  menuItems,
  menuNotice,
  menuTemplateCode,
  menuHeaderFooter,
  getNoticeTemplates,
}) => {
  let excelTemplate =
    menuTemplateCode[values?.noticeCategory]?.length &&
    menuTemplateCode[values?.noticeCategory]?.filter(
      (template) => template?.templateCode === values?.templateCode
    )[0]?.excelTemplate;

  const inputs = [
    {
      type: "select",
      name: "noticeCategory",
      label: "Notice Category",
      required: true,
      items: menuNotice,
    },
    {
      type: "select",
      name: "templateCode",
      label: "Template Code",
      required: true,
      items: menuTemplateCode[values?.noticeCategory]?.length
        ? menuTemplateCode[values?.noticeCategory]?.map(
            ({ id, templateCode }) => ({
              id: id,
              label: templateCode,
              value: templateCode,
            })
          )
        : [],
    },
    {
      type: "select",
      name: "letterHeadName",
      label: "Letter Head Name",
      required: false,
      items: menuHeaderFooter,
    },
    {
      type: "number",
      name: "lotNo",
      label: "Lot Number",
      required: true,
    },
  ];

  const upload_templates = [
    {
      name: "excelFile",
      label: "Upload Excel Template (xlsx)",
      type: "upload",
      required: true,
      accept: ".xlsx",
      types: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
      ],
      downloadType: "Please download the xlsx template",
      url: excelTemplate,
    },
  ];

  return (
    <>
      <StyledFormElementsWrapper style={{ marginBottom: 8, marginTop: 15 }}>
        <ContainerGrid className="disabledArrows">
          <CustomSelect
            name="partyId"
            trigger={true}
            {...{
              errors,
              touched,
              setFieldValue,
              menuItems,
              getNoticeTemplates,
            }}
          />
          {/* <FormField
            white
            input={{
              type: "select",
              name: "partyId",
              label: "Select a Party",
              required: true,
            }}
            showId
            menuItems={menuItems}
            {...{
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
            }}
            handleChange={(e) => getNoticeTemplates(e?.target?.value)}
          /> */}
          {inputs.map((input, index) => {
            return (
              <FormField
                key={index}
                menuItems={input.items}
                {...{
                  touched,
                  errors,
                  input,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }}
              />
            );
          })}
          {upload_templates.map((upload, index) => (
            <div style={{ marginTop: 10 }}>
              <FormLabel>
                {upload.label} <span className="required-star"> *</span>
              </FormLabel>
              <CustomFileUpload
                error={touched[upload.name] && errors[upload.name]}
                setFile={(file) =>
                  s3Upload(file, upload.name, setFieldValue, upload.types)
                }
                style={{
                  width: "100%",
                }}
                accept={upload.accept}
                file={{ name: getFileName(values[upload.name]) }}
              />
              <Subheading>
                <a target="_blank" rel="noopener noreferrer" href={upload.url}>
                  {upload.downloadType}
                </a>
              </Subheading>
            </div>
          ))}
        </ContainerGrid>
      </StyledFormElementsWrapper>
    </>
  );
};

export default GenerateNotice;
