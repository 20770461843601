import { Formik } from "formik";
import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { AddZoomLinkValidation } from "../../../helpers/validationSchema";
import { PrimaryCTAButton } from "../Buttons";
import FormField from "../FormField/FormField";
import Modal from "../Modal";
import moment from "moment";

const Index = ({ modal, setModal, onSubmit }) => {
  const onFormSubmit = (values) => {
    const date = moment(new Date(values.startDate)).format("YYYY-MM-DD");
    const time = moment(new Date(values?.startTime)).format("HH:mm:ss");
    const selectedTime = moment(`${date} ${time}`);
    const postData = {
      zoomLink: {
        url: values?.zoomLink,
        date: moment.utc(selectedTime).format(),
        time: moment.utc(selectedTime).format(),
      },
    };
    onSubmit(postData, modal?.data);
  };

  return (
    <Modal {...{ modal, setModal }}>
      <HeadingContainer>
        <Heading>{modal?.data?.id ? "Update" : "Add"} VC Link</Heading>
        <CloseModal
          onClick={() => setModal({})}
          src={require("../../../assets/images/closeModal.svg")}
        />
      </HeadingContainer>
      <FormContainer>
        <Formik
          initialValues={{
            zoomLink: modal?.data?.zoomLink?.url || "",
            startDate: modal?.data?.id
              ? new Date(modal?.data?.zoomLink?.date)
              : new Date(),
            startTime: modal?.data?.id
              ? new Date(modal?.data?.zoomLink?.date)
              : null,
          }}
          validationSchema={AddZoomLinkValidation}
          onSubmit={onFormSubmit}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
          }) => (
            <StyledForm onSubmit={handleSubmit}>
              <div className="link-container">
                <FormField
                  input={{
                    name: "zoomLink",
                    label: "VC Link",
                    type: "text",
                    required: true,
                    placeholder: "https://zoom.us/j/972...",
                  }}
                  {...{
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                  }}
                />
              </div>

              <div className="date-time">
                <div className="date">
                  <FormField
                    input={{
                      name: "startDate",
                      label: "Date",
                      type: "date",
                      required: true,
                    }}
                    minDate={new Date()}
                    {...{
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                </div>
                <div className="time">
                  <FormField
                    input={{
                      name: "startTime",
                      label: "Time (IST)",
                      type: "time",
                      required: true,
                    }}
                    {...{
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                </div>
              </div>
              <ButtonContainer style={{ marginTop: 40 }}>
                <PrimaryCTAButton
                  type="submit"
                  onClick={handleSubmit}
                  style={{ width: 413, margin: "auto" }}
                >
                  {modal?.data?.id ? "Update" : "Add"} Link
                </PrimaryCTAButton>
              </ButtonContainer>
            </StyledForm>
          )}
        </Formik>
      </FormContainer>
    </Modal>
  );
};

export default Index;

const FormContainer = styled.div`
  padding: 15px;
  .link-container {
    margin: 12px;
  }
  .date-time {
    display: flex;
    .date {
      margin: 12px;
      width: 100%;
    }
    .time {
      margin: 12px;
      width: 100%;
    }
  }
`;

const HeadingContainer = styled.div`
  padding-top: 28px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
  span {
    font-family: ${theme.fonts.primaryFontSemiBold};
    text-decoration: underline;
    margin-left: 23px;
    font-size: 12px;
    color: ${COLORS.BTN_GREEN};
    user-select: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;
