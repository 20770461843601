import API from "../api/manager";
import config from "../api/config";

const getNoticeTemplates = (partyId) => {
  return API({
    method: "GET",
    url: config.urls.notice.getNoticeTemplates + `/${partyId}`,
  });
};

const bulkNoticeGenerate = (data) => {
  return API({
    method: "POST",
    url: config.urls.notice.bulkNoticeGenerate,
    data,
  });
};

const uploadNoticeTemplates = (data) => {
  return API({
    method: "POST",
    url: config.urls.notice.uploadNoticeTemplates,
    data,
  });
};

const addLetterHeadOption = (data) => {
  return API({
    method: "POST",
    url: config.urls.notice.addLetterHeadOption,
    data,
  });
};

const updateNoticeTemplates = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.notice.uploadNoticeTemplates + `/${id}`,
    data,
  });
};

const getLetterHeadOption = () => {
  return API({
    method: "GET",
    url: config.urls.notice.getLetterHeadOption,
  });
}

const updateLetterHeadOption = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.notice.addLetterHeadOption + `/${id}`,
    data,
  });
};

export default {
  getNoticeTemplates,
  bulkNoticeGenerate,
  uploadNoticeTemplates,
  addLetterHeadOption,
  updateNoticeTemplates,
  getLetterHeadOption,
  updateLetterHeadOption,
};
