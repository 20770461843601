import { Modal } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import BundleService from "../../../services/BundleService";

const inputs = [
  {
    type: "number",
    name: "pageNo",
    label: "PageNo",
    required: true,
  },
  {
    type: "textarea",
    name: "annotationContent",
    label: "Annotation",
    required: true,
  },
];

const Annotation = ({ id, annotation, setAnnotation }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Loading..." });
      const payload = {
        pageNo: values?.pageNo,
        annotationContent: values?.annotationContent,
      };
      const response = await BundleService.addAnnotationToBundles(id, payload);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setAnnotation(false);
    }
  }
  return (
    <Modal
      open={annotation}
      onClose={() => setAnnotation(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer>
        <Container>
          <HeadingContainer>
            <Heading>{"Annotation"}</Heading>
            <CloseModal
              onClick={() => setAnnotation(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            <Formik
              initialValues={{
                pageNo: "",
                annotationContent: "",
              }}
              enableReinitialize
              validateOnBlur
              validateOnChange
              onSubmit={onFormSubmit}
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                setFieldError,
                handleBlur,
              }) => (
                <StyledForm onSubmit={handleSubmit}>
                  {inputs.map((input, index) => (
                    <FormField
                      key={index}
                      {...{
                        input,
                        values,
                        handleChange,
                        errors,
                        handleSubmit,
                        touched,
                        setFieldValue,
                        setFieldError,
                        handleBlur,
                      }}
                    />
                  ))}
                  <ButtonContainer>
                    <PrimaryOutlinedCTAButton
                      style={{ width: "45%" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setAnnotation(false);
                      }}
                    >
                      {"Cancel"}
                    </PrimaryOutlinedCTAButton>
                    <PrimaryCTAButton
                      style={{ width: "45%" }}
                      disabled={!values?.annotationContent || !values?.pageNo}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      {"Submit"}
                    </PrimaryCTAButton>
                  </ButtonContainer>
                </StyledForm>
              )}
            </Formik>
          </FormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
};

export default Annotation;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 456px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 40px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.p`
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  min-height: 200px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const StyledForm = styled.form``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;
