import React from "react";
import { navigate } from "@reach/router";
import moment from "moment";
import { HyperLink, Bold } from "./styles";
import PartyService from "../../../services/PartyService";
import _ from "lodash";
import {
  getLabelByPartyStatus,
  parseTimeStamp,
} from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";

function handleNavigateToParty(type, id) {
  switch (type) {
    case "draft":
      navigate(`/dashboard/verify-parties/draft/${id}`);
      break;
    default:
      navigate(`/dashboard/verify-parties/${id}`);
      break;
  }
}

export const columns = [
  {
    field: "id",
    title: labels.party_id,
    tooltip: "Placeholder",
    render: (rowData) => (
      <HyperLink
        onClick={() => handleNavigateToParty(rowData?.status, rowData?.id)}
      >
        {rowData.id}
      </HyperLink>
    ),
    headerStyle: {
      paddingLeft: 35,
    },
  },
  {
    field: "name",
    title: labels.party_name,
    render: (rowData) => <Bold onClick={() => {}}>{rowData.name}</Bold>,
  },
  {
    field: "kind",
    title: labels.party_type,
    tooltip: "Placeholder",
    render: (rowData) => <>{_.capitalize(rowData.kind)}</>,
  },
  {
    field: "role",
    title: labels.party_role,
    tooltip: "Placeholder",
    sorting: false,
    render: (rowData) => (
      <>
        {rowData.primaryAgentEmail
          ? rowData.primaryAgentCanCreateAgent
            ? "Primary Agent"
            : "Agent"
          : "Party Agent"}
      </>
    ),
  },
  {
    field: "documentKind",
    tooltip: "Placeholder",
    title: labels.id_type,
    render: (rowData) => (
      <>{rowData.documentKind === "pan" ? "PAN" : "Aadhar"}</>
    ),
  },
  {
    field: "status",
    tooltip: "Placeholder",
    title: labels.status,
    render: (rowData) => getLabelByPartyStatus(rowData.status),
  },
  {
    field: "created_at",
    tooltip: "Placeholder",
    title: labels.created_on,
    render: (rowData) => (
      <>{moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}</>
    ),
  },
];

export async function getParties(query = "", id) {
  try {
    const response = await PartyService.getProfileParties(query, id);
    const stats = await PartyService.stats(id);
    return { ...response, stats };
  } catch (error) {
    return error;
  }
}

export const filterByStatus = (selectedFilter) => {
  let key = "";
  switch (selectedFilter) {
    case "Drafts":
      key = "draft";
      break;
    case "Under Verification":
      key = "underReview";
      break;
    case "Active":
      key = "active";
      break;
    default:
      break;
  }
  return key;
};
