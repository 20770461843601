import React, { useState, useEffect } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from "@material-ui/core";
import StatusBadge from "../../../common/StatusBadge";
import { DropDown, Icon, Actions, Heading, Header } from "../styles";
import ClaimTabel from "./ClaimTable";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    border: `solid 0.5px #e1e3ee`,
  },
  content: {
    margin: `0 !important`,
    "& .MuiExpansionPanelSummary-content.Mui-expanded": {
      margin: `0 !important`,
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: `0 21px 21px !important`,
  },
}));

export default function ClaimBox({
  title,
  claimItems,
  status,
  enableCheckBox = false,
  expand,
  showRespondantView,
  isRespondent,
  errorText,
  negotiationRound,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(expand);
  const [items, setItems] = useState(claimItems ? claimItems : []);
  const [trigggerStateChange, setTrigggerStateChange] = useState(false);

  useEffect(() => {
    if (trigggerStateChange) {
      setTrigggerStateChange(false);
    }
    setItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigggerStateChange]);

  const checkboxEnable = (items, index) => {
    return items[index]?.some((el) => el?.offer?.status === "accepted");
  };

  return (
    <ExpansionPanel className={classes.root} expanded={expanded}>
      <ExpansionPanelSummary
        classes={{ content: classes.content }}
        onClick={() => setExpanded((e) => !e)}
      >
        <Header>
          <div className="title-container">
            <Heading style={{ marginBottom: "unset", marginLeft: "21px" }}>
              {title}
            </Heading>
            {status && (
              <StatusBadge
                text={
                  status === "success"
                    ? "Negotiation Successful"
                    : errorText
                    ? errorText
                    : "Progressed but did not converge yet"
                }
                status={status}
              />
            )}
          </div>
          <Actions>
            <DropDown>
              <Icon
                src={require("../../../../assets/images/dropdownIcon.svg")}
                alt="dropdown"
                expanded={expanded}
              />
            </DropDown>
          </Actions>
        </Header>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <div>
          {items.claimantClaims && (
            <ClaimTabel
              {...{
                disableChekBox: showRespondantView || !enableCheckBox,
                enableCheckBox:
                  negotiationRound > 1 ||
                  enableCheckBox ||
                  showRespondantView ||
                  checkboxEnable(items, "claimantClaims"),
                items: items.claimantClaims,
                heading: "Claimant Party’s  Claim",
              }}
            />
          )}
          {showRespondantView && (
            <ClaimTabel
              {...{
                enableCheckBox: enableCheckBox || showRespondantView,
                disableChekBox:
                  (showRespondantView && isRespondent) ||
                  !enableCheckBox ||
                  !expand,
                items: items.respondentClaims,
                isRespondent,
                heading: "Respondent Party’s  Claim",
              }}
            />
          )}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
