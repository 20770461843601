import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import StyledAppBar from "./StyledAppBar";
import Sidebar from "./Sidebar";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    marginTop: 74
  }
}));

export function Header({
  children,
  showSidebar = false,
  selectedItem,
  selectedSubItem
}) {
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = useState(
    localStorage.getItem("Collapse") === "true"
  );

  useEffect(() => {
    localStorage.setItem("Collapse", showDrawer);
  }, [showDrawer]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <StyledAppBar {...{ setShowDrawer, showDrawer }} />
      {showSidebar && (
        <Sidebar
          {...{ selectedItem, selectedSubItem, showDrawer, setShowDrawer }}
        />
      )}
      <main className={classes.content}>{children}</main>
    </div>
  );
}
