import React from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
// import { ButtonContainer, Label } from "../styles";
// import { PrimaryCTAButton } from "../../../common/Buttons";
// import SearchBar from "../../../common/CustomTable/common/SearchBar";
// import CustomChip from "../../../common/Chip";
// import { CustomCheckbox } from "../../../common/FormInputs";

// const array = [
//   { label: "Name", value: false },
//   { label: "Name 2", value: true },
// ];
// const role = [
//   { label: "Respondent", value: false },
//   { label: "Claimant", value: true },
// ];

export default function FilterModal({ modal, setModal }) {
  return (
    <Drawer anchor={"right"} open={modal} onClose={() => setModal(false)}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>
            Filters <span>Remove All filters</span>
          </Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        {/* 
        <AgentFormContainer>
          <div>
            <Label>Party Role</Label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: 340,
              }}
            >
              {role.map((ar) => (
                <CheckboxContainer>
                  <CustomCheckbox checked={ar.value} />
                  <span>{ar.label}</span>
                </CheckboxContainer>
              ))}
            </div>
          </div>
          <Label>Party</Label>
          <InputBox>
            <SearchBar
              placeHolderText="Search Parties"
              backgroundColor="#ffffff"
              setInputText={Party.setInputText}
              inputText={Party.inputText}
              width="100%"
            />
            <ChipContainer>
              <CustomChip>Selected Party</CustomChip>
            </ChipContainer>
            <Seperator />
            {array.map((ar) => (
              <CheckboxContainer>
                <CustomCheckbox checked={ar.value} />
                <span>{ar.label}</span>
              </CheckboxContainer>
            ))}
          </InputBox>
          <Label>Counter Party</Label>
          <InputBox>
            <SearchBar
              placeHolderText="Search Parties"
              backgroundColor="#ffffff"
              setInputText={Party.setInputText}
              inputText={Party.inputText}
              width="100%"
            />
            <ChipContainer>
              <CustomChip>Selected Party</CustomChip>
            </ChipContainer>
            <Seperator />
            {array.map((ar) => (
              <CheckboxContainer>
                <CustomCheckbox checked={ar.value} />
                <span>{ar.label}</span>
              </CheckboxContainer>
            ))}
          </InputBox>
          <ButtonContainer>
            <PrimaryCTAButton onClick={() => {}} style={{ width: 399 }}>
              Apply Filters
            </PrimaryCTAButton>
          </ButtonContainer>
        </AgentFormContainer>
      */}
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 618px;
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// const InputBox = styled.div`
//   border-radius: 5px;
//   background-color: #f9f9f9;
//   width: 399px;
//   padding: 9px;
//   margin-top: 12px;
//   margin-bottom: 14px;
// `;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

// const ChipContainer = styled.div`
//   margin-top: 13px;
// `;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
  span {
    font-family: ${theme.fonts.primaryFontSemiBold};
    text-decoration: underline;
    margin-left: 23px;
    font-size: 12px;
    color: ${COLORS.BTN_GREEN};
    user-select: none;
  }
`;

// const AgentFormContainer = styled.div`
//   padding-top: 14px;
//   padding-left: 38px;
//   padding-right: 38px;
//   padding-bottom: 26px;
//   & form {
//     padding-top: 10px;
//   }
// `;

// const Seperator = styled.div`
//   width: 100%;
//   height: 1px;
//   background-color: ${COLORS.INPUT_BORDER};
//   margin-top: 13px;
// `;

// const CheckboxContainer = styled.div`
//   display: flex;
//   width: 100%;
//   align-items: center;
//   span {
//     color: ${COLORS.COLOR_DARK};
//     font-family: ${theme.fonts.primaryFontRegular};
//   }
// `;
