import React, { useState } from "react";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import styled, { css } from "styled-components";
import { WhatsApp } from "@material-ui/icons";
import WhatsAppModal from "./index";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  customTooltip: {
    backgroundColor: "#ffffff", // Replace with the color you want
    color: "#293461", // Text color, adjust as needed
    border: "1px solid #00838c",
    fontFamily: "openSans-bold, sans-serif",
  },
});

// Create a functional component with withStyles
const CustomTooltip = withStyles(styles)((props) => {
  const { classes, ...other } = props;

  return <Tooltip classes={{ tooltip: classes.customTooltip }} {...other} />;
});

export default function WhatsAppIcon({ id, isActionsDisabled, title }) {
  const [modal, setModal] = useState(false);

  return (
    <Card disabled={isActionsDisabled}>
      <div
        className="add_circle"
        onClick={() => !isActionsDisabled && setModal(true)}
      >
        <CustomTooltip title="Send to Whatsapp" placement="right">
          <WhatsApp className="add_icon" />
        </CustomTooltip>
      </div>
      {modal && <WhatsAppModal {...{ id, modal, setModal, title }} />}
    </Card>
  );
}

export const Card = styled.div`
  width: 32px;
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
      & .add_circle {
        cursor: not-allowed !important;
      }
    `}
  & .add_case_casemanager {
    color: ${COLORS.INPUT_LABEL};
    font-family: ${theme.fonts.primaryFontSemiBold};
  }

  & .add_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    cursor: pointer;
    background-color: rgba(0, 131, 140, 0.13);
  }
  & .add_icon {
    color: ${COLORS.BTN_GREEN};
    font-size: 18px;
  }
`;
