import React, { Fragment } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import OrderDictation from "./LiveDictation";
import { Refresh } from "@material-ui/icons";
import { Button, Tooltip } from "@material-ui/core";

const ActionBar = ({
  actions = [],
  breadcrumbs = [],
  onBreadcrumbClick = () => {},
  status,
  setReferesh,
  update,
  timeInterval,
  settings,
  caseId,
  mergedBlobURL,
  setMergedBlobURL,
  isLocalTranscriptionServer,
}) => {
  return (
    <ActionBarContainer>
      <Breadcrumbs>
        {breadcrumbs.map((breadcrumb, index) => (
          <Fragment key={`breadcrumb-${breadcrumb}-${index}`}>
            <Breadcrumb onClick={() => onBreadcrumbClick(breadcrumbs[index])}>
              {breadcrumb}
            </Breadcrumb>
            {index < breadcrumbs.length - 1 && <Breadcrumb>{">"}</Breadcrumb>}
          </Fragment>
        ))}
      </Breadcrumbs>
      <ActionButtonContainer></ActionButtonContainer>
      <ActionButtonContainer>
        {update ? (
          <MuiButton>
            <Tooltip title="Refresh" placement="top">
              <Refresh
                style={{ fill: "#00838c" }}
                onClick={() => setReferesh(true)}
              />
            </Tooltip>
          </MuiButton>
        ) : null}
        {actions.map((action, index) => (
          <ActionBtn
            outlined={action.outlined}
            hidden={action.hidden}
            key={`action-${action.text}-${index}`}
            {...action.props}
          >
            {action.text}
          </ActionBtn>
        ))}
        {status ? null : (
          <ActionButton>
            <OrderDictation
              {...{
                timeInterval,
                settings,
                caseId,
                mergedBlobURL,
                setMergedBlobURL,
                isLocalTranscriptionServer,
              }}
            />
          </ActionButton>
        )}
      </ActionButtonContainer>
    </ActionBarContainer>
  );
};

export default ActionBar;

const ActionBarContainer = styled.div`
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
  padding: 0px 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Breadcrumb = styled.div`
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-right: 4px;
  cursor: pointer;
  color: ${COLORS.COLOR_DARK};
  user-select: none;
`;

const ActionButton = styled.div`
  border-radius: 4px;
  width: 161px;
  height: 30px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  text-align: center;
  outline: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  margin-left: 23px;
`;

const ActionBtn = styled.button`
  border-radius: 4px;
  background-color: ${({ outlined }) =>
    outlined ? COLORS.PRIMARY_WHITE : COLORS.BTN_GREEN};
  color: ${({ outlined }) =>
    outlined ? COLORS.BTN_GREEN : COLORS.PRIMARY_WHITE};
  border: ${(outlined) => (outlined ? `1px solid ${COLORS.BTN_GREEN}` : 0)};
  width: 138px;
  height: 30px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  text-align: center;
  outline: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  margin-left: 23px;
`;

const MuiButton = styled(Button)(({ disabled }) => ({
  marginLeft: "1.0em",
  width: "0.5em",
  height: "2.1em",
  border: disabled ? `1px solid #8294a5` : `1px solid ${COLORS.BTN_GREEN}`,
  backgroundColor: COLORS.PRIMARY_WHITE,
  minWidth: 32,
}));
