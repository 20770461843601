import React, { useState, useEffect, useRef, useCallback } from "react";
import ActionBar from "../common/ActionBar";
import {
  Container,
  ContainerGrid,
  Grid,
  Label,
  Center,
  SelectContainer,
  HyperLink,
  Loader,
  PieIcon,
} from "./styles";
import PartyService from "../../services/PartyService";
import CaseService from "../../services/CaseService";
import { CustomDatePicker, CustomInputField } from "../common/FormInputs";
import {
  Select,
  makeStyles,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import {
  StyledSelectFormControl,
  StyledDropdownIcon,
} from "../common/CustomSelect/styles";
import { StyledMenuItem } from "../common/FormInputs";
import CustomTable from "./component/MaterailCustomTable";
import moment from "moment";
import PieChart from "./component/PieChart";
import {
  getCaseLabelByStatus,
  daysCount,
  stagesTimeLine,
  daysFromNow,
  calculateDaysBetweenDates,
} from "./component/function";
import _ from "lodash";
import Timeline from "./TimeLine";
import { Autocomplete } from "@material-ui/lab";
import { navigate } from "@reach/router";
import CustomSelect, { components } from "react-select";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDrawer-paperAnchorRight": {
      zIndex: 2001,
      boxShadow: "1px 0px 20px 0px rgba(0,0,0,0.2)",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  menuPaper: {
    maxHeight: 200,
  },
  listbox: {
    color: "#293461",
    fontFamily: "openSans-SemiBold, sans-serif",
    fontSize: "16px",
    backgroundColor: "#ffffff",
  },
  noOptions: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: "#8294a5",
  },
}));

const customStyles = {
  control: (base) => ({
    ...base,
    width: "100%", // This ensures the select box takes the full width of the container
    "&:hover": { borderColor: "#e1e3ee" }, // border style on hover
    border: "1px solid #e1e3ee", // default border color
    boxShadow: "none", // no box-shadow
    cursor: "pointer",
  }),
  menu: (base) => ({
    ...base,
    cursor: "pointer",
  }),
  menuList: (base) => ({
    ...base,
    fontSize: "13px",
    cursor: "pointer",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#293461", // Custom text color
    fontWeight: "bold", // Bold text
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#293461", // Remove icon color
    borderRadius: "15px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px", // Change this to the desired font size
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#293461" : "#293461",
    backgroundColor: state.isSelected ? "lightblue" : provided.backgroundColor,
  }),
};

const MisDashboard = () => {
  const classes = useStyles();
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));
  let [currentData, setCurrentData] = useState([]);
  let filterRef = useRef({
    fromDate: "",
    toDate: "",
    fromCaseId: "",
    toCaseId: "",
    lotNumber: "",
    partyId: [],
    caseStage: "",
    lotID: "",
    detailedStage: "",
  });
  const [menuItems, setMenuItems] = useState([]);
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ state: false });
  const [subStages, setSubStages] = useState([]);
  const [lotMenuItems, setLotMenuItems] = useState([
    { label: "None", value: "" },
  ]);
  const [stages, setStages] = useState([]);
  const [state, setState] = useState({ state: false });
  const [page, setPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState([]);
  const [caseIds, setCaseIds] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [inputText, setInputText] = useState("");
  const [pieObject, setPieObject] = useState({
    Initiated: [],
    InProgress: [],
    Completed: [],
    "Resolution Closed": [],
  });

  /**
   * @description apply filters
   */
  const searchData = (rowData, filterRef) => {
    const parsedFromDate = moment(filterRef?.fromDate, "DD/MM/YYYY");
    const parsedToDate = moment(filterRef?.toDate, "DD/MM/YYYY");
    const itemDate = moment(rowData.createdDate, "DD/MM/YYYY");

    const from = parseInt(filterRef.fromCaseId, 10);
    const to = parseInt(filterRef.toCaseId, 10);

    return (
      _.lowerCase(rowData.caseStage).includes(
        _.lowerCase(filterRef?.caseStage),
      ) &&
      _.lowerCase(rowData.detailedStage).includes(
        _.lowerCase(filterRef?.detailedStage),
      ) &&
      (filterRef?.fromDate && filterRef?.toDate
        ? itemDate.isBetween(parsedFromDate, parsedToDate, undefined, "[]")
        : true) &&
      (filterRef.fromCaseId && filterRef.toCaseId
        ? rowData.caseId >= from && rowData.caseId <= to
        : true) &&
      (filterRef?.partyId?.length
        ? rowData.partyId.some((element) => filterRef.partyId.includes(element))
        : true) &&
      (filterRef?.lotID ? rowData.lotID === filterRef.lotID : true)
    );
  };

  const filterData = (inputText, filterRef, key) => {
    const filteredData = data?.length
      ? data.filter((rowData) => {
        return searchData(rowData, filterRef);
      })
      : "";
    inputText || filterRef
      ? setCurrentData(filteredData)
      : setCurrentData(data);
  };

  /**
   * @description get all the cases
   */

  useEffect(() => {
    async function getCases() {
      try {
        setLoading(true);
        const response = await CaseService.casesForMIS(
          `?partyIds=${filterRef.current.partyId}`,
        );
        setCaseIds(response?.caseIds);
        let storedCurrentData = response?.caseDet?.map((item) => ({
          title: item?.caseTitle,
          partyId: item?.partyIdArr,
          detailedStageLabel: getCaseLabelByStatus(item),
          days: daysCount(item),
          timeLineData: stagesTimeLine(item),
          createdDate: moment(
            item?.case_created_at,
            "YYYY-MM-DD HH:mm:ss",
          ).format("DD/MM/YYYY"),
          ...item,
        }));
        setCurrentData(storedCurrentData);
        setData(storedCurrentData);
        setStages([
          { label: "None", value: "" },
          ...response?.stages.map((item) => ({ label: item, value: item })),
        ]);
        setSubStages(response?.detailedStage);
        setPieObject(
          Object.fromEntries(
            Object.entries(response?.detailedStage).map(([key, array]) => [
              key,
              array
                .map((item) => item.value.toString())
                .filter((value) => value !== ""), // filter out empty values
            ]),
          ),
        );
        const uniqueArray = response?.caseDet.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.lotID === value.lotID),
        );
        const arrayOfObject = uniqueArray
          ?.filter(({ lotID }) => lotID?.length > 0)
          .map((item) => ({
            label: item?.lotID,
            value: item?.lotID,
            partyId: item?.partyIdArr,
          }));
        setLotMenuItems((prev) => [...prev, ...arrayOfObject]);
      } catch (err) {
        throw err;
      } finally {
        setLoading(false);
      }
    }
    if (filterRef.current.partyId.length > 0) {
      getCases();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRef.current.partyId]);

  /**
   * @description get parties
   */

  useEffect(() => {
    async function getParties(params) {
      try {
        const response = await PartyService.index(
          "?perPage=4000&status=active",
        );
        if (response?.data?.length) {
          setMenuItems(
            response.data.map(({ name, id, owner }) => ({
              label: `${name} (Party Id:${id})`,
              value: id,
              id: id,
              email: owner?.email,
            })),
          );
        }
      } catch (error) {
        throw error;
      }
    }
    getParties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description input props
   */

  const inputs = [
    {
      name: "fromCaseId",
      label: "From Case ID",
      value: filterRef.current.fromCaseId,
    },
    {
      name: "toCaseId",
      label: "To Case ID",
      value: filterRef.current.toCaseId,
    },
  ];

  const date_inputs = [
    {
      name: "fromDate",
      label: "From Date",
      value: fromDate,
      props: setFromDate,
    },
    {
      name: "toDate",
      label: "To Date",
      value: toDate,
      props: setToDate,
    },
  ];

  // /**
  //  * @description Filters pie chart
  //  */

  // const filteredData = () => {
  //   const outputObject = {};

  //   Object.keys(pieObject).forEach((group) => {
  //     outputObject[group] = [];
  //   });

  //   if (currentData?.length) {
  //     currentData.forEach((item) => {
  //       const value = item.detailedStage;

  //       // Find the key for the given status
  //       let keyFound = false;
  //       Object.keys(pieObject).forEach((group) => {
  //         if (
  //           pieObject[group].includes(value) &&
  //           (filterRef.current.lotID
  //             ? filterRef.current.lotID === item.lotID
  //             : true)
  //         ) {
  //           keyFound = true;
  //           outputObject[group].push(value);
  //         }
  //       });

  //       // If the status is not found in any existing key, ignore it
  //       if (!keyFound) {
  //         // Optionally, handle statuses that do not match any key
  //       }
  //     });
  //   }

  //   setModal({ state: true, pieData: outputObject });
  // };

  /**
   * @description Table columns and data
   */

  const columns = [
    {
      field: "lotID",
      title: "Lot ID",
      render: (rowData) => (
        <Center>{rowData.lotID ? rowData?.lotID : "-"}</Center>
      ),
      width: 130,
    },
    {
      field: "caseId",
      title: "Case ID",
      sorting: true,
      render: (rowData) => (
        <HyperLink
          onClick={() => {
            if (caseIds.includes(rowData?.caseId)) {
              navigate(
                `/dashboard/${rowData?.resolutionKind}/${rowData.caseId}`,
              );
            } else {
              enqueueSnackbar(
                "Case Manager does not have access to view the case",
                {
                  variant: "error",
                },
              );
            }
          }}
        >
          {rowData.caseId}
        </HyperLink>
      ),
    },
    {
      field: "contractNumber",
      title: "Contract Number",
      sorting: true,
      render: (rowData) => (
        <Center>{rowData.contractNumber ? rowData.contractNumber : "-"}</Center>
      ),
    },
    {
      field: "caseStage",
      title: "Case Stage",
      sorting: true,
      render: (rowData) => <Center>{rowData?.caseStage}</Center>,
    },
    {
      field: "detailedStageLabel",
      title: "Sub Stages",
      sorting: true,
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            rowData?.timeLineData?.length === 0
              ? {}
              : setState({
                state: true,
                timeLineData: calculateDaysBetweenDates(
                  rowData?.timeLineData,
                ),
                status: rowData?.detailedStage,
              })
          }
        >
          {rowData?.detailedStageLabel}
        </HyperLink>
      ),
    },
    {
      field: "days",
      title: "Total Days",
      sorting: true,
      render: (rowData) => (
        <Center style={{ marginLeft: "12px" }}>
          {rowData?.timeLineData[0]?.time
            ? daysFromNow(rowData?.timeLineData[0]?.time)
            : 0}
        </Center>
      ),
    },
  ];

  /**
   * @description party select
   */

  const handleSelect = useCallback((selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption.length === 0) {
      setCurrentData([]);
      setData([]);
      setStages([]);
      setSubStages([]);
      setLotMenuItems([]);
    } else {
      filterRef.current = {
        ...filterRef.current,
        caseStage: "",
        detailedStage: "",
        partyId: selectedOption.map((item) => item?.id),
      };
      // filterData(
      //   selectedOption.map((item) => item?.id),
      //   filterRef.current,
      //   "partyId",
      // );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Multivalue select tags
   */

  const MultiValue = React.memo((props) => {
    const { index, selectProps } = props;
    const maxToShow = selectProps.maxDisplayedOptions || 1; // Set default max to show

    if (index >= maxToShow) {
      if (index === maxToShow) {
        return (
          <components.MultiValue {...props}>
            <span>+{props.selectProps.value.length - maxToShow} more</span>
          </components.MultiValue>
        );
      }
      return null; // Do not render additional chips
    }

    return <components.MultiValue {...props} />;
  });

  /**
   * @description checkbox
   */

  // const CheckboxOption = React.memo((props) => {
  //   return (
  //     <components.Option {...props}>
  //       <StyledInput
  //         type="checkbox"
  //         checked={props.isSelected}
  //         onChange={() => null} // Prevents triggering onClick
  //         style={{ marginRight: 8 }}
  //       />
  //       {props.label}
  //     </components.Option>
  //   );
  // });

  /**
   * @description sorted menu items
   */

  const sortedOptions = menuItems.sort((a, b) => {
    const aSelected = selectedOption.some((option) => option.value === a.value);
    const bSelected = selectedOption.some((option) => option.value === b.value);

    if (aSelected && !bSelected) return -1;
    if (!aSelected && bSelected) return 1;
    return 0;
  });

  /**
   * @description Detail panel
   */

  /*
  const detailPanel = [
    {
      tooltip: "View detailed split up...",
      render: (rowData) => {
        return <StagesSplitUp data={rowData} />;
      },
    },
  ];
  */

  return (
    <>
      <ActionBar breadcrumbs={["MIS Dashboard"]} />
      <Container>
        <Grid>
          {date_inputs.map((input, index) => {
            return (
              <div className="mis-dashboard">
                <Label>{input.label}</Label>
                <CustomDatePicker
                  className={"input-white"}
                  inputVariant="outlined"
                  format={"DD/MM/YYYY"}
                  name={input.name}
                  clearable
                  value={
                    input?.value
                      ? moment(input.value).format("MM/DD/YYYY")
                      : null
                  }
                  maxDate={new Date()}
                  onChange={(e) => {
                    filterRef.current = {
                      ...filterRef.current,
                      [input.name]:
                        e !== null
                          ? moment(new Date(e)).format("DD/MM/YYYY")
                          : "",
                    };
                    if (e !== null) {
                      input.props(e.format("MM/DD/YYYY"));
                      filterData(
                        moment(new Date(e)).format("DD/MM/YYYY"),
                        filterRef.current,
                        input.name,
                      );
                    } else {
                      input.props("");
                      filterData("", filterRef.current, input.name);
                    }
                  }}
                />
              </div>
            );
          })}
          <div className="mis-dashboard">
            <Label>Case Stages</Label>
            <StyledSelectFormControl variant="outlined">
              <Select
                value={filterRef.current.caseStage}
                displayEmpty
                IconComponent={() => (
                  <StyledDropdownIcon
                    alt="select"
                    src={require("../../assets/images/dropdownIcon.svg")}
                  />
                )}
                onChange={(e) => {
                  filterRef.current = {
                    ...filterRef.current,
                    caseStage: e.target.value,
                    detailedStage: "",
                  };
                  filterData(e.target.value, filterRef.current, "caseStage");
                }}
              >
                {stages.map((item, index) => (
                  <StyledMenuItem key={index + 1} value={item.value}>
                    {item.label}
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledSelectFormControl>
          </div>
          <div className="mis-dashboard">
            <Label>Case SubStages</Label>
            <StyledSelectFormControl variant="outlined">
              <Select
                value={filterRef.current.detailedStage}
                displayEmpty
                IconComponent={() => (
                  <StyledDropdownIcon
                    alt="select"
                    src={require("../../assets/images/dropdownIcon.svg")}
                  />
                )}
                onChange={(e) => {
                  filterRef.current = {
                    ...filterRef.current,
                    detailedStage: e.target.value,
                  };
                  filterData(
                    e.target.value,
                    filterRef.current,
                    "detailedStage",
                  );
                }}
              >
                {subStages[filterRef?.current?.caseStage]?.length
                  ? subStages[filterRef?.current?.caseStage].map(
                    (item, index) => (
                      <StyledMenuItem key={index + 1} value={item.value}>
                        {item.label}
                      </StyledMenuItem>
                    ),
                  )
                  : [{ label: "None", value: "" }].map((item, index) => (
                    <StyledMenuItem key={index + 1} value={item.value}>
                      {item.label}
                    </StyledMenuItem>
                  ))}
              </Select>
            </StyledSelectFormControl>
          </div>
          <div className="mis-dashboard">
            <Label>Select party</Label>
            <StyledSelectFormControl
              variant="outlined"
              displayEmpty
              style={{ width: isBigScreen && 450 }}
            >
              <CustomSelect
                isMulti
                options={sortedOptions}
                value={selectedOption}
                inputValue={inputText}
                onInputChange={(newValue, actionMeta) => {
                  if (actionMeta.action === "input-change") {
                    setInputText(newValue);
                  }
                }}
                onChange={handleSelect}
                placeholder="Select party"
                isClearable={true}
                noOptionsMessage={() => "No Party found"}
                components={{
                  MultiValue,
                  // Option: CheckboxOption,
                }}
                styles={customStyles}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
              />
              {/* <MultiSelect
                items={menuItems}
                label="Select complex values"
                placeholder="Search party"
                selectAllLabel="Select all"
                noOptionsText="No Party found"
                {...{ filterRef, filterData }}
              /> */}
            </StyledSelectFormControl>
          </div>
        </Grid>
        <ContainerGrid>
          {inputs.map((input, index) => {
            return (
              <div className="mis-dashboard">
                <Label>{input.label}</Label>
                <CustomInputField
                  variant="outlined"
                  name={input.name}
                  value={input.value}
                  onChange={(e) => {
                    filterRef.current = {
                      ...filterRef.current,
                      [input.name]: e.target.value,
                    };
                    filterData(e.target.value, filterRef.current, input.name);
                  }}
                />
              </div>
            );
          })}
          <div className="mis-dashboard">
            <Label>Lot Id</Label>
            <StyledSelectFormControl variant="outlined">
              <Autocomplete
                id="size-small-outlined"
                size="small"
                classes={{
                  listbox: classes.listbox,
                  noOptions: classes.noOptions,
                }}
                onChange={(e, newValue) => {
                  if (newValue === null) {
                    filterRef.current = {
                      ...filterRef.current,
                      lotID: "",
                    };
                    filterData(newValue?.value, filterRef.current, "lotID");
                  } else {
                    filterRef.current = {
                      ...filterRef.current,
                      lotID: newValue?.value,
                    };
                    filterData(newValue?.value, filterRef.current, "lotID");
                  }
                }}
                selectOnFocus
                clearOnBlur
                options={lotMenuItems.filter((item) =>
                  item?.partyId?.some((element) =>
                    filterRef?.current?.partyId.includes(element),
                  ),
                )}
                getOptionLabel={(option) =>
                  option.label && option.label.toString()
                }
                renderOption={(option) => (
                  <SelectContainer>
                    <div className="agent_row">
                      <div className="agent_name">{option.label}</div>
                    </div>
                  </SelectContainer>
                )}
                noOptionsText={
                  <div variant="body2" color="textSecondary">
                    No Lot id found
                  </div>
                }
                renderInput={(params) => (
                  <CustomInputField {...params} variant="outlined" />
                )}
              />
            </StyledSelectFormControl>
          </div>
          <Tooltip title={"Pie chart"} placement="right">
            <PieIcon
              src={require("../../assets/images/pie-chart-icon.png")}
              onClick={() => setModal({ state: true })}
            />
          </Tooltip>
        </ContainerGrid>
        {!loading ? (
          <CustomTable
            hidePagination={currentData?.lastPage === 1}
            pageSize={10}
            pluralTitle={"Cases"}
            singularTitle={"Cases"}
            placeholderText={"Search"}
            noToolbar={true}
            {...{
              columns,
              modal,
              setModal,
              page,
              setPage,
            }}
            // detailPanel={detailPanel}
            loading={loading}
            data={currentData}
            state={currentData}
          />
        ) : (
          <Loader>
            <CircularProgress />
          </Loader>
        )}
        {modal?.state && (
          <PieChart
            {...{
              modal,
              setModal,
              currentData,
              filterRef,
              filterData,
              pieObject,
            }}
            metaData={data}
          />
        )}
        {state?.state && <Timeline {...{ state, setState }} />}
      </Container>
    </>
  );
};

export default MisDashboard;
