import React from "react";
import CreateTranscriber from "../CreateTranscriber";

const EditTranscriber = ({ id }) => {
  return (
    <div>
      <CreateTranscriber id={id} />
    </div>
  );
};

export default EditTranscriber;
