import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import { Tooltip, makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useAlert from "../../../hooks/useAlert";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import ViewOnlyUser from "../../pages/CaseDetails/common/ViewOnlyUser";
import AssignCaseNeutral from "../../pages/CaseDetails/common/AssignCaseNeutral";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiExpansionPanelSummary-content": {
      margin: "27px 0 27px 24px !important",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function TableWithAccordion({ caseDetails, id, setRefreshDetails, viewOnly }) {
  const classes = useStyles();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { hideAlert, showAlert } = useAlert();
  const [assignNeutral, setAssignNeutral] = useState(false);
  const [expanded, setExpanded] = useState(false);

  async function callRemoveViewUser(caseId, mediatorId) {
    setLoader({ state: true, message: "Removing mediator..." });
    try {
      const res = await CaseService.removePassiveUser(caseId, mediatorId);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      hideAlert();
      setLoader({ state: false });
      setRefreshDetails(true);
    }
  }

  const columns = [
    {
      field: "name",
      title: "Agent Name",
      sorting: false,
    },
    {
      field: "email",
      title: "Email",
      sorting: false,
      // render: (rowData) => (
      //   <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
      // ),
    },
    {
      field: "mobile",
      title: "Phone",
      sorting: false,
    },
    {
      field: "",
      title: "Remove",
      sorting: false,
      render: (rowData) => (
        <ButtonWrapper>
          <Tooltip title="Remove view only user" placement="top">
            <DeclineButton
              onClick={() =>
                showAlert({
                  primaryBtnText: "Cancel",
                  secondaryBtnText: "Ok",
                  clickSecondarybtn: () => {
                    callRemoveViewUser(id, rowData?.id);
                  },
                  clickPrimaryBtn: () => {
                    hideAlert();
                  },
                  isOpen: true,
                  onDialogClose: () => hideAlert(),
                  desc: `Are you sure you want to remove this user (view only access)`,
                  heading: `View Only User`,
                  descriptionTextStyle: {
                    textAlign: "left",
                    fontFamily: theme.fonts.primaryFontSemiBold,
                  },
                })
              }
            >
              {/* <img
                src={require("../../../assets/images/closeButton.svg")}
                alt="decline"
              /> */}
              <Delete
                style={{
                  fill: COLORS.LOGOUT_RED,
                  cursor: "pointer",
                }}
              />
            </DeclineButton>
          </Tooltip>
        </ButtonWrapper>
      ),
    },
  ];

  return (
    <Accordion className={classes.root} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={() => setExpanded((e) => !e)} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeading onClick={() => setExpanded((e) => !e)}>
          View Only User
        </AccordionHeading>
        {viewOnly !== true && (
          <ViewOnlyUser
            disabled={false}
            title={"View Only User"}
            titleTag={"Add View Only User"}
            addMediator={() => setAssignNeutral(true)}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          {caseDetails?.passiveNeutrals?.length ? (
            <CustomTable
              pluralTitle="Respondent"
              singularTitle="Respondent"
              {...{
                columns,
              }}
              data={caseDetails?.passiveNeutrals}
              noToolbar
              pageSize={1000}
              hidePagination={true}
            />
          ) : (
            <div className="no-result">No View users Found</div>
          )}
        </TableContainer>
      </AccordionDetails>
      <AssignCaseNeutral
        modal={assignNeutral}
        setModal={setAssignNeutral}
        type={
          caseDetails?.resolutionKind === "mediation"
            ? "Mediator"
            : "Arbitrator"
        }
        onAssignSuccess={() => {
          setRefreshDetails(true);
          setAssignNeutral(false);
        }}
        caseDetails={caseDetails}
      />
    </Accordion>
  );
}

export default TableWithAccordion;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (min-width: 780px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const DeclineButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 65%;
  @media (min-width) {
    justify-content: space-between;
  }
`;
