import React, { useRef } from "react";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import CaseService from "../../../../../src/services/CaseService";
import { useState } from "react";
import useLoader from "../../../../hooks/useLoader";
import {
  CustomCheckboxClosed,
  CheckBoxLabel,
  CheckboxContainer
} from "../../../common/FormInputs";

export default function RecordingControl({
  id,
  caseDetails,
  meetingDetail,
  setRefreshStatus
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [checked, setChecked] = useState(sessionStorage.getItem(id) === "true");
  const MTRef = useRef();

  const toggleDarkMode = () => {
    setChecked(!checked);
  };

  const enableOrDisableRecording = async ({ recordingStatus }) => {
    try {
      const status = recordingStatus === "no" ? "yes" : "no";
      setLoader({ state: true, message: "Updating Status..." });
      const response = await CaseService.RecordingControl(id, status);
      if (response) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success"
          });
          setRefreshStatus(true);
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <CheckboxContainer>
      {caseDetails?.status !== "draft" ? (
        <CheckboxContainer>
          <CustomCheckboxClosed
            id="secret"
            inputRef={MTRef}
            onChange={() =>
              toggleDarkMode(enableOrDisableRecording(meetingDetail))
            }
            checked={meetingDetail?.recordingStatus === "yes"}
          ></CustomCheckboxClosed>
          <CheckBoxLabel>Enable Recording</CheckBoxLabel>
        </CheckboxContainer>
      ) : (
        ""
      )}
    </CheckboxContainer>
  );
}
