import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../Buttons";
import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { FormLabel, TextareaAutosize } from "@material-ui/core";
import { CustomDatePicker } from "../FormInputs";
import moment from "moment";

const AlertDialog = ({
  heading = "Quit Case",
  desc = "Are you sure you want to Quit this case? All the negotiation progress will be lost if you quit case at this point",
  primaryBtnText = "Submit",
  secondaryBtnText = "Close",
  moveTranscriptBtnText,
  clickSecondarybtn = () => { },
  clickPrimaryBtn = () => { },
  movePrimaryBtn = () => { },
  isOpen = false,
  onDialogClose = () => { },
  status,
  moveTranscript
}) => {
  const [date, setDate] = useState(new Date());
  const [reasonText, setReasonText] = useState();
  const dateOfClose = moment(date).format("DD/MM/YYYY");
  useEffect(() => {
    if (!isOpen) {
      setReasonText("");
      setDate(new Date());
    }
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onDialogClose}
        disableRestoreFocus={true}
        disableScrollLock={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Heading style={{ margin: "30px auto 36px" }}>{heading}</Heading>
        <Description
          style={{
            margin: status ? "0 62px 40px" : "0 62px 80px",
            width: "400px",
            textAlign: "center"
          }}
        >
          {desc}
        </Description>
        {status ? (
          <>
            <Label>Please enter reason for closing the case</Label>
            <CustomTextArea
              rowsMin={3}
              rowsMax={3}
              maxLength={"auto"}
              value={reasonText}
              onChange={e => setReasonText(e.target.value)}
            />
            <Label>Date (Optional)</Label>
            <CustomDatePicker
              style={{
                maxWidth: "200px",
                marginLeft: "62px",
                marginRight: "65px",
                marginBottom: "25px"
              }}
              className={"input-white"}
              inputVariant="outlined"
              // minDate={new Date()}
              format={"DD/MM/YYYY"}
              placeholder={"Data"}
              value={date}
              onChange={setDate}
            />
          </>
        ) : (
          ""
        )}
        <ButtonWrapper
          style={{
            margin: "auto",
            width: "418px",
            display: "flex",
            justifyContent: "center",
            marginBottom: "52px"
          }}
        >
          {secondaryBtnText &&
            (status ? (
              <PrimaryOutlinedCTAButton
                style={{ height: "48px", width: "192px", marginRight: 20 }}
                onClick={() => clickSecondarybtn(reasonText, dateOfClose)}
              >
                {secondaryBtnText}
              </PrimaryOutlinedCTAButton>
            ) : (
              <PrimaryOutlinedCTAButton
                style={{ height: "48px", width: "192px", marginRight: 20 }}
                onClick={clickSecondarybtn}
              >
                {secondaryBtnText}
              </PrimaryOutlinedCTAButton>
            ))}
          {primaryBtnText && (
            <PrimaryCTAButton
              style={{ height: "48px", width: "192px" }}
              onClick={clickPrimaryBtn}
            >
              {primaryBtnText}
            </PrimaryCTAButton>
          )}
          {moveTranscript && (
            <PrimaryCTAButton
              style={{ height: "48px", width: "192px", marginLeft: 20 }}
              onClick={movePrimaryBtn}
            >
              {moveTranscriptBtnText}
            </PrimaryCTAButton>
          )}
        </ButtonWrapper>
      </Dialog>
    </div>
  );
};
export default AlertDialog;

const ButtonWrapper = styled.div`
  display: flex;
`;
const Heading = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 18px;
  font-weight: 800;
  color: ${COLORS.COLOR_DARK};
  margin: auto;
  margin-top: 23px;
  margin-bottom: 22px;
`;
const Description = styled.div`
  font-family: ${theme?.fonts?.primaryFontSemiBold};
`;

const CustomTextArea = styled(TextareaAutosize)`
  // width: 100%;
  // min-width: 100%;
  max-width: 400px;
  font-size: 16px;
  margin-left: 62px;
  margin-right: 65px;
  margin-bottom: 25px;
  color: ${COLORS.COLOR_DARK} !important;
  font-family: ${theme.fonts.primaryFontSemiBold};
  /* min-height: 89px; */
  border-radius: 6px;
  border: solid 0.5px #e1e3ee;
  background-color: #ffffff;
  outline: none;
  padding: 15px;
  :hover {
    border: solid 0.5px black;
  }
  ${({ error }) =>
    error &&
    css`
      border: solid 0.5px #f44336;
      :hover {
        border: solid 0.5px #f44336;
      }
    `}
`;

const Label = styled(FormLabel)`
  margin-left: 62px;
  margin-right: 65px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color:${COLORS.INPUT_LABEL} !important
  margin-top: 15px;
  line-height: 2.1;
`;
