import React, { useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import { useState } from "react";
import useLoader from "../../../hooks/useLoader";

import {
  CustomCheckbox,
  CheckBoxLabel,
  CheckboxContainer
} from "../../common/FormInputs";

export default function TranscriptionControl({
  id,
  status,
  noToolbar,
  isActionsDisabled = false,
  caseDetails,
  statusCheck,
  setStatusCheck,
  selection = false,
  ...props
}) {
  const [transcriptControl, settranscriptControl] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [checked, setChecked] = useState();
  const MTRef = useRef();

  const toggleDarkMode = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    getRecordingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getRecordingDetails() {
    try {
      const transcriptionControl = await CaseService.GetMeetingcases();
      settranscriptControl(transcriptionControl);
      return;
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  }

  async function enableLiveTranscription() {
    try {
      const transcriptionControl = await CaseService.enableLiveTranscriptionCommon();
      setStatusCheck(transcriptionControl);
      return;
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    }
  }

  const enableOrDisable = async ({ transcriptionStatus }) => {
    try {
      const status = transcriptionStatus === "yes" ? "no" : "yes";
      setLoader({ state: true, message: "Updating Status..." });
      const response = await CaseService.transcriptionControlCM(status);
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success"
        });
        enableLiveTranscription();
        getRecordingDetails();
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <CheckboxContainer>
      <CheckboxContainer>
        <CustomCheckbox
          id="secret"
          inputRef={MTRef}
          onChange={() => toggleDarkMode(enableOrDisable(transcriptControl))}
          checked={transcriptControl?.transcriptionStatus === "yes"}
        ></CustomCheckbox>
        <CheckBoxLabel> {"Enable Transcription"}</CheckBoxLabel>
      </CheckboxContainer>
    </CheckboxContainer>
  );
}
