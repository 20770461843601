import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const HeaderWrapper = styled.div`
  padding: 20px 54px;
  display: flex;
  background: ${COLORS.PRIMARY_WHITE};
  border-bottom: solid 0.5px ${COLORS.HEADER_BORDER};
`;

export const BigScreen = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  margin-left: 15px;
  flex: 1;
  @media (max-width: 600px) {
    margin-left: 5px;
  }
`;

export const SmallScreen = styled.div`
  @media (max-width: 600px) {
    display: flex;
  }
  @media (min-width: 600px) {
    display: none;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  /* background-color: ${COLORS.INPUT_BORDER}; */
  margin-right: 10px;
  user-select: none;
`;

export const ProfileName = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  margin-right: 32px;
  user-select: none;
  position: relative;
`;

export const AgentTag = styled.div`
  /* position: absolute; */
  left: 0;
  color: ${COLORS.PRIMARY_BLACK};
  opacity: 0.3;
  letter-spacing: 1px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const AgentPortalText = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  margin-right: 36px;
  cursor: pointer;
  user-select: none;
  width: 146px;
  height: 32px;
  border-radius: 3px;
  background-color: rgba(41, 52, 97, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoutIcon = styled.img`
  width: 12px;
  height: 12px;
  object-fit: contain;
  /* margin-left: 50px; */
  margin-right: 6px;
`;

export const LogoutText = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.LOGOUT_RED};
  margin-right: 36px;
  cursor: pointer;
  user-select: none;
`;

export const LoginText = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  color: ${({ filled }) => (filled ? COLORS.PRIMARY_WHITE : COLORS.COLOR_DARK)};
  border: 1px solid ${COLORS.COLOR_DARK};
  border-radius: 4px;
  padding: 5px 12px;
  margin-right: 32px;
  cursor: pointer;
  user-select: none;
  ${({ filled }) =>
    filled &&
    css`
      background-color: ${COLORS.COLOR_DARK};
    `}
`;
