import React, { useEffect, useRef, useState } from "react";
import ActionBar from "../../common/ActionBar";
import { Header } from "../Header/Header";
import labels from "../../../helpers/labels.json";
import { Container, HyperLink, TableWrapper } from "./styles";
import _ from "lodash";
import { navigate } from "@reach/router";
import CaseService from "../../../services/CaseService";
import { Bold } from "../../pages/ProfileDetails/styles";
import { ActionButton } from "../../common/Buttons";
import moment from "moment";
import CustomTable from "../../common/CustomTable/CustomTable";
import queryString from "query-string";
import {
  getErrorMessage,
  getFileName,
  parseTimeStamp,
  numberFormat,
} from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import PaymentModal from "../../pages/CaseDetails/common/PaymentModal";

async function getOrders(query = "") {
  try {
    const response = await CaseService.getOrders(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const ProformaInvoiceListing = () => {
  const [state, setState] = useState({});
  const [openPaymentBox, setOpenPaymentBox] = useState(false);
  const placeholderText = "Search Invoice";
  const MTRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [paymentId, setPaymentId] = useState("");
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [paymentsData, setPaymentsData] = useState();

  useEffect(() => {
    if (triggerRefresh) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
      setTriggerRefresh(false);
    }
  }, [triggerRefresh]);

  const breadcrumbs = [
    _.startCase(labels.my_tasks),
    _.startCase(labels.proforma_invoice),
  ];

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case labels.my_tasks:
        navigate("/dashboard");
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      field: "id",
      title: labels["table.order_id"],
      sorting: true,
      tooltip: "Payment order identifier",
      render: (rowData) => (
        <HyperLink
          style={{
            textDecoration: "none",
            cursor: "inherit",
            width: 90,
          }}
        >
          {rowData?.id}
        </HyperLink>
      ),
      cellStyle: {
        paddingLeft: 32,
      },
    },
    {
      field: "id",
      title: labels["table.profoma_invoice_id"],
      sorting: true,
      render: (rowData) => (
        <HyperLink
          style={{
            textDecoration: "none",
            cursor: "inherit",
            width: 90,
          }}
        >
          {rowData?.id}
        </HyperLink>
      ),
    },
    {
      field: "parties.name",
      title: labels["table.party_name"],
      sorting: false,
      render: (rowData) => rowData?.party?.name,
    },
    {
      field: "amount",
      title: labels["table.amount"],
      sorting: false,
      render: (rowData) => (
        <Bold>
          {numberFormat(
            parseFloat(rowData?.amount?.toFixed(2)),
            rowData?.cartItems[0].fee.unit
          )}
        </Bold>
      ),
    },
    {
      field: "created_at",
      title: labels["table.date"],
      tooltip: "Date of transaction",
      sorting: true,
      render: (rowData) => (
        <>{moment(parseTimeStamp(rowData?.created_at)).format("DD/MM/YYYY")}</>
      ),
    },
    {
      field: "invoiceUrl",
      title: labels["table.proforma_invoice"],
      tooltip: "Proforma invoice of the transaction ",
      sorting: false,

      render: (rowData) =>
        rowData?.proformaInvoiceUrl ? (
          <HyperLink onClick={() => window.open(rowData?.proformaInvoiceUrl)}>
            {rowData?.proformaInvoiceUrl
              ? getFileName(rowData?.proformaInvoiceUrl)?.split(".pdf")[0]
              : "Download"}
          </HyperLink>
        ) : (
          <div style={{ marginLeft: 35 }}>-</div>
        ),
    },
    {
      field: "action",
      title: labels["table.action"],
      sorting: false,
      render: (rowData) => (
        <ActionButton
          style={{ margin: 0, height: 30, width: 90, fontSize: 10 }}
          key={rowData?.id}
          onClick={() => {
            setPaymentId(rowData?.id);
            setOpenPaymentBox(true);
            setPaymentsData(rowData);
          }}
        >
          {labels["mark_as_paid"]}
        </ActionButton>
      ),
      headerStyle: {
        width: "150px !important",
      },
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        filter: "status",
      };
      if (query.search) {
        params.query = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      const filterSting = queryString.stringify(
        { filterValues: ["proformaGenerated"] },
        { arrayFormat: "index" }
      );
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}&${filterSting}`;
      }
      getOrders(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <Header
      showSidebar
      selectedItem={labels.my_tasks}
      selectedSubItem={labels.proforma_invoice}
    >
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container className="PendingActions">
        <TableWrapper>
          <CustomTable
            hidePagination={state.lastPage <= 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle="Proforma Invoices"
            singularTitle=""
            {...{
              columns,
              data,
              placeholderText,
              MTRef,
            }}
            //noToolbar
            hideFilterBar
            state={state}
          />
        </TableWrapper>
      </Container>
      <PaymentModal
        modal={openPaymentBox}
        setModal={(bool) => {
          setOpenPaymentBox(bool);
          setTriggerRefresh(true);
        }}
        payments={paymentsData}
        id={paymentId}
      />
    </Header>
  );
};

export default ProformaInvoiceListing;
