import React from "react";
import { Modal } from "@material-ui/core";
import { Formik } from "formik";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import FormField from "../FormField/FormField";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../Buttons";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { GenerateInvoiceValidationSchema } from "../../../helpers/validationSchema";
import CaseService from "../../../services/CaseService";
import { CustomRadio } from "../FormInputs";

const GenerateInvoiceModal = ({ invoiceModal, setInvoiceModal, caseId }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const inputs = [
    {
      name: "feeType",
      type: "text",
      label: "Fees Type",
      required: true,
    },
    {
      name: "amount",
      type: "numberFormat",
      label: "Amount",
      required: true,
    },
  ];

  const claimant_percentage = [
    {
      name: "claimantPercentage",
      type: "number",
      label: "Claimant Percentage",
      required: true,
    },
  ];

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "genrate invoice..." });
      const response = await CaseService.generateInvoiceCase(
        invoiceModal?.caseId,
        {
          ...values,
          amount: Number(values.amount?.toString()?.split(",")?.join("")),
        }
      );
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setInvoiceModal({ state: false });
    }
  }

  return (
    <Modal
      open={invoiceModal?.state}
      onClose={() => setInvoiceModal({ state: false })}
      disableRestoreFocus
    >
      <DrawerContainer>
        <HeadingContainer>
          <Heading>Generate Invoice</Heading>
          <CloseModal
            src={require("../../../assets/images/closeModal.svg")}
            onClick={() => setInvoiceModal({ state: false })}
          />
        </HeadingContainer>
        <FormContainer>
          <Formik
            initialValues={{
              feeType: "",
              amount: 0,
              claimantPercentage: 0 || "",
              incluseGst: false,
            }}
            enableReinitialize
            validateOnBlur
            validationSchema={GenerateInvoiceValidationSchema}
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <StyledFormElementsWrapper
                  className="disabledArrows"
                  style={{
                    marginBottom: 3,
                  }}
                >
                  {inputs.map((input, index) => (
                    <FormField
                      key={index}
                      {...{
                        input,
                        values,
                        handleBlur,
                        handleChange,
                        touched,
                        errors,
                      }}
                    />
                  ))}
                  <InputFiled>
                    {claimant_percentage.map((input, index) => (
                      <FormField
                        key={index}
                        {...{
                          input,
                          values,
                          handleBlur,
                          handleChange,
                          touched,
                          errors,
                        }}
                      />
                    ))}
                  </InputFiled>
                  <StyledFormElementsWrapper1
                    style={{ marginBottom: 15, marginTop: 15 }}
                  >
                    <CustomRadio
                      radioInputs={[
                        { label: "Include GST", value: "incluseGst" },
                      ]}
                      name={"incluseGst"}
                      handleChange={(e) => handleChange(e, "incluseGst")}
                      {...{ values, setFieldValue }}
                    />
                  </StyledFormElementsWrapper1>
                </StyledFormElementsWrapper>
                <Flex>
                  <ButtonContainer>
                    <PrimaryOutlinedCTAButton
                      style={{
                        width: "48%",
                        marginRight: "10px",
                        padding: "12px 12px",
                      }}
                      onClick={() => setInvoiceModal({ state: false })}
                    >
                      Close
                    </PrimaryOutlinedCTAButton>
                    <PrimaryCTAButton
                      style={{ width: "48%", padding: "12px 12px" }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </PrimaryCTAButton>
                  </ButtonContainer>
                </Flex>
              </>
            )}
          </Formik>
        </FormContainer>
      </DrawerContainer>
    </Modal>
  );
};

export default GenerateInvoiceModal;

const DrawerContainer = styled.div`
  max-width: 95%;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 60px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.div`
  padding-top: 8px;
  padding-left: 54px;
  padding-right: 54px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 318px;
  margin-top: 22px;
`;

const StyledFormElementsWrapper = styled.div`
  margin-bottom: 10px;
  max-width: 436px;
  @media ${theme?.breakpoints?.sm_down} {
    & .template {
      margin-top: 5px;
    }
  }
`;

const StyledFormElementsWrapper1 = styled.div`
  margin-bottom: 28px;
  & .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
  }
`;

const InputFiled = styled.div`
  margin-top: 8px;
`;
