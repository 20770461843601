import React, { Fragment, useEffect, useState } from "react";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import labels from "../../../helpers/labels.json";
import TranscriberService from "../../../services/TranscriberService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import styled, { css } from "styled-components";
import {
  PrimaryOutlinedCTAButton,
  SecondaryOutlinedCTAButton,
} from "../../common/Buttons";
import TranscriberCasesTable from "../../internal/TranscriberCasesTable";
import { navigate } from "@reach/router";
import AlertDialog from "../../common/Alert";

const ViewTranscriber = ({ id }) => {
  const [state, setState] = useState();
  const { setLoader } = useLoader(); // This Hook is to display the loader
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState();

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "transcribers":
        navigate("/dashboard/transcriber");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (id) {
      getTranscriberDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function getTranscriberDetails(id) {
    try {
      setLoader({ state: true, message: "Fetching case details..." });
      const response = await TranscriberService.getTranscriberDetails(id);
      if (response) {
        setState(response);
        const breadcrumbs = [
          _.startCase(labels.transcriber),
          _.startCase(response?.transcriberDetails.name),
        ];
        setBreadcrumbs(breadcrumbs);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const enableOrDisable = async ({ disabled }) => {
    try {
      const status = disabled ? "enable" : "disable";
      setLoader({ state: true, message: "Updating..." });
      // console.log(disabled, "disabled");
      const response = await TranscriberService.deleteTranscriber(id, status);
      if (response) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          getTranscriberDetails(id);
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  return (
    <Fragment>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
      />
      <Container>
        <Row
          style={{
            width: 900,
            justifyContent: "space-between",
          }}
        >
          <Row
            style={{
              width: 500,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <BasicDetailsLabel>{labels["profile_name"]}</BasicDetailsLabel>
              <BasicDetailsValue>
                {state?.transcriberDetails?.name}
              </BasicDetailsValue>
            </div>
            <div>
              <BasicDetailsLabel>{labels["profile_email"]}</BasicDetailsLabel>
              <BasicDetailsValue>
                {state?.transcriberDetails?.email}
              </BasicDetailsValue>
            </div>
            <div>
              <BasicDetailsLabel>{labels["profile_mobile"]}</BasicDetailsLabel>
              <BasicDetailsValue>
                {state?.transcriberDetails?.mobile
                  ? state?.transcriberDetails?.mobile
                  : "-"}
              </BasicDetailsValue>
            </div>
          </Row>
          <Row>
            <PrimaryOutlinedCTAButton
              className="mr15"
              onClick={() => navigate(`/dashboard/transcriber/edit/${id}`)}
            >
              Edit
            </PrimaryOutlinedCTAButton>
            {state?.transcriberDetails?.disabled ? (
              <PrimaryOutlinedCTAButton
                outlined
                className="mr15"
                onClick={() => setOpen(true)}
              >
                Activate
              </PrimaryOutlinedCTAButton>
            ) : (
              <SecondaryOutlinedCTAButton
                className="ml15"
                onClick={() => setOpen(true)}
              >
                Deactivate
              </SecondaryOutlinedCTAButton>
            )}
          </Row>
        </Row>
      </Container>
      <div>
        <TranscriberCasesTable {...{ id }} />
      </div>

      <div>
        <AlertDialog
          isOpen={open}
          clickSecondarybtn={() => setOpen(false)}
          onDialogClose={() => setOpen(false)}
          primaryBtnText={"Ok"}
          secondaryBtnText={"Cancel"}
          desc={`Are you sure? You want to ${
            state?.transcriberDetails?.disabled ? "enable" : "disable"
          } this Transcriber`}
          heading={`${
            state?.transcriberDetails?.disabled ? "Enable" : "Disable"
          } Transcriber`}
          clickPrimaryBtn={() => enableOrDisable(state?.transcriberDetails)}
          descriptionTextStyle={{
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold,
          }}
        />
      </div>
    </Fragment>
  );
};

export default ViewTranscriber;

const BasicDetailsLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;

const BasicDetailsValue = styled.div`
  word-break: break-all;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  ${({ transform }) =>
    transform &&
    css`
      text-transform: ${transform};
    `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  & button {
    height: 42px !important;
  }
`;

const Container = styled.div`
  padding: 34px 43px;
  display: flex;
  flex-direction: column;
`;
