import React, { useState } from "react";
import _ from "lodash";
import { navigate } from "@reach/router";
import ActionBar from "../../common/ActionBar";
import CustomTable from "../../common/CustomTable/CustomTable";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import queryString from "query-string";
import PartyService from "../../../services/PartyService";
import moment from "moment";
import { useRef } from "react";
import labels from "../../../helpers/labels.json";
import { parseTimeStamp } from "../../../helpers/functions";

async function getParties(query = "") {
  try {
    const response = await PartyService.index(query);
    return { ...response };
  } catch (error) {
    return error;
  }
}

const Index = () => {
  const breadcrumbs = [
    _.startCase(labels.my_tasks),
    _.startCase(labels.verify),
  ];
  const [state, setState] = useState({});
  const placeholderText = "Search Parties";
  const MTRef = useRef();

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case labels.my_tasks:
        navigate("/dashboard");
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      field: "name",
      title: labels.party_name,
      sorting: false,
      render: (rowData) => (
        <HyperLink
          onClick={() => navigate(`/dashboard/verify-parties/${rowData.id}`)}
        >
          {rowData.name}
        </HyperLink>
      ),
      headerStyle: { paddingLeft: 32 },
      cellStyle: { paddingLeft: 32 },
    },
    {
      field: "kind",
      title: labels.party_type,
      sorting: false,
      render: (rowData) => _.capitalize(rowData.kind),
    },
    {
      field: "owner.name",
      title: labels.created_by,
      sorting: false,
    },
    {
      field: "documentKind",
      title: labels.id_type,
      sorting: false,
      render: (rowData) => (
        <>
          {rowData.documentKind === "voter"
            ? "VOTER ID"
            : rowData.documentKind === "aadhar"
            ? "AADHAAR"
            : _.upperCase(rowData.documentKind)}
        </>
      ),
    },
    {
      field: "created_at",
      title: labels.created_on,
      render: (rowData) =>
        moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY"),
    },
    {
      field: "created_at",
      title: labels.target_date,
      render: (rowData) =>
        moment(
          rowData.submittedOn
            ? rowData.submittedOn
            : parseTimeStamp(rowData.created_at)
        )
          .add(7, "days")
          .format("DD/MM/YYYY"),
    },
  ];

  // async function getParties(query = "") {
  //   try {
  //     const response = await PartyService.index(query);
  //     return { ...response };
  //   } catch (error) {
  //     return error;
  //   }
  // }

  // const Index = () => {
  //   const breadcrumbs = [
  //     _.startCase(labels.my_tasks),
  //     _.startCase(labels.verify),
  //   ];
  //   const [state, setState] = useState({});
  //   const placeholderText = "Search Parties";
  //   const MTRef = useRef();

  //   const onBreadcrumbClick = (selected) => {
  //     switch (_.snakeCase(selected)) {
  //       case labels.my_tasks:
  //         navigate("/dashboard");
  //         break;
  //       default:
  //         break;
  //     }
  //   };

  const data = (query) =>
    new Promise((resolve, reject) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        status: "underReview",
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      if (query.search) {
        params.search = query.search;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getParties(stringParams)
        .then((result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  return (
    <>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container className="CreateCase">
        <TableWrapper>
          <CustomTable
            pluralTitle="Parties"
            singularTitle=""
            {...{
              columns,
              data,
              placeholderText,
              state,
              MTRef,
            }}
            pageSize={10}
            state={state}
            hideFilterBar
            //noToolbar
          />
        </TableWrapper>
      </Container>
    </>
  );
};
export default Index;

export const Container = styled.div`
  padding: 34px 43px;
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div``;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
`;
export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;
