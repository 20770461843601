import API from "../api/manager";
import config from "../api/config";

const addBundle = (data, id) => {
  return API({
    method: "POST",
    url: `${config.urls.bundle.customBundle}/${id}`,
    data,
  });
};

const getBundleName = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.bundle.customBundle}/${id}`,
  });
};

const customBundleDelete = (id) => {
  return API({
    method: "DELETE",
    url: `${config.urls.bundle.customBundle}/${id}`,
    data: {},
  });
};

const editBundleName = (data, caseId, bundleId) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.customBundle + `/${caseId}/${bundleId}`,
    data,
  });
};

const addPageNumberBundle = (id) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.addPageNumberBundle + `/${id}`,
  });
};

const generateTableofContentsBundle = (id) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.generateTableofContentsBundle + `/${id}`,
  });
};

const getDocumentList = (caseId, id) => {
  return API({
    method: "GET",
    url: `${config.urls.bundle.bundleDocList}/${caseId}/${id}`,
  });
};

const hearingVisibilityType = (data, caseId, id) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.customBundle + `/${caseId}/${id}`,
    data,
  });
};

const updateBundle = (data, caseId, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.bundle.customBundle}/${caseId}/${id}`,
    data,
  });
};

const addAnnotationToBundles = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.addAnnotationToBundles + `/${id}`,
    data,
  });
};

const deletePagesFromBundle = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.bundle.deletePagesFromBundle}/${id}`,
    data,
  });
};

const addSectionOrPagesToBundle = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.bundle.addSectionOrPagesToBundle + `/${id}`,
    data,
  });
};

const addPagesToBundle = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.bundle.addPagesToBundle}/${id}/page`,
    data,
  });
};

export default {
  addBundle,
  getBundleName,
  customBundleDelete,
  editBundleName,
  addPageNumberBundle,
  generateTableofContentsBundle,
  getDocumentList,
  hearingVisibilityType,
  updateBundle,
  addAnnotationToBundles,
  deletePagesFromBundle,
  addSectionOrPagesToBundle,
  addPagesToBundle,
};
