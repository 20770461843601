import React, { useState, useMemo, useEffect, useCallback } from "react";
import ActionBar from "./ActionBar";
import styled from "styled-components";
import { useLocation, navigate } from "@reach/router";
import _ from "lodash";
import useDictation from "../../../hooks/useDictation";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { makeStyles, Select } from "@material-ui/core";
import { TextType } from "../../../helpers/constants";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { createEditor, Transforms, Range, Editor } from "slate";
import { withHistory } from "slate-history";
import UpdateSpeaker from "../../transcripts/SlateEditor/UpdateSpeaker";
import { StyledMenuItem } from "../../common/FormInputs";
// import { PauseOutlined, PlayArrow } from "@material-ui/icons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import AlertDialog from "../../common/Alert";
import AssignTranscriber from "../../pages/AssignLiveTranscribers";
import "./Audio.css";
import AWSService from "../../../services/AWSService";
import moment from "moment";

const CrossExamination = (id) => {
  const history = useLocation();
  // const resolutionKind = history.pathname.replace("/cross-examination", "");
  const previousPath = history?.pathname.replace("/cross-examination", "");
  const breadcrumbs = ["Cases", _.startCase(id?.type), "Cross Examination"];
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const [value, setValue] = useState([]);
  const [state, setState] = useState({ state: false });
  //   const videoRef = useRef(null);
  const {
    comment,
    setComment,
    setTranscribedData,
    transcriptionReceived,
    setTranscriptionReceived,
    disabled,
    setDisabled,
  } = useDictation();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [referesh, setReferesh] = useState(true);
  const [render, setRender] = useState(false);
  const caseId = id?.id;
  const [data, setData] = useState([]);
  const [meetingId, setMeetingId] = useState();
  const [transcriptData, setTranscriptData] = useState([]);
  const [dialogData, setDialogData] = useState({});
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({ state: false });
  const update = true;
  const [cursor, setCursor] = useState({});
  const [transcript, setTranscript] = useState();
  const [mergedBlobURL, setMergedBlobURL] = useState();
  const [filterSpeaker, setFilterSpeaker] = useState([]);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case id?.type:
        navigate(`${previousPath}`);
        break;
      case "cases":
        navigate(`/dashboard/${id?.type}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (comment) {
      const lastNode = data[data.length - 1];
      const updatedComment = { ...comment };
      updatedComment["textType"] =
        lastNode?.textType === "Question" ? "Answer" : "Question";
      // updatedComment["color"] = "rgba(0, 131, 140, 0.58)";
      updatedComment["color"] = "#5cebdf";
      const updatedArray = [...data, updatedComment];
      updatedArray[updatedArray.length - 1] = updatedComment;
      setTranscript(updatedComment);
      if (updatedArray) {
        const response = splitContent(updatedArray);
        setValue(response);
        editor.children = response;
        editor.selection = null;
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  useEffect(() => {
    const getTranscriptionData = async (caseId) => {
      try {
        setLoader({ state: true, message: "Fetching transcript..." });
        const transcriptionControl = await CaseService.GetMeetingAccesscases(
          caseId,
        );
        const response = await CaseService.getTranscriptionData(caseId);
        if (response) {
          const lastObject =
            response?.transcriptionData[response?.transcriptionData.length - 1];

          const newObject = { ...lastObject, text: "End Of Transcript" };
          if (
            response?.transcriptionData.length > 0 &&
            transcriptionControl?.transcriptionRunningStatus !== true
          ) {
            setData([
              ...response?.transcriptionData?.map((el) => {
                return {
                  speaker: el.speaker ? el?.speaker : "test",
                  text: el.text,
                  textType: el.textType ? el.textType : "Question",
                  mediaId: el?.mediaId,
                  questionNo: el?.questionNo,
                  audioUrl: el?.audioUrl,
                };
              }),
              newObject,
            ]);
          } else {
            setData(
              response?.transcriptionData?.map((el) => {
                return {
                  speaker: el.speaker ? el?.speaker : "test",
                  text: el.text,
                  textType: el.textType ? el.textType : "Question",
                  mediaId: el?.mediaId,
                  questionNo: el?.questionNo,
                  audioUrl: el?.audioUrl,
                };
              }),
            );
          }

          setMeetingId(response.meetingId);
          setRender(true);
        } else {
          setValue([]);
          setData([]);
          editor.children = [{ type: "paragraph", children: [{ text: "" }] }];
          Transforms.deselect(editor);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        setComment();
        setTranscribedData([]);
        setMergedBlobURL();
        // setDisabled(false);
      }
    };
    if (referesh) {
      getTranscriptionData(caseId);
      setReferesh(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referesh]);

  useEffect(() => {
    if (data) {
      const response = splitContent(data);
      setValue(response);
      setRender(false);
    }
    if (value) {
      editor.history.undos = [];
      editor.children = value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  useEffect(() => {
    if (value) {
      setTranscriptData(
        value.map((el) => {
          return {
            id: el?.id,
            text: el?.children[0].text,
            speaker: el?.speaker,
            mediaId: el?.mediaId,
            audioUrl: el?.audioUrl,
            textType: el?.textType ? el?.textType : "Question",
            questionNo: el?.questionNo,
          };
        }),
      );
      if (true) {
        let result = {};
        value.map((item) => {
          return (
            item?.oldSpeaker !== item?.speaker &&
            (result[item?.oldSpeaker] = item?.speaker)
          );
        });
        setFilterSpeaker(result);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    async function postTranscriptionData(caseId) {
      try {
        setLoader({ state: true, message: "Post transcript..." });
        const blobRes = await fetch(mergedBlobURL);
        const blob = await blobRes.blob();
        const dateOfFile = moment(new Date()).format("DD-MM-YYYY_h-mm-ss");
        const file = new File(
          [blob],
          `${caseId}_crossExamAudio_${data.length}_${dateOfFile}.mp3`,
          { type: "audio/mp3" },
        );
        const s3Response = await AWSService.getS3URL({
          key: `${caseId}_crossExamAudio_${data.length}_${dateOfFile}.mp3`,
        });
        const audioUrl = await AWSService.uploadToS3(s3Response.url, file, {
          type: "audio/mp3",
        });
        const payload = {
          transcriptionData: { ...transcript, audioUrl },
          caseId: caseId,
        };

        const response = await CaseService.postTranscriptionData(payload);
        if (response) {
          setReferesh(true);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        setTranscribedData([]);
        delete transcriptionReceived[caseId];
        setTranscriptionReceived(transcriptionReceived);
        setDisabled(false);
      }
    }

    if (transcriptionReceived[caseId]) {
      postTranscriptionData(caseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptionReceived[caseId]]);

  let newArray = editor.children.map((item) => item.speaker);

  let speakersData = [...new Set(newArray)];

  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: COLORS.INPUT_GRAY,
    },
    selectInput: {
      color: "#293461", // Change the font color here
      fontFamily: theme.fonts.primaryFontSemiBold, // Change the font family here
    },
  }));

  const generateText = (words) => {
    return words.text;
  };

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const handleChange = (e, element) => {
    const pathToCurrentNode = ReactEditor.findPath(editor, element);
    const value = e.target.value;
    if (value) {
      Transforms.setNodes(
        editor,
        { type: "timedText", textType: value },
        { at: pathToCurrentNode },
      );
    }
  };

  const splitContent = (paragraphs) => {
    return paragraphs.map((paragraph, index) => ({
      speaker: paragraph.speaker,
      id: paragraph.id,
      audioUrl: paragraph.audioUrl,
      textType: paragraph.textType,
      type: "timedText",
      currentTime: paragraph.currentTime,
      duration: paragraph.duration,
      mediaId: paragraph.mediaId,
      oldSpeaker: paragraph?.oldSpeaker
        ? paragraph?.oldSpeaker
        : paragraph?.speaker,
      questionNo: paragraph.questionNo,
      children: [
        {
          text: generateText(paragraph),
          color: paragraph.color ? paragraph.color : "",
        },
      ],
    }));
  };

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    return (
      <span
        className={"timecode text"}
        style={{
          borderRadius: "5px",
          backgroundColor: leaf["color"],
        }}
        {...attributes}
      >
        {children}
      </span>
    );
  }, []);

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "timedText":
        return <TimedTextElement {...props} />;
      default:
        return <DefaultElement {...{ props }} />;
    }
  }, []);

  const DefaultElement = (props) => {
    return <p {...props.attributes}>{props.children}</p>;
  };

  const TimedTextElement = (props) => {
    return (
      <React.Fragment>
        <DrawerContainer contentEditable={false}>
          <Select
            style={{
              height: "32px",
              width: "11%",
              borderRadius: "5px",
              color: "#293461",
              fontFamily: theme.fonts.primaryFontSemiBold,
            }}
            disableUnderline={true}
            onChange={(e) => handleChange(e, props.element)}
            defaultValue={props.element.textType}
            renderValue={
              props.element.textType !== ""
                ? undefined
                : () => <Placeholder>{"Select Type"}</Placeholder>
            }
          >
            {TextType.map((element, index) => (
              <StyledMenuItem
                key={index}
                value={element.value}
                disabled={element.disabled}
              >
                {element.label}
              </StyledMenuItem>
            ))}
          </Select>
          <ValueSpeaker
            onClick={() =>
              editor.children[editor.children.length - 1].children[0].text ===
              props.element.children[0].text
                ? ""
                : setState({
                    state: true,
                    element: props.element,
                  })
            }
          >
            {props.element.speaker}
          </ValueSpeaker>
          <ValueConfidence>{props.element.questionNo}</ValueConfidence>
          <AudioBar>
            {props.element.audioUrl ? (
              <div className="audio-player-container">
                <audio className="audio-player" controls>
                  <source src={props.element.audioUrl} />
                </audio>
              </div>
            ) : null}
            {/* <audio
                id="audioUrlId"
                ref={videoRef}
                src={props.element.audioUrl}
                onLoadedMetadata={(event) =>
                  handleMetaDataTimeUpdate(event, props.element)
                }
                // controls
                // playsInline
                // style={{
                //   display: "inline",
                // }}
              />
              <ButtonPlayer onClick={() => togglePlay(props.element)}>
                {props.element.media ? <PauseOutlined /> : <PlayArrow />}
              </ButtonPlayer>
              <Bar
                type="range"
                min="0"
                style={{
                  accentColor: COLORS.BTN_GREEN,
                  // color: "rgba(80, 151, 255, 0.38)",
                }}
                max={props.element.duration}
                value={props.element.currentTime}
              /> */}
          </AudioBar>
        </DrawerContainer>
        <CustomTextAreaOtter
          style={{
            color: COLORS.COLOR_DARK,
          }}
        >
          {props.children}
        </CustomTextAreaOtter>
      </React.Fragment>
    );
  };

  const updateXExamTranscript = async (meetingId) => {
    try {
      setLoader({ state: true, message: "Update transcript..." });
      let storedData = transcriptData;
      storedData.pop();
      const payload = {
        editedTranscriptionData: storedData,
        speakerNameObj: filterSpeaker,
      };
      if (storedData?.length) {
        const response = await CaseService.updateXExamTranscript(
          payload,
          meetingId,
        );
        if (response) {
          setReferesh(true);
        }
      } else {
        setReferesh(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const updateXExamTranscriptMove = async (meetingId) => {
    try {
      setLoader({ state: true, message: "Update transcript..." });
      let storedData = transcriptData;
      storedData.pop();
      const payload = {
        editedTranscriptionData: storedData,
      };
      const response = await CaseService.updateXExamTranscript(
        payload,
        meetingId,
      );
      if (response) {
        moveTranscriptToRecordings(meetingId);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  const moveTranscriptToRecordings = async (meetingId) => {
    try {
      setLoader({ state: true, message: "Move transcript..." });
      const response = await CaseService.moveTranscriptToRecordings(meetingId);
      if (response) {
        setTimeout(() => {
          setReferesh(true);
        }, 2000);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const moveTranscriptionAlert = (meetingId) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Save and Move",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => setOpen(false),
      clickPrimaryBtn: () => updateXExamTranscriptMove(meetingId),
      onDialogClose: () => setOpen(false),
      desc: (
        <>
          Are you sure you want to move the transcript to case documents? <br />
          <br />
          <b style={{ fontFamily: `${theme.fonts.primaryFontExtraBold}` }}>
            Note :
          </b>{" "}
          <b
            style={{
              fontFamily: theme.fonts.primaryFontExtraBold,
              color: COLORS.LOGOUT_RED,
            }}
          >
            {
              "Please make sure you save all your changes before moving the transcript."
            }
          </b>
        </>
      ),
      heading: `Move Transcription`,
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  const actions = [
    {
      text: "Assign Transcriber",
      props: {
        onClick: () => setModal({ state: true, meetingId: meetingId }),
      },
      outlined: true,
      hidden: data?.length ? false : true,
    },
    {
      text: "Move Transcript",
      props: {
        onClick: () => moveTranscriptionAlert(meetingId),
      },
      outlined: true,
      hidden: data?.length ? false : true,
    },
    {
      text: "Save (alt+s)",
      props: {
        onClick: () => updateXExamTranscript(meetingId),
      },
      outlined: true,
      hidden: data?.length ? false : true,
    },
  ];

  const handleOnKeyDown = (event) => {
    const { selection } = editor;
    const lastNode = Editor.end(editor, []);
    if (selection && Range.isCollapsed(selection)) {
      // Get the current path and the last node's path

      // Check if the current path is the last node
      if (
        lastNode.path[0] === selection.anchor.path[0] &&
        event.key !== "ArrowUp"
      ) {
        event.preventDefault();
      }
    }
    if (event.altKey && event.key === "s") {
      if (data?.length) {
        updateXExamTranscript(meetingId);
      }
    } else if (
      (lastNode.path[0] === selection.anchor.path[0]) !== true &&
      event.key === "Backspace"
    ) {
      const { selection } = editor;
      const { focus } = selection;
      const { offset } = focus;
      if (focus && offset === 0 && focus.path.length > 1) {
        const prevPath = focus.path.slice(0, focus.path.length - 1);
        const prevNode = Editor.node(editor, prevPath)[0];
        const addNode = Editor.node(editor, [prevPath - 1])[0];
        Transforms.setNodes(
          editor,
          {
            type: "timedText",
            mediaId: addNode?.mediaId + "," + prevNode?.mediaId,
            audioUrl: addNode?.audioUrl + "," + prevNode?.audioUrl,
          },
          { at: [prevPath - 1] },
        );
      } else {
        if (selection && Range.isExpanded(selection)) {
          const [start, end] = Range.edges(selection);
          const range = { anchor: start, focus: end };
          Transforms.delete(editor, { at: range });
          const [node, nodePath] = Editor.node(editor, range.anchor.path);
          const maxOffset = node.text.length; // Maximum offset for the node

          // Validate the offset and set it to a valid value if out of bounds
          const offset = Math.min(range.anchor.offset, maxOffset);
          const adjustedSelection = {
            anchor: { path: nodePath, offset },
            focus: { path: nodePath, offset },
          };
          editor.selection = null;
          setCursor({
            selection: adjustedSelection,
          });
        }
      }
    } else if (
      (lastNode.path[0] === selection.anchor.path[0]) !== true &&
      event.key === "Delete"
    ) {
      if (lastNode.path[0] - 1 === selection.anchor.path[0]) {
        const [node] = Editor.node(editor, selection);
        if (
          node?.text.length === selection.anchor.offset &&
          event.key !== "ArrowUp"
        ) {
          event.preventDefault();
        }
      } else {
        const { selection } = editor;
        const { focus } = selection;
        const { offset } = focus;
        const prevPath = focus.path.slice(0, focus.path.length - 1);
        const prevNode = Editor.node(editor, prevPath)[0];
        if (prevNode.children[0].text.length === offset) {
          if (selection && Range.isExpanded(selection)) {
            const [start, end] = Range.edges(selection);
            const range = { anchor: start, focus: end };
            Transforms.delete(editor, { at: range });
            const [node, nodePath] = Editor.node(editor, range.anchor.path);
            const maxOffset = node.text.length; // Maximum offset for the node

            // Validate the offset and set it to a valid value if out of bounds
            const offset = Math.min(range.anchor.offset, maxOffset);
            const adjustedSelection = {
              anchor: { path: nodePath, offset },
              focus: { path: nodePath, offset },
            };
            editor.selection = null;
            setCursor({
              selection: adjustedSelection,
            });
          } else {
            const addNode = Editor.node(editor, [parseInt(prevPath) + 1])[0];
            Transforms.setNodes(
              editor,
              {
                type: "timedText",
                mediaId: prevNode?.mediaId + "," + addNode?.mediaId,
                audioUrl: prevNode?.audioUrl + "," + addNode?.audioUrl,
              },
              { at: [prevPath] },
            );
          }
        } else {
          if (selection && Range.isExpanded(selection)) {
            const [start, end] = Range.edges(selection);
            const range = { anchor: start, focus: end };
            Transforms.delete(editor, { at: range });
            const [node, nodePath] = Editor.node(editor, range.anchor.path);
            const maxOffset = node.text.length; // Maximum offset for the node

            // Validate the offset and set it to a valid value if out of bounds
            const offset = Math.min(range.anchor.offset, maxOffset);
            const adjustedSelection = {
              anchor: { path: nodePath, offset },
              focus: { path: nodePath, offset },
            };
            editor.selection = null;
            setCursor({
              selection: adjustedSelection,
            });
          }
        }
      }
    } else if (event.key === "Enter") {
      const { selection } = editor;

      if (selection && Range.isExpanded(selection)) {
        event.preventDefault();
      }
      // else {
      //   if (selection && focus) {
      //     setData(
      //       value.map((el) => {
      //         return {
      //           id: el?.id,
      //           text: el?.children[0].text,
      //           speaker: el?.speaker,
      //           mediaId: el?.mediaId,
      //           audioUrl: el?.audioUrl,
      //           textType: el?.textType ? el?.textType : "Question",
      //           questionNo: el?.questionNo,
      //         };
      //       })
      //     );
      //     setTranscribedData([]);
      //   }
      // }
    }
  };

  useEffect(() => {
    if (cursor?.selection) {
      editor.selection = cursor.selection;
      setCursor({});
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <React.Fragment>
        <ActionBar
          timeInterval={id?.location.state.timeInterval}
          isLocalTranscriptionServer={
            id?.location?.state?.isLocalTranscriptionServer
          }
          settings={id?.location?.state}
          {...{
            actions,
            breadcrumbs,
            onBreadcrumbClick,
            setReferesh,
            update,
            caseId,
            mergedBlobURL,
            setMergedBlobURL,
          }}
        />
        <Container>
          {value.length !== 0 ? (
            <div style={{ paddingTop: "10px" }}>
              <BoxContainer>
                <Main>
                  {value.length !== 0 ? (
                    <Slate
                      editor={editor}
                      value={value}
                      onChange={(value) => {
                        return setValue(value);
                      }}
                    >
                      <Editable
                        readOnly={disabled}
                        autoFocus
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        onKeyDown={handleOnKeyDown}
                      />
                    </Slate>
                  ) : null}
                </Main>
              </BoxContainer>
            </div>
          ) : (
            <StyledTableEmptyBlock>
              <h2>
                {
                  "Welcome to JustAct live transcription services. Your session has either not started or your live transcript is currently being generated."
                }
              </h2>
            </StyledTableEmptyBlock>
          )}
        </Container>
      </React.Fragment>
      {state?.state ? (
        <UpdateSpeaker
          {...{
            state,
            setState,
            speakersData,
            editor,
            setCursor,
          }}
          isEditable={true}
        />
      ) : null}
      <AssignTranscriber {...{ modal, setModal }} />
      <AlertDialog
        isOpen={open}
        {...{ ...dialogData }}
        descriptionTextStyle={{
          textAlign: "left",
          fontFamily: theme.fonts.primaryFontSemiBold,
        }}
      />
    </>
  );
};

export default CrossExamination;

export const Container = styled.div`
  padding: 4px 43px;
  display: flex;
  flex-direction: column;
`;

const Main = styled.section`
  background-color: ${COLORS.PRIMARY_WHITE};
  height: 590px;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: thin;
  border-radius: 10px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  box-shadow: 0 0 5px rgba(80, 151, 255, 0.68);
`;

const DrawerContainer = styled.div`
  width: 100%;
  padding: 10px 23px;
  display: flex;
  align-items: center;
`;

const ValueSpeaker = styled.span`
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  padding-right: 6px;
  padding-left: 10px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

export const StyledDropdownIcon = styled.img`
  height: 4px;
  width: 7px;
  background-color: ${COLORS.INPUT_BACKGROUND};
  position: absolute;
  right: 13px;
`;

const BoxContainer = styled.div`
  background-color: ${COLORS.BORDER_GREY};
  position: relative;
  border-radius: 10px;
`;

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15px;
  margin-bottom: 20px;
  &:nth-child(5n) {
    margin-bottom: 25px;
  }
`;

export const Value = styled.div`
  height: 10px;
  display: block;
  align-items: center;
  margin-bottom: 20px;
`;

const CustomTextAreaOtter = styled.div`
  width: 80%;
  font-size: 16px;
  margin-left: 142px;
  margin-right: 25px;
  margin-bottom: 8px;
  position: relative;
  line-height: 1.5;
  outline: none;
  border: none;
  resize: none;
  font-family: ${theme.fonts.primaryFontSemiBold};
  background-color: ${COLORS.PRIMARY_WHITE};
  @media ${theme.breakpoints.sm_down} {
    margin-left: 30px;
    font-size: 14px;
  }
`;

const AudioBar = styled.span`
  width: 75%;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueConfidence = styled.span`
  width: 48px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-right: 10px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const StyledTableEmptyBlock = styled.div`
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-40%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: ${COLORS.COLOR_DARK};
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 20px;
    margin: 0;
    text-align: center;
    font-family: ${theme.fonts.primaryFontBold};
  }
  p {
    margin: 10px auto 30px;
    font-family: ${theme.fonts.primaryFontRegular};
    color: ${COLORS.COLOR_DARK};
  }
  a {
    color: ${COLORS.BTN_GREEN};
    font-family: ${theme.fonts.primaryFontRegular};
  }
`;
