import React from "react";
import styled from "styled-components";
import ActionBar from "../../common/ActionBar";
import PartyTable from "../../common/PartyTable";
import _ from "lodash";
import labels from "../../../helpers/labels.json";

const Index = () => {
  const breadcrumbs = [_.startCase(labels.parties)];
  return (
    <div>
      <ActionBar {...{ breadcrumbs }} />
      <Padding>
        <PartyTable />
      </Padding>
    </div>
  );
};

export default Index;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;
