import React, { useEffect, useRef, useState } from "react";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import _ from "lodash";
import queryString from "query-string";
import labels from "../../../helpers/labels.json";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import useLoader from "../../../hooks/useLoader";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import theme from "../../../assets/theme";
import { Person, FiberManualRecord } from "@material-ui/icons";
import { navigate } from "@reach/router";
import AssignTranscriber from "../AssignTranscriber";

const MeetingAudioTable = ({ id, type, trigger, setTrigger }) => {
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const caseId = id;
  const [modal, setModal] = useState({ state: false });

  async function getAudioDetails(query) {
    try {
      // setLoader({ state: true, message: "Fetching Audio Details..." });
      const response = await CaseService.getAudioDetails(caseId, query);
      return { ...response };
    } catch (error) {
      throw error;
    } finally {
      // setLoader({ state: false });
    }
  }

  useEffect(() => {
    if (trigger) {
      MTRef.current.onQueryChange("");
      setTrigger(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const columns = [
    {
      field: "id",
      title: "Id",
      sorting: false,
      render: (rowData) => <b>{rowData?.id}</b>,
    },
    // {
    //   field: "audioFileName",
    //   title: labels["audioFileName"],
    //   sorting: false,
    //   render: (rowData) => (
    //     <div
    //       style={{
    //         color: COLORS.COLOR_DARK,
    //         fontFamily: theme.fonts.primaryFontSemiBold,
    //       }}
    //     >
    //       {rowData?.audioFileName.split(".mp3")}
    //     </div>
    //   ),
    // },
    {
      field: "audioFileUrl",
      title: "Live Transcript",
      sorting: false,
      render: (rowData, index) =>
        rowData?.transcriptionData ? (
          <HyperLink
            style={{ width: 150 }}
            onClick={() =>
              navigate(`/dashboard/${type}/${caseId}/split-transcription`, {
                state: rowData,
              })
            }
          >
            {rowData?.audioFileName}
          </HyperLink>
        ) : (
          <div>{rowData?.audioFileName}</div>
        ),
    },
    {
      field: "audioFileUrl",
      title: labels["audioFileUrl"],
      sorting: false,
      render: (rowData) => (
        <HyperLink
          onClick={() => {
            window.open(rowData?.audioFileUrl);
          }}
        >
          {rowData?.audioFileName.split(".mp3")}
        </HyperLink>
      ),
    },
    {
      field: "duration",
      title: labels["duration"],
      sorting: false,
      render: (rowData) => (
        <b style={{ marginLeft: 30 }}>{rowData?.duration}</b>
      ),
    },
    {
      field: "created_at",
      title: "Created",
      sorting: false,
      render: (rowData) => <b>{rowData?.created_at}</b>,
    },
    {
      field: "",
      title: "Assign",
      sorting: false,
      render: (rowData) => (
        <Person
          style={{ cursor: "pointer" }}
          onClick={() =>
            setModal({
              state: true,
              id: rowData?.meetingAudioId,
              meetingAudioId: rowData?.meetingAudioId,
              audioFileUrl: rowData?.audioFileUrl,
              audioFileName: rowData?.audioFileName,
            })
          }
        />
      ),
    },
    {
      field: "",
      title: "Edit Status",
      sorting: false,
      render: (rowData) => (
        <FiberManualRecord
          style={{
            fill: rowData?.transcriberEditMode ? "#d91b1b" : "#00FF00",
            marginLeft: 25,
          }}
        />
      ),
    },
  ];

  const handleCaseSelection = async (cases, data) => {
    if (cases?.length >= 1 && !data) {
      try {
        setLoader({ state: true, message: "Modifying Metting details..." });
        const payload = {
          caseId: caseId,
          selectedStatus: 1,
        };
        await CaseService.selectMeetingAudioDetails(payload);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    } else if (cases?.length === 0 && !data) {
      try {
        const payload = {
          caseId: caseId,
          selectedStatus: 0,
        };
        setLoader({ state: true, message: "Modifying Metting details..." });
        await CaseService.selectMeetingAudioDetails(payload);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    } else {
      try {
        setLoader({ state: true, message: "Modifying Metting details..." });
        if (data?.selectedStatus) {
          const payload = {
            meetingAudioId: data.meetingAudioId,
            selectedStatus: 0,
          };
          await CaseService.selectMeetingAudioDetails(payload);
        } else {
          const payload = {
            meetingAudioId: data.meetingAudioId,
            selectedStatus: 1,
          };
          await CaseService.selectMeetingAudioDetails(payload);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    }
  };

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getAudioDetails(stringParams)
        .then(async (result) => {
          if (result) {
            result = result?.meetingAudioDetails;
            resolve({
              data: result.data?.map((row) => ({
                ...row,
                tableData: {
                  checked: row?.selectedStatus === 1 ? true : false,
                },
              })),
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <>
      <AccordionDetails>
        <TableContainer>
          <CustomTable
            selection
            onSelectionChange={handleCaseSelection}
            hidePagination={state.lastPage === 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle={labels.audioDetails}
            hideFilterBar
            singularTitle=""
            {...{
              columns,
              data,
              MTRef,
            }}
            noToolbar
            state={state}
          />
        </TableContainer>
      </AccordionDetails>
      <AssignTranscriber {...{ modal, setModal }} />
    </>
  );
};

export default MeetingAudioTable;

export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
