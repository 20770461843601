import React from "react";
import { CaseManagerCardData } from "../styles";
import { PrimaryCTAButton } from "../../../common/Buttons";

export default function BigBlueButton({
  type = "",
  onClick = () => {},
}) {
  return (
    <CaseManagerCardData>
      <PrimaryCTAButton onClick={onClick}>{type}</PrimaryCTAButton>
    </CaseManagerCardData>
  );
}
