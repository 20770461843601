import React from "react";
import {
  CaseManagerCard,
  CaseManagerTitle,
  Flex,
  CaseManagerProfile,
  CaseManagerName,
  Column,
} from "../styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DrawerModal from "../../DrawerModal/DrawerModel";
import { useState } from "react";
import { Checkbox } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";

export default function CaseManager({
  caseManager,
  title = "Case Manager",
  addMediator,
  disabled = false,
  showRemove = false,
  removeMediator = () => {},
  adhocPending = false,
  presidingArbitrator,
  mediator,
  caseDetails,
  titleTag,
}) {
  const [modal, setModal] = useState(false);
  return (
    <CaseManagerCard disabled={disabled}>
      <Column>
        <CaseManagerTitle>
          {mediator?.presidingArbitrator === "yes"
            ? "Presiding Arbitrator"
            : titleTag
            ? titleTag
            : title}
        </CaseManagerTitle>
        <Flex className="flex-1">
          {caseManager?.name ? (
            <>
              <CaseManagerProfile
                src={
                  caseManager?.avatarUrl
                    ? caseManager?.avatarUrl
                    : require("../../../../assets/images/userPlaceholder.svg")
                }
              />
              <CaseManagerName
                // disabled={adhocPending}
                onClick={(e) => setModal(true)}
              >
                {caseManager?.name}
              </CaseManagerName>
            </>
          ) : (
            <div>
              <div className="add_case_casemanager flex flex-1">
                {titleTag ? title : `Add ${title}`}
              </div>
            </div>
          )}
        </Flex>
        {adhocPending && (
          <div className="pending_mediator">
            (Ad-hoc) {title} is yet to sign up.
          </div>
        )}
      </Column>
      {caseManager?.name ? null : (
        <div className="add_circle" onClick={() => !disabled && addMediator()}>
          <AddIcon className="add_icon" />
        </div>
      )}
      {showRemove && caseManager?.email ? (
        <div className="remove" onClick={() => removeMediator()}>
          <CloseIcon className="icon" />
        </div>
      ) : null}
      {caseManager?.email && caseDetails?.resolutionKind === "arbitration" ? (
        <Tooltip
          title={
            mediator?.presidingArbitrator === "yes"
              ? "Remove Presiding Arbitrator status"
              : "Make Presiding Arbitrator"
          }
          placement="top"
        >
          <div className="checkbox">
            <Checkbox
              onChange={(e) => presidingArbitrator(mediator)}
              checked={mediator?.presidingArbitrator === "yes"}
              className="icon"
              // disabled={mediator?.presidingArbitrator === "yes"}
            />
          </div>
        </Tooltip>
      ) : null}
      <DrawerModal
        {...{ modal, setModal }}
        id={caseManager?.id}
        type={title}
        details={caseManager}
      />
    </CaseManagerCard>
  );
}
