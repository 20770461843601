import React from "react";
import Modal from "../../../common/Modal";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import { Formik } from "formik";
import FormField from "../../../common/FormField/FormField";
import { CustomFileUpload } from "../../../common/FormInputs";
import {
  UploadAgreementValidationSchema,
  UploadSummaryValidationSchema,
} from "../../../../helpers/validationSchema";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import {
  getErrorMessage,
  getFileName,
  parseTimeStamp,
} from "../../../../helpers/functions";
import CaseService from "../../../../services/CaseService";
import _ from "lodash";
import AWSService from "../../../../services/AWSService";
import moment from "moment";
import { useState } from "react";

const Index = ({
  modal,
  setModal,
  MTRef = { current: { onSearchChange: () => {} } },
}) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(true);

  function onFormSubmit(values) {
    if (modal.type === "summary") {
      saveOrDraftSummary(values, false);
    } else {
      uploadAgreement(values);
    }
    setDisabled(true);
  }

  async function saveOrDraftSummary(values, isDraft = false, close = true) {
    try {
      setLoader({
        state: true,
        message: `${isDraft ? "Updating" : "Submitting"} Agreement Summary...`,
      });
      const payload = _.pick(values, ["summary"]);
      const action = isDraft ? `draft` : `publish`;
      const response = await CaseService.saveSummary(payload, modal.id, action);
      if (response) {
        enqueueSnackbar(
          `${isDraft ? "Updated" : "Submitted"} Agreement Summary`,
          {
            variant: "success",
          }
        );
        if (close) {
          setModal({ state: false });
        } else {
          setDisabled(true);
        }
        MTRef.current.onSearchChange("");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  async function uploadAgreement(values) {
    try {
      setLoader({
        state: true,
        message: `Uploading Agreement Document...`,
      });
      const payload = {
        url: {
          url: values.agreement_url,
          uploadType: "",
        },
      };
      const file = modal?.case?.uploadedDocuments?.length
        ? modal?.case?.uploadedDocuments?.find(
            (item) => item?.kind === "negotiationAgreement"
          )
        : {};

      const response = file?.id
        ? await CaseService.updateAgreement(payload, modal.id, file?.id)
        : await CaseService.uploadAgreement(payload, modal.id);
      if (response) {
        enqueueSnackbar("Uploaded Agreement Document", {
          variant: "success",
        });
        setModal({ state: false });
      }
      MTRef.current.onSearchChange("");
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        setLoader({ state: true, message: `Uploading file ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        setFieldError(field, message);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  return (
    <Modal {...{ modal, setModal }}>
      <HeadingContainer>
        <Heading>
          Upload Final {modal.type === "summary" ? "Summary" : "Agreement"}
        </Heading>
        <CloseModal
          onClick={() => setModal(false)}
          src={require("../../../../assets/images/closeModal.svg")}
        />
      </HeadingContainer>
      <EditProfileContainer>
        <Formik
          initialValues={{
            summary: modal?.case?.negotiationSummary || "",
            agreement_url: "",
          }}
          validationSchema={
            modal.type === "summary"
              ? UploadSummaryValidationSchema
              : UploadAgreementValidationSchema
          }
          onSubmit={onFormSubmit}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            setFieldError,
          }) => (
            <StyledForm onSubmit={handleSubmit}>
              <H1>{modal?.case?.title}</H1>
              <FlexStart>
                <ColumnSm>
                  <Label>CASE ID</Label>
                  <H2>{modal?.case?.id}</H2>
                </ColumnSm>
                <ColumnSm>
                  <Label>RESPONDENT</Label>
                  <H2>{modal?.case?.respondentParty?.name}</H2>
                </ColumnSm>
                <ColumnSm className="isLastColumn">
                  <Label>CLAIMANT</Label>
                  <H2>{modal?.case?.claimantParty?.name}</H2>
                </ColumnSm>
              </FlexStart>
              <FlexStart>
                <ColumnSm>
                  <Label>MONETARY CLAIM VALUE</Label>
                  <H2>
                    {modal?.case?.totalClaimValue > 0
                      ? `${modal?.case?.currencyUnit} ${modal?.case?.totalClaimValue}`
                      : "Non Monetary"}
                  </H2>
                </ColumnSm>
                <ColumnSm>
                  <Label>FILED ON</Label>
                  <H2>
                    {modal?.case?.created_at &&
                      moment(parseTimeStamp(modal?.case?.created_at)).format(
                        "DD/MM/YYYY"
                      )}
                  </H2>
                </ColumnSm>
              </FlexStart>

              {modal.type === "summary" ? (
                <>
                  <StyledFormElementsWrapper
                    style={{ marginBottom: "unset", width: 446 }}
                  >
                    <FormField
                      white
                      maxLength={0}
                      input={{
                        type: "textarea",
                        name: "summary",
                        label: "AGREEMENT SUMMARY",
                      }}
                      {...{
                        values,
                        handleChange,
                        errors,
                        handleSubmit,
                        touched,
                        handleBlur,
                        setFieldValue,
                      }}
                    />
                  </StyledFormElementsWrapper>

                  <ButtonContainer style={{ justifyContent: "unset" }}>
                    <PrimaryCTAButton
                      onClick={() => saveOrDraftSummary(values, true)}
                      style={{ width: 216, height: 42, marginRight: 16 }}
                    >
                      Save as Draft
                    </PrimaryCTAButton>
                    <PrimaryCTAButton
                      onClick={handleSubmit}
                      style={{ width: 216, height: 42 }}
                    >
                      Publish Summary
                    </PrimaryCTAButton>
                  </ButtonContainer>
                </>
              ) : (
                <>
                  <StyledFormElementsWrapper>
                    <FormField
                      white
                      disabled={disabled}
                      maxLength={0}
                      input={{
                        type: "textarea",
                        name: "summary",
                        label: "AGREEMENT SUMMARY",
                      }}
                      {...{
                        values,
                        handleChange,
                        errors,
                        handleSubmit,
                        touched,
                        handleBlur,
                        setFieldValue,
                      }}
                    />
                    <ButtonContainer
                      style={{ marginBottom: 13, marginTop: 10 }}
                    >
                      {modal?.case?.uploadedDocuments?.length > 0 ? null : (
                        <PrimaryOutlinedCTAButton
                          onClick={() => {
                            if (disabled) {
                              setDisabled(false);
                            } else {
                              saveOrDraftSummary(
                                { summary: values.summary },
                                false,
                                false
                              );
                            }
                          }}
                          style={{ width: 216, height: 42 }}
                        >
                          {disabled ? "Edit Summary" : "Save Summary"}
                        </PrimaryOutlinedCTAButton>
                      )}
                    </ButtonContainer>
                    {modal?.case?.uploadedDocuments?.length > 0 ? (
                      <>
                        <Label>AGREEMENT</Label>
                        <FileLink
                          onClick={() =>
                            window.open(
                              modal?.case?.uploadedDocuments[0]?.url?.url
                                ? modal?.case?.uploadedDocuments[0]?.url?.url
                                : modal?.case?.uploadedDocuments[0]?.url
                              // "Download"
                            )
                          }
                        >
                          {decodeURI(
                            getFileName(
                              modal?.case?.uploadedDocuments[0]?.url?.url
                                ? modal?.case?.uploadedDocuments[0]?.url?.url
                                : modal?.case?.uploadedDocuments[0]?.url
                            )
                          )}
                        </FileLink>
                        <Label style={{ marginTop: 16 }}>
                          Update Agreement (pdf, docx or txt)
                        </Label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomFileUpload
                            file={{ name: getFileName(values.agreement_url) }}
                            setFile={(file) =>
                              s3Upload(
                                file,
                                "agreement_url",
                                setFieldValue,
                                setFieldError
                              )
                            }
                            fileUrl={values.agreement_url}
                            accept=".pdf, .docx, .txt"
                          />
                          <ButtonContainer
                            style={{ margin: 0, marginLeft: 20 }}
                          >
                            <PrimaryCTAButton
                              disabled={!values.agreement_url}
                              onClick={handleSubmit}
                              style={{ width: 100, height: 50 }}
                            >
                              Update
                            </PrimaryCTAButton>
                          </ButtonContainer>
                        </div>
                      </>
                    ) : (
                      <>
                        <Label>
                          UPLOAD AGREEMENT (pdf, docx or txt)
                          <span className="required-star"> *</span>
                        </Label>
                        <CustomFileUpload
                          file={{ name: getFileName(values.agreement_url) }}
                          error={touched.agreement_url && errors.agreement_url}
                          setFile={(file) =>
                            s3Upload(
                              file,
                              "agreement_url",
                              setFieldValue,
                              setFieldError
                            )
                          }
                          fileUrl={values?.agreement_url}
                          accept=".pdf, .docx, .txt"
                        />
                      </>
                    )}
                  </StyledFormElementsWrapper>

                  {modal?.case?.uploadedDocuments?.length > 0 ? null : (
                    <ButtonContainer style={{ marginBottom: 13 }}>
                      <PrimaryCTAButton
                        onClick={handleSubmit}
                        style={{ width: 216, height: 42 }}
                      >
                        Upload Agreement
                      </PrimaryCTAButton>
                    </ButtonContainer>
                  )}
                </>
              )}
            </StyledForm>
          )}
        </Formik>
      </EditProfileContainer>
    </Modal>
  );
};

export default Index;

const HeadingContainer = styled.div`
  padding-top: 28px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;
const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
  span {
    font-family: ${theme.fonts.primaryFontSemiBold};
    text-decoration: underline;
    margin-left: 23px;
    font-size: 12px;
    color: ${COLORS.BTN_GREEN};
    user-select: none;
  }
`;
const EditProfileContainer = styled.div`
  margin: 0 30px 0 30px;
`;
const FlexStart = styled.div`
  display: flex;
`;
const ColumnSm = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  &.isLastColumn {
    padding-left: 20px;
  }
`;
const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  width: 180px;
  word-break: break-word;
`;
const H1 = styled.h1`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 20px;
  /* width: 180px; */
  margin: 30px 0 40px;
`;
const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.INPUT_LABEL};
  font-size: 10px;
  font-weight: 600;
  line-height: 1.7;
  color: #949ab0;
  margin-bottom: 3px;
`;
const StyledForm = styled.form`
  width: 100%;
`;
const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

const FileLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  cursor: pointer;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
