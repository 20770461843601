import React from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import FormField from "../../../common/FormField/FormField";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import useLoader from "../../../../hooks/useLoader";
import { Formik } from "formik";
import { OfflinePaymentSchema } from "../../../../helpers/validationSchema";
import { useSnackbar } from "notistack";
import { getErrorMessage, getFileName } from "../../../../helpers/functions";
import labels from "../../../../helpers/labels.json";
import _ from "lodash";
import { Modal } from "@material-ui/core";
import CaseService from "../../../../services/CaseService";

export default function PaymentModal({ modal, setModal, payments, id }) {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Updating profile..." });
      const postbody = { ...values, partyRole: "claimant" };
      const response = await CaseService.offlinePayment(postbody, id);
      if (response.message) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        setModal(false);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal(false);
    }
  }

  return (
    <Modal open={modal}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{labels.choose_payment_type}</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <Formik
            initialValues={{
              paymentType: payments?.paymentType || "Cheque",
              details: payments?.details || "",
              paymentRelatedDocuments: payments?.paymentRelatedDocuments || "",
            }}
            enableReinitialize
            validateOnBlur
            validationSchema={OfflinePaymentSchema}
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldError,
              handleBlur,
            }) => (
              <>
                <FormField
                  input={{
                    name: "paymentType",
                    label: _.upperCase(labels.payment_type),
                    type: "select",
                  }}
                  menuItems={["Cheque", "Transfer", "Others"].map((i) => ({
                    label: i,
                    value: i,
                  }))}
                  {...{
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                  }}
                />
                <div style={{ marginTop: 17 }}>
                  <FormField
                    input={{
                      name: "details",
                      label: _.upperCase(labels.details),
                      type: "textarea",
                      placeholder: "",
                    }}
                    style={{ backgroundColor: "#f9f9f9" }}
                    multiline
                    maxLength={0}
                    {...{
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                    }}
                  />
                </div>
                <div style={{ marginTop: 17 }}>
                  <Label>Receipt Document</Label>
                  <FileLink
                    disabledUnderline={payments?.paymentRelatedDocuments}
                    onClick={() =>
                      payments?.paymentRelatedDocuments &&
                      window.open(payments?.paymentRelatedDocuments)
                    }
                  >
                    {payments?.paymentRelatedDocuments
                      ? decodeURI(
                          getFileName(payments?.paymentRelatedDocuments)
                        )
                      : "-"}
                  </FileLink>
                </div>
                <Flex>
                  <HalfWidth>
                    <PrimaryOutlinedCTAButton onClick={() => setModal(false)}>
                      {labels.cancel}
                    </PrimaryOutlinedCTAButton>
                  </HalfWidth>
                  <HalfWidth>
                    <PrimaryCTAButton onClick={handleSubmit}>
                      {labels["mark_as_paid"]}
                    </PrimaryCTAButton>
                  </HalfWidth>
                </Flex>
              </>
            )}
          </Formik>
        </AgentFormContainer>
      </DrawerContainer>
    </Modal>
  );
}

const DrawerContainer = styled.div`
  max-width: 516px;
  min-height: 318px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  padding-top: 14px;
  padding-left: 54px;
  padding-right: 54px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
`;

const HalfWidth = styled.div`
  width: 48%;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.INPUT_LABEL};
  font-size: 12px;
  font-weight: 600;
  line-height: 1.7;
  color: #949ab0;
  margin-bottom: 3px;
  text-transform: uppercase;
`;

const FileLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: ${({ disabledUnderline }) =>
    disabledUnderline ? "underline" : ""};
  cursor: pointer;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
