export const setInitialAgreementUrl = (urls = []) => {
  if (urls?.length) {
    const agrementUrl = urls.map((url) => {
      if (typeof url === "string") {
        return {
          uploadType: "",
          url: url || "",
        };
      } else {
        return {
          uploadType: url?.uploadType || "",
          url: url?.url || "",
        };
      }
    });
    return agrementUrl?.length ? agrementUrl : [dummyFileUpload];
  }
  return [dummyFileUpload];
};

export const dummyFileUpload = {
  uploadType: "Orders & Minutes",
  url: "",
};
