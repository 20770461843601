import React from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { Modal } from "@material-ui/core";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import { getErrorMessage } from "../../../../helpers/functions";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import CaseService from "../../../../services/CaseService";
import { Formik } from "formik";
import { CustomInputField } from "../../../common/FormInputs";

export default function EditModal({
  editModal,
  setEditModal,
  setTriggerNotify,
}) {
  // let caseObj = {};
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "please wait..." });
      const payload = {
        editFlag: true,
        editComments: values?.editComments.split("\n"),
      };
      const response = await CaseService.updateCaseManagerCases(
        editModal?.caseId,
        payload,
      );
      if (response?.message) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setEditModal({ state: false });
      setTriggerNotify(true);
    }
  }

  return (
    <>
      <Modal
        open={editModal.state}
        onClose={() => setEditModal({ state: false })}
      >
        <DrawerContainer role="presentation">
          <HeadingContainer>
            <Heading>{"Edit"}</Heading>
            <CloseModal
              onClick={() => setEditModal({ state: false })}
              src={require("../../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            <Formik
              initialValues={{
                editComments: "",
              }}
              enableReinitialize
              validateOnBlur
              validateOnChange
              onSubmit={onFormSubmit}
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                setFieldError,
                handleBlur,
                isValid,
              }) => (
                <>
                  <CustomInputField
                    variant="outlined"
                    className={"input-height-auto"}
                    multiline={true}
                    rows={5}
                    maxRows={15}
                    placeholder="Add comments here"
                    name={"editComments"}
                    value={values?.editComments}
                    onChange={handleChange}
                  />
                  <Flex>
                    <HalfWidth>
                      <PrimaryOutlinedCTAButton
                        onClick={() => setEditModal({ state: false })}
                      >
                        {"Cancel"}
                      </PrimaryOutlinedCTAButton>
                    </HalfWidth>

                    <HalfWidth>
                      <PrimaryCTAButton onClick={handleSubmit}>
                        {"Submit"}
                      </PrimaryCTAButton>
                    </HalfWidth>
                  </Flex>
                </>
              )}
            </Formik>
          </FormContainer>
        </DrawerContainer>
      </Modal>
    </>
  );
}

const DrawerContainer = styled.div`
  max-width: 95%;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.div`
  padding-left: 54px;
  padding-right: 54px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
`;

const HalfWidth = styled.div`
  width: 48%;
`;
